import { render } from 'ejs'
import { get, set, cloneDeep, toNumber } from 'lodash'
import { renderEventRow } from './oddPageTemplateNewAPI'
import { renderEventRowAllPeriod } from './oddPageTemplateAllPeriodsMatchup'
import { buildRouteUrl, getCurrentRouterProvider } from 'services/Url'
import { routesStandard } from 'services/Routes/routes'
import { renderTeamTotalEvent } from 'apps/templates/TeamTotal'
import { renderEventSearchResult } from 'apps/templates/SearchResult/searchResultTemplate'
import { ROUTER_PROVIDER, SPORT_NAME } from 'constants'
import { SPORT_ID } from 'constants/sportId'

export const OddPageBindDataHL = (stringRender, data) => {
	const content = render(stringRender, {
		data: {
			...data,
			matchData: data.matchData,
		},
	})
	return content.trim()
}

export const OddPageBindDataForEvent = (stringRender, data) => {
	const { name: rootName } = getCurrentRouterProvider()
	const content = render(stringRender, {
		data: {
			...data,
			event: data.event,
			rootName,
			ROUTER_PROVIDER,
		},
	})
	return content.trim()
}

export const removeAllExtendOdds = (event) => {
	event?.stopPropagation()
	const elementsRemove = document.querySelectorAll(`.${extendListClassName}`)
	elementsRemove.forEach((element) => {
		element.remove()
	})
}

export const timeStampDifference = 0

export const renderOddUp = (classIndicator, timeStamp) => {
	return `<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" 
  class="styleOddsChange styleUp ${classIndicator} ${classIndicator}${timeStamp}">
    <title>Odds Increased</title>
    <path fill="#10A810" d="M0 0h8v8z" fill-rule="evenodd"></path>
  </svg>`
}

export const renderOddDown = (classIndicator, timeStamp) => {
	return `<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" 
class="styleOddsChange styleDown ${classIndicator} ${classIndicator}${timeStamp}">
  <title>Odds Decreased</title>
  <path fill="#FF4A70" d="M0 8h8V0z" fill-rule="evenodd"></path>
</svg>`
}

export const renderOddUpSelected = (classIndicator, timeStamp) => {
	return `<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" 
  class="styleOddsChange selected styleUp ${classIndicator} ${classIndicator}${timeStamp}">
    <title>Odds Increased</title>
    <path d="M0 0h8v8z" fill-rule="evenodd"></path>
  </svg>`
}

export const renderOddDownSelected = (classIndicator, timeStamp) => {
	return `<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" 
class="styleOddsChange selected styleDown ${classIndicator} ${classIndicator}${timeStamp}">
  <title>Odds Decreased</title>
  <path d="M0 8h8V0z" fill-rule="evenodd"></path>
</svg>`
}

export const SPORT_WITH_FILTER_PERIOD = {
	29: [0, 8, 39, 3, 4, 5, 6, 7],
	12: [0, 1, 2, 3, 4],
	4: [0, 2],
	15: [0, 2],
}

export const soccerId = '29'

export const dartId = '10'

export const esportId = '12'

export const BET_PLACED = 'betsPlaced'

export const extendListClassName = 'list-extend'

export const oddsSelectionsType = 'NORMAL'

export const orderMoneyLine = 'moneyline'

export const orderHandicap = 'handicap'

export const orderSpread = 'spread'

export const notLoggedInStorageKey = 'notLoggedIn'

export const listSportsNotShowPeriod = [
	'33',
	'19',
	'6',
	'10',
	'34',
	'39',
	'3',
	'8',
	'17',
	'18',
	'22',
	'26',
	'27',
	'20',
	'45',
	'4',
	'15',
]

export const LeagueDetailBetTypes = ['ML_1X2', 'HDP', 'OU', 'TEAM_TOTALS']

window.listSportsNotShowPeriod = listSportsNotShowPeriod

export const listMl3Line = ['29', '10', '8']

export const updateExtendOddList = (
	oddRef,
	oddData,
	refExtendOdd,
	selectedOdds = [],
	eventIdOnClickName = '',
	quickBet = {},
	updateCurrentRef = false,
) => {
	if (!oddData) return
	const { leagues = [] } = oddData

	const selectedOddIds = selectedOdds.map((odd) => odd.oddsId)
	const selectedOddsWithoutAlt = selectedOddIds.map((odd) => {
		const oddWithoutAlt = odd.split('|')
		oddWithoutAlt.splice(4, 1)
		return oddWithoutAlt.join('|')
	})
	const quickBetWithoutAlt = get(
		quickBet,
		['selection', 'quickBetWithoutAlt'],
		'',
	)
	if (!oddRef?.current) return

	if (refExtendOdd.current?.eventId && refExtendOdd.current?.leagueId) {
		let contentDiv = ``
		const findIndexLeague = leagues.findIndex(
			(le) => le.id === refExtendOdd.current.leagueId,
		)
		if (findIndexLeague !== -1) {
			const leagueId = get(oddData, ['leagues', findIndexLeague, 'id'], '')
			const findEvent = get(
				oddData,
				['leagues', findIndexLeague, 'events'],
				[],
			).find((ev) => ev.id === refExtendOdd.current.eventId)
			if (findEvent) {
				const sportId = get(oddData, ['leagues', findIndexLeague, 'sportId'], 0)
				const sportName = SPORT_ID[sportId] || ''
				const { eventId, displayPeriod, type } = refExtendOdd.current
				const { homeTeamType, awayTeamType } = findEvent
				const isShowHomeFirst = homeTeamType === 0
				const extendList = get(
					findEvent,
					[
						'periods',
						refExtendOdd.current.displayPeriod,
						refExtendOdd.current.type,
					],
					[],
				)
				if (extendList.length > 0) {
					switch (refExtendOdd.current.type) {
						case 'handicap':
							extendList.forEach(function (handicap, indexHandicap) {
								const isAltLine = handicap.isAlt ? '1' : '0'
								const handicapHomeId = `${eventId}|${displayPeriod}|2|0|${isAltLine}|${handicap.homeSpread}|${homeTeamType}`
								const handicapAwayId = `${eventId}|${displayPeriod}|2|1|${isAltLine}|${handicap.awaySpread}|${awayTeamType}`
								const handicapHomeIdSelected = `${eventId}|${displayPeriod}|2|0|${handicap.homeSpread}`
								const handicapAwayIdSelected = `${eventId}|${displayPeriod}|2|1|${handicap.awaySpread}`
								const homeHandicapButton = `<div class='styleButtonWrapper'>
								<button
								  aria-label='HHH'
								  lineId='${handicap.lineId}'
								  id='t${handicapHomeId}'
								  class='market-btn styleButton stylePill styleVertical style-market-btn ${extendListClassName} ${
									selectedOddsWithoutAlt.includes(handicapHomeIdSelected) ||
									quickBetWithoutAlt === handicapHomeIdSelected
										? 'styleSelected'
										: ''
								}'
								  title='${handicap.homeSpread}'
								  onclick="${eventIdOnClickName}(event,'${handicapHomeId}', '${leagueId}', '${eventId}', '${
									handicap.homeOdds
								}','${sportName}','${sportId}')"
								>
								  <span class='styleLabelOdd ${extendListClassName}'>${
									handicap.homeSpread
								}</span>
								  <span class='stylePrice ${extendListClassName}'>${handicap.homeOdds}</span>
								</button>
							  </div>`
								const awayHandicapButton = `<div class='styleButtonWrapper'>
								<button
								  aria-label='HHH'
								  lineId='${handicap.lineId}'
								  id='t${handicapAwayId}'
								  class='market-btn styleButton stylePill styleVertical style-market-btn ${extendListClassName} ${
									selectedOddsWithoutAlt.includes(handicapAwayIdSelected) ||
									quickBetWithoutAlt === handicapAwayIdSelected
										? 'styleSelected'
										: ''
								}'
								  title='${handicap.awaySpread}'
								  onclick="${eventIdOnClickName}(event,'${handicapAwayId}', '${leagueId}', '${eventId}', '${
									handicap.awayOdds
								}','${sportName}','${sportId}')"
								>
								  <span class='styleLabelOdd ${extendListClassName}'>${
									handicap.awaySpread
								}</span>
								  <span class='stylePrice ${extendListClassName}'>${handicap.awayOdds}</span>
								</button>
							  </div>`
								const content = handicap.offline
									? `
				  <div class='styleButtonsExtend ${extendListClassName}'>
					<div class='styleButtonWrapper'>
					<button
					aria-label='HHH'
					lineId='${handicap.lineId}'
					id='t${handicapHomeId}'
					class='market-btn styleButton stylePill styleVertical style-market-btn styleDisabled ${extendListClassName}'
				  	>
					  <i class="icon icon-lock styleUnavailable offlineButton"></i>
				  	</button>
					</div>
					<div class='styleButtonWrapper'>
					<button
					aria-label='HHH'
					lineId='${handicap.lineId}'
					id='t${handicapAwayId}'
					class='market-btn styleButton stylePill styleVertical style-market-btn styleDisabled ${extendListClassName}'
				  >
					  <i class="icon icon-lock styleUnavailable offlineButton"></i>
				  </button>
					</div>
				  </div>`
									: `
				  <div class='styleButtonsExtend ${extendListClassName}'>
				  ${
						isShowHomeFirst
							? homeHandicapButton + awayHandicapButton
							: awayHandicapButton + homeHandicapButton
					}
				  </div>`
								contentDiv = `${contentDiv} ${content}`
							})
							break
						case 'overUnder':
							extendList.forEach(function (overUnder, indexOverUnder) {
								const isAltLine = overUnder.isAlt ? '1' : '0'
								const overUnderHomeId = `${eventId}|${displayPeriod}|3|3|${isAltLine}|${overUnder.points}|${homeTeamType}`
								const overUnderAwayId = `${eventId}|${displayPeriod}|3|4|${isAltLine}|${overUnder.points}|${awayTeamType}`
								const overUnderHomeIdSelected = `${eventId}|${displayPeriod}|3|3|${overUnder.points}`
								const overUnderAwayIdSelected = `${eventId}|${displayPeriod}|3|4|${overUnder.points}`
								const content = overUnder.offline
									? `
				  <div class='styleButtonsExtend ${extendListClassName}'>
					<div class='styleButtonWrapper'>
					<button
					aria-label='HHH'
					lineId='${overUnder.lineId}'
					id='t${overUnderHomeId}'
					class='market-btn styleButton stylePill styleVertical style-market-btn styleDisabled ${extendListClassName}'
				  	>
					  <i class="icon icon-lock styleUnavailable offlineButton"></i>
				  	</button>
					</div>
					<div class='styleButtonWrapper'>
					<button
					aria-label='HHH'
					lineId='${overUnder.lineId}'
					id='t${overUnderAwayId}'
					class='market-btn styleButton stylePill styleVertical style-market-btn styleDisabled ${extendListClassName}'
				  >
					  <i class="icon icon-lock styleUnavailable offlineButton"></i>
				  </button>
					</div>
				  </div>`
									: `
				  <div class='styleButtonsExtend ${extendListClassName}'>
					<div class='styleButtonWrapper'>
					<button aria-label="OverUnder" 
					id='t${overUnderHomeId}'
					class="market-btn styleButton stylePill styleVertical style-market-btn ${extendListClassName} ${
											selectedOddsWithoutAlt.includes(
												overUnderHomeIdSelected,
											) || quickBetWithoutAlt === overUnderHomeIdSelected
												? 'styleSelected'
												: ''
									  }"
					lineId='${overUnder.lineId}'
					title="${overUnder.points}"
					onclick="${eventIdOnClickName}(event,'${overUnderHomeId}', '${leagueId}', '${eventId}', '${
											overUnder.overOdds
									  }','${sportName}','${sportId}')"
					>
					  <span class="styleLabelOdd ${extendListClassName}">${overUnder.points}</span>
					  <span class="stylePrice ${extendListClassName}">${overUnder.overOdds}</span>
					</button>
					</div>
					<div class='styleButtonWrapper'>
					  <button
						aria-label='HHH'
						id='t${overUnderAwayId}'
						lineId='${overUnder.lineId}'
						class='market-btn styleButton stylePill styleVertical style-market-btn ${extendListClassName} ${
											selectedOddsWithoutAlt.includes(
												overUnderAwayIdSelected,
											) || quickBetWithoutAlt === overUnderAwayIdSelected
												? 'styleSelected'
												: ''
									  }'
						title='${overUnder.points}'
						onclick="${eventIdOnClickName}(event,'${overUnderAwayId}', '${leagueId}', '${eventId}', '${
											overUnder.underOdds
									  }','${sportName}','${sportId}')"
					  >
						<span class='styleLabelOdd ${extendListClassName}'>${overUnder.points}</span>
						<span class='stylePrice ${extendListClassName}'>${overUnder.underOdds}</span>
					  </button>
					</div>
				  </div>`
								contentDiv = `${contentDiv} ${content}`
							})
							break
						default:
							break
					}
				}
				const alternateButton = oddRef?.current?.querySelector(
					`#${type}${eventId}p${displayPeriod}`,
				)

				if (alternateButton) {
					let altlineIndex = extendList.findIndex((odd) => !odd.isAlt)
					let topPosition = 0

					topPosition = 10 + 50 * altlineIndex + 1 * altlineIndex
					const extendContainerId = `extend-${eventId}-${type}-p${displayPeriod}`

					const renderExpandButton = `<a class="styleExpandBtn styleExpandBtnModal" style="top: calc(50% + ${
						extendList.length % 2 === 1 ? 0 : 25.5
					}px); bottom: auto;" onclick="removeAllExtendOdds(event)"><i class="icon-expand styleExpandBtnIcon"></i></a>`

					const divAppend = `<div
					style='height: ${
						52 * (extendList.length || 0) + 4
					}px; top: -${topPosition}px; bottom: auto; left: -5px;'
					class='style-container style-visible ${extendListClassName}' id="${extendContainerId}">
					${contentDiv}
					${renderExpandButton}
					</div>`
					const currentExtendElement = alternateButton.querySelector(
						`#${extendContainerId}`,
					)

					// Check if current extend odd is exist in DOM, if not will insert to Alternate Button
					if (currentExtendElement) {
						currentExtendElement.innerHTML = `${contentDiv} ${renderExpandButton}`
					} else {
						alternateButton?.insertAdjacentHTML('afterbegin', divAppend)
					}
				}
			}
		}
	}
}

export const updateEventData = (
	oddRef,
	dataUpdate,
	originData,
	order,
	dataConfig,
	isAllPeriods,
) => {
	const { leagues = [] } = dataUpdate
	const prefix = 'eventElement'
	if (!oddRef?.current) return
	leagues.forEach((league, leagueIndex) => {
		const { events } = league
		const findIndexLeague = get(originData, ['leagues'], []).findIndex(
			(le) => le.id === league.id,
		)

		events.forEach((event, eventIndex) => {
			const findIndexEvent = get(
				originData,
				['leagues', findIndexLeague, 'events'],
				[],
			).findIndex((ev) => ev.id === event.id)

			const eventElement = oddRef.current.querySelector(`#${prefix}${event.id}`)
			let dataRenderEvent = ''
			if (order === 'searchPage') {
				dataRenderEvent = OddPageBindDataForEvent(renderEventSearchResult(), {
					...dataConfig,
					leagueId: league.id,
					leagueName: league.name,
					leagueCode: league.leagueCode,
					gameCode: league.gameCode,
					league,
					event,
					indexLeague: findIndexLeague,
					indexEvent: findIndexEvent,
				})
			} else {
				dataRenderEvent = OddPageBindDataForEvent(
					isAllPeriods
						? renderEventRowAllPeriod({
								order,
						  })
						: renderEventRow({
								order,
						  }),
					{
						...dataConfig,
						leagueId: league.id,
						leagueName: league.name,
						leagueCode: league.leagueCode,
						gameCode: league.gameCode,
						league,
						event,
						indexLeague: findIndexLeague,
						indexEvent: findIndexEvent,
					},
				)
			}

			if (eventElement) {
				eventElement.innerHTML = dataRenderEvent
			}
		})
	})
}

export const updateEventTeamTotal = (
	oddRef,
	dataUpdate,
	originData,
	order,
	dataConfig,
) => {
	const prefix = 'eventTeamTotal'
	const { leaguesByDateTotal = [] } = dataUpdate
	if (!oddRef?.current) return
	leaguesByDateTotal.forEach((lgByDate) => {
		const { leagues = [], leagueTime = 0 } = lgByDate
		leagues.forEach((league, leagueIndex) => {
			const { events } = league

			events.forEach((event, eventIndex) => {
				const eventElement = oddRef.current.querySelector(
					`#${prefix}${event.id}`,
				)
				const dataRenderEvent = OddPageBindDataForEvent(
					renderTeamTotalEvent({
						order,
					}),
					{
						...dataConfig,
						leagueId: league.id,
						leagueName: league.name,
						leagueCode: league.leagueCode,
						gameCode: league.gameCode || '',
						event,
						leagueTime,
					},
				)
				if (eventElement) {
					eventElement.innerHTML = dataRenderEvent
				}
			})
		})
	})
}

const getScore = (data) => {
	if (typeof data === 'object') {
		return Object.values(data).reduce((a, b) => a + b, 0)
	}
	return data
}

export const BetType = {
	1: 'THE_1X2',
	2: 'HANDICAP',
	3: 'OU',
	99: 'SPECIALS',
	THE_1X2: 1,
	HANDICAP: 2,
	OU: 3,
	HOME_TOTALS: 4,
	AWAY_TOTALS: 5,
	MIX_PARLAY: 6,
	TEASER: 7,
	MANUAL_PLAY: 8,
	SPECIALS: 9,
	OE: 97,
	OUTRIGHT: 99,
}

export const navigateEvent = (
	event,
	leagueData,
	navigate,
	esportDynamicRoutes,
) => {
	event?.preventDefault()
	const navigateData = JSON.parse(leagueData)
	let hash = ''
	const { subfix = '' } = navigateData
	if (subfix === 'corner') {
		hash = '#corners'
	}
	if (subfix === 'booking') {
		hash = '#bookings'
	}
	const gameCode = navigateData.gameCode || ''
	const leagueCode = navigateData.leagueCode.replaceAll(`${gameCode}-`, '')

	const link =
		`${navigateData?.sportId}` !== `${esportId}`
			? buildRouteUrl(
					routesStandard.sportLeagueEvent,
					{
						sportCode: (navigateData.sportName || '').replaceAll(' ', '-'),
						leagueCode: navigateData.leagueCode,
						eventId: hash ? navigateData.parentId : navigateData.eventId,
						eventName: (navigateData.eventName || '').replaceAll(' ', '-'),
					},
					true,
			  )
			: buildRouteUrl(
					esportDynamicRoutes.esportGameLeagueEvent,
					{
						sportCode: (navigateData.sportName || '').replaceAll(' ', '-'),
						leagueCode,
						gameCode,
						eventName: (navigateData.eventName || '').replaceAll(' ', '-'),
						eventId: navigateData.eventId,
					},
					true,
			  )

	const linkNavigate = `${link.toLowerCase()}${hash}`
	navigate(linkNavigate, {
		state: navigateData,
	})
}

export const navigateLeague = (leagueData, navigate, routes) => {
	const navigateData = JSON.parse(leagueData)
	const gameCode = navigateData.gameCode || ''
	const leagueCode = navigateData.leagueCode.replaceAll(`${gameCode}-`, '')
	const link =
		`${navigateData?.sportId}` !== `${esportId}`
			? buildRouteUrl(
					routes.sportLeague,
					{
						sportCode: (navigateData.sportName || '').replaceAll(' ', '-'),
						leagueCode: navigateData.leagueCode,
					},
					true,
			  )
			: buildRouteUrl(
					routes.esportGameLeague,
					{
						sportCode: (navigateData.sportName || '').replaceAll(' ', '-'),
						leagueCode,
						gameCode,
					},
					true,
			  )

	navigate(`${link.toLowerCase()}`, {
		state: navigateData,
	})
}

export const updateLiveIndicator = (
	oddRef,
	dataUpdate,
	originData,
	eventIdOnClickName,
	isSoccer,
	periodToDisplay,
	timeInterval = 10000,
	translate = {},
) => {
	let { update = [] } = dataUpdate
	if (!oddRef?.current) return
	const timeStamp = Date.now()
	const classIndicator = 'indicator'

	const elementOddUps = renderOddUp(classIndicator, timeStamp)
	const elementOddDown = renderOddDown(classIndicator, timeStamp)
	const elementOddUpSelected = renderOddUpSelected(classIndicator, timeStamp)
	const elementOddDownSelected = renderOddDownSelected(
		classIndicator,
		timeStamp,
	)
	// const oddIndicatorShowTime = timeInterval
	const iconLockOffline = `<i class="icon icon-lock styleUnavailable offlineButton"></i>`
	if (oddRef?.current) {
		oddRef.current.querySelectorAll(`.${classIndicator}`).forEach((element) => {
			element.remove()
		})
	}
	update.forEach((league, leagueIndex) => {
		const { events } = league
		events.forEach((event, eventIndex) => {
			const participants = get(event, ['participants'], []) || []
			if (participants?.length === 2 && isSoccer) {
				const redCardHomeElement = oddRef.current.querySelector(
					`#eventRedcardHome${event.id}`,
				)

				const scoreHomeElement = oddRef.current.querySelector(
					`#eventScoreHome${event.id}`,
				)
				const redCardAwayElement = oddRef.current.querySelector(
					`#eventRedcardAway${event.id}`,
				)
				const scoreAwayElement = oddRef.current.querySelector(
					`#eventScoreAway${event.id}`,
				)
				const redCardsHome = getScore(participants[0].redCards)
				const redCardsAway = getScore(participants[1].redCards)
				const scoreHome = getScore(participants[0].score)
				const scoreAway = getScore(participants[1].score)
				if (redCardHomeElement && redCardsHome > 0) {
					redCardHomeElement.classList.remove('styleBlank')
					redCardHomeElement.innerHTML = `${redCardsHome}`
				}
				if (scoreHomeElement) {
					scoreHomeElement.innerHTML = `${scoreHome}`
				}
				if (redCardAwayElement && redCardsAway > 0) {
					redCardAwayElement.classList.remove('styleBlank')
					redCardAwayElement.innerHTML = `${redCardsAway}`
				}
				if (scoreAwayElement) {
					scoreAwayElement.innerHTML = `${scoreAway}`
				}
			}
			const findIndexLeague = get(originData, ['leagues'], []).findIndex(
				(le) => le.id === league.id,
			)
			const findEvent =
				get(originData, ['leagues', findIndexLeague, 'events'], []).find(
					(ev) => ev.id === event.id,
				) || {}

			const periods = get(event, ['periods'], {})
			const homeTeamType = get(findEvent, 'homeTeamType', 0)
			const awayTeamType = get(findEvent, 'awayTeamType', 1)
			Object.entries(periods).forEach((periodEntry) => {
				const [periodToDisplay, period] = periodEntry
				const handicap = get(period, 'handicap', [])
				const overUnder = get(period, 'overUnder', [])
				const moneyLine = get(period, 'moneyLine', null)

				handicap.forEach((hd, indexHandicap) => {
					const isAltLine = hd.isAlt ? 1 : 0
					const idHomeToSearch = `${
						event.id
					}\\|${periodToDisplay}\\|2\\|0\\|${isAltLine}\\|${(
						hd.homeSpread || ''
					)
						.replace('.', '\\.')
						.replace('+', '\\+')}\\|${homeTeamType}`

					const idAwayToSearch = `${
						event.id
					}\\|${periodToDisplay}\\|2\\|1\\|${isAltLine}\\|${(
						hd.awaySpread || ''
					)
						.replace('.', '\\.')
						.replace('+', '\\+')}\\|${awayTeamType}`
					const idHomeToSet = `${idHomeToSearch}`
					const awayIdToSet = `${idAwayToSearch}`

					const homeElementArray = oddRef.current.querySelectorAll(
						`#t${idHomeToSearch}`,
					)
					const awayElementArray = oddRef.current.querySelectorAll(
						`#t${idAwayToSearch}`,
					)

					homeElementArray.forEach((homeElement, indexHome) => {
						const awayElement = awayElementArray[indexHome]
						if (homeElement && awayElement) {
							if (
								homeElement.previousSibling?.tagName?.toLowerCase() === 'span'
							) {
								homeElement.previousSibling.remove()
							}
							if (
								awayElement.previousSibling?.tagName?.toLowerCase() === 'span'
							) {
								awayElement.previousSibling.remove()
							}
							if (hd.offline) {
								homeElement.classList.add('styleDisabled')
								awayElement.classList.add('styleDisabled')
								homeElement.classList.remove('styleSelected')
								awayElement.classList.remove('styleSelected')
								homeElement.removeAttribute('onclick')
								awayElement.removeAttribute('onclick')
								homeElement.setAttribute('tooltip', translate?.currentlyOffline)
								awayElement.setAttribute('tooltip', translate?.currentlyOffline)
								homeElement.innerHTML = iconLockOffline
								awayElement.innerHTML = iconLockOffline
							} else {
								homeElement.classList.remove('styleDisabled')
								awayElement.classList.remove('styleDisabled')
								homeElement.removeAttribute('tooltip')
								awayElement.removeAttribute('tooltip')
								homeElement.setAttribute('lineId', hd.lineId)
								awayElement.setAttribute('lineId', hd.lineId)
								if (hd.homeSpread && hd.homeOdds) {
									homeElement.setAttribute('title', hd.homeSpread)
									homeElement.innerHTML = ` <span class="styleLabelOdd">${hd.homeSpread}</span>
									  <span class="stylePrice">${hd.homeOdds}</span>`
								}

								if (hd.homeOdds) {
									homeElement.setAttribute(
										'onclick',
										`${eventIdOnClickName}(event,'${idHomeToSet}', '${league.id}','${event.id}','${hd.homeOdds}')`,
									)
								}

								if (hd.awaySpread && hd.awayOdds) {
									awayElement.setAttribute('title', hd.awaySpread)
									awayElement.innerHTML = ` <span class="styleLabelOdd">${hd.awaySpread}</span>
									 <span class="stylePrice">${hd.awayOdds}</span>`
								}

								if (hd.awayOdds) {
									awayElement.setAttribute(
										'onclick',
										`${eventIdOnClickName}(event,'${awayIdToSet}', '${league.id}','${event.id}','${hd.awayOdds}')`,
									)
								}
								const isHomeSelected =
									homeElement.classList.contains('styleSelected')
								const isAwaySelected =
									awayElement.classList.contains('styleSelected')
								if (hd.homeSignal === 1) {
									homeElement.insertAdjacentHTML(
										'afterbegin',
										isHomeSelected ? elementOddUpSelected : elementOddUps,
									)
								}
								if (hd.homeSignal === -1) {
									homeElement.insertAdjacentHTML(
										'afterbegin',
										isHomeSelected ? elementOddDownSelected : elementOddDown,
									)
								}

								if (hd.awaySignal === 1) {
									awayElement.insertAdjacentHTML(
										'afterbegin',
										isAwaySelected ? elementOddUpSelected : elementOddUps,
									)
								}
								if (hd.awaySignal === -1) {
									awayElement.insertAdjacentHTML(
										'afterbegin',
										isAwaySelected ? elementOddDownSelected : elementOddDown,
									)
								}
							}
						}
					})
				})
				overUnder.forEach((ou, indexOverUnder) => {
					const isAltLine = ou.isAlt ? 1 : 0

					const idHomeToSearch = `${
						event.id
					}\\|${periodToDisplay}\\|3\\|3\\|${isAltLine}\\|${(ou.points || '')
						.replace('.', '\\.')
						.replace('+', '\\+')}\\|${homeTeamType}`
					const idAwayToSearch = `${
						event.id
					}\\|${periodToDisplay}\\|3\\|4\\|${isAltLine}\\|${(ou.points || '')
						.replace('.', '\\.')
						.replace('+', '\\+')}\\|${awayTeamType}`

					const idHomeToSet = `${idHomeToSearch}`

					const awayIdToSet = `${idAwayToSearch}`

					const homeElementArray = oddRef.current.querySelectorAll(
						`#t${idHomeToSearch}`,
					)
					const awayElementArray = oddRef.current.querySelectorAll(
						`#t${idAwayToSearch}`,
					)

					homeElementArray.forEach((homeElement, indexHome) => {
						const awayElement = awayElementArray[indexHome]
						if (homeElement && awayElement) {
							if (
								homeElement.previousSibling?.tagName?.toLowerCase() === 'span'
							) {
								homeElement.previousSibling.remove()
							}
							if (
								awayElement.previousSibling?.tagName?.toLowerCase() === 'span'
							) {
								awayElement.previousSibling.remove()
							}
							if (ou.offline) {
								homeElement.classList.add('styleDisabled')
								awayElement.classList.add('styleDisabled')
								homeElement.classList.remove('styleSelected')
								awayElement.classList.remove('styleSelected')
								homeElement.removeAttribute('onclick')
								awayElement.removeAttribute('onclick')
								homeElement.innerHTML = iconLockOffline
								awayElement.innerHTML = iconLockOffline
								homeElement.setAttribute('tooltip', translate?.currentlyOffline)
								awayElement.setAttribute('tooltip', translate?.currentlyOffline)
							} else {
								homeElement.removeAttribute('tooltip')
								awayElement.removeAttribute('tooltip')
								homeElement.classList.remove('styleDisabled')
								awayElement.classList.remove('styleDisabled')
								homeElement.setAttribute('lineId', ou.lineId)
								awayElement.setAttribute('lineId', ou.lineId)
								if (ou.points) {
									homeElement.setAttribute('title', ou.points)

									awayElement.setAttribute('title', ou.points)
								}
								if (ou.overOdds) {
									homeElement.innerHTML = ` <span class="styleLabelOdd">${ou.points}</span>
								<span class="stylePrice">${ou.overOdds}</span>`
									homeElement.setAttribute(
										'onclick',
										`${eventIdOnClickName}(event,'${idHomeToSet}', '${league.id}','${event.id}','${ou.overOdds}')`,
									)
								}
								if (ou.underOdds) {
									awayElement.innerHTML = ` <span class="styleLabelOdd">${ou.points}</span>
								<span class="stylePrice">${ou.underOdds}</span>`
									awayElement.setAttribute(
										'onclick',
										`${eventIdOnClickName}(event,'${awayIdToSet}', '${league.id}','${event.id}','${ou.underOdds}')`,
									)
								}

								const isHomeSelected =
									homeElement.classList.contains('styleSelected')
								const isAwaySelected =
									awayElement.classList.contains('styleSelected')

								if (ou.overSignal === 1) {
									homeElement.insertAdjacentHTML(
										'afterbegin',
										isHomeSelected ? elementOddUpSelected : elementOddUps,
									)
								}
								if (ou.overSignal === -1) {
									homeElement.insertAdjacentHTML(
										'afterbegin',
										isHomeSelected ? elementOddDownSelected : elementOddDown,
									)
								}

								if (ou.underSignal === 1) {
									awayElement.insertAdjacentHTML(
										'afterbegin',
										isAwaySelected ? elementOddUpSelected : elementOddUps,
									)
								}
								if (ou.underSignal === -1) {
									awayElement.insertAdjacentHTML(
										'afterbegin',
										isAwaySelected ? elementOddDownSelected : elementOddDown,
									)
								}
							}
						}
					})
				})

				if (moneyLine) {
					const idHomeToSearch = `${event.id}\\|${periodToDisplay}\\|1\\|0\\|0\\|0\\|${homeTeamType}`
					const idAwayToSearch = `${event.id}\\|${periodToDisplay}\\|1\\|1\\|0\\|0\\|${awayTeamType}`
					const idDrawToSearch = `${event.id}\\|${periodToDisplay}\\|1\\|2\\|0\\|0\\|2`

					const idHomeToSet = `${idHomeToSearch}`

					const awayIdToSet = `${idAwayToSearch}`
					const drawIdToSet = `${idDrawToSearch}`

					const homeElement = oddRef.current.querySelector(
						`#t${idHomeToSearch}`,
					)
					const awayElement = oddRef.current.querySelector(
						`#t${idAwayToSearch}`,
					)
					const drawElement = oddRef.current.querySelector(
						`#t${idDrawToSearch}`,
					)

					if (homeElement && awayElement) {
						if (
							homeElement.previousSibling?.tagName?.toLowerCase() === 'span'
						) {
							homeElement.previousSibling.remove()
						}
						if (
							awayElement.previousSibling?.tagName?.toLowerCase() === 'span'
						) {
							awayElement.previousSibling.remove()
						}
						if (moneyLine.offline) {
							homeElement.classList.add('styleDisabled')
							awayElement.classList.add('styleDisabled')
							homeElement.classList.remove('styleSelected')
							awayElement.classList.remove('styleSelected')
							homeElement.removeAttribute('onclick')
							awayElement.removeAttribute('onclick')
							homeElement.innerHTML = iconLockOffline
							awayElement.innerHTML = iconLockOffline
							homeElement.setAttribute('tooltip', translate?.currentlyOffline)
							awayElement.setAttribute('tooltip', translate?.currentlyOffline)
						} else {
							if (moneyLine.homePrice) {
								homeElement.classList.remove('styleDisabled')
								homeElement.removeAttribute('tooltip')
								homeElement.setAttribute('lineId', moneyLine.lineId)
								homeElement.setAttribute('title', moneyLine.homePrice)
								homeElement.innerHTML = ` 
							<span class="stylePrice">${moneyLine.homePrice}</span>`
								homeElement.setAttribute(
									'onclick',
									`${eventIdOnClickName}(event,'${idHomeToSet}', '${league.id}','${event.id}','${moneyLine.homePrice}')`,
								)
							}

							if (moneyLine.awayPrice) {
								awayElement.classList.remove('styleDisabled')
								awayElement.removeAttribute('tooltip')
								awayElement.setAttribute('lineId', moneyLine.lineId)
								awayElement.setAttribute('title', moneyLine.awayPrice)
								awayElement.innerHTML = ` 
							  <span class="stylePrice">${moneyLine.awayPrice}</span>`
								awayElement.setAttribute(
									'onclick',
									`${eventIdOnClickName}(event,'${awayIdToSet}', '${league.id}','${event.id}','${moneyLine.awayPrice}')`,
								)
							}

							const isHomeSelected =
								homeElement.classList.contains('styleSelected')
							const isAwaySelected =
								awayElement.classList.contains('styleSelected')

							if (moneyLine.homeSignal === 1) {
								homeElement.insertAdjacentHTML(
									'afterbegin',
									isHomeSelected ? elementOddUpSelected : elementOddUps,
								)
							}
							if (moneyLine.homeSignal === -1) {
								homeElement.insertAdjacentHTML(
									'afterbegin',
									isHomeSelected ? elementOddDownSelected : elementOddDown,
								)
							}

							if (moneyLine.awaySignal === 1) {
								awayElement.insertAdjacentHTML(
									'afterbegin',
									isAwaySelected ? elementOddUpSelected : elementOddUps,
								)
							}
							if (moneyLine.awaySignal === -1) {
								awayElement.insertAdjacentHTML(
									'afterbegin',
									isAwaySelected ? elementOddDownSelected : elementOddDown,
								)
							}
						}
					}

					if (drawElement) {
						const isDrawSelected =
							drawElement.classList.contains('styleSelected')
						if (
							drawElement.previousSibling?.tagName?.toLowerCase() === 'span'
						) {
							drawElement.previousSibling.remove()
						}
						if (moneyLine.offline) {
							drawElement.classList.add('styleDisabled')
							drawElement.classList.remove('styleSelected')
							drawElement.innerHTML = iconLockOffline
							drawElement.removeAttribute('onclick')
							drawElement.setAttribute('tooltip', translate?.currentlyOffline)
						} else {
							if (moneyLine.drawPrice) {
								drawElement.classList.remove('styleDisabled')
								drawElement.removeAttribute('tooltip')
								drawElement.setAttribute('lineId', moneyLine.lineId)
								drawElement.setAttribute('title', moneyLine.drawPrice)
								drawElement.innerHTML = ` 
							<span class="stylePrice">${moneyLine.drawPrice}</span>`
								drawElement.setAttribute(
									'onclick',
									`${eventIdOnClickName}(event,'${drawIdToSet}', '${league.id}','${event.id}','${moneyLine.drawPrice}')`,
								)
							}
							if (moneyLine.drawSignal === 1) {
								drawElement.insertAdjacentHTML(
									'afterbegin',
									isDrawSelected ? elementOddUpSelected : elementOddUps,
								)
							}
							if (moneyLine.drawSignal === -1) {
								drawElement.insertAdjacentHTML(
									'afterbegin',
									isDrawSelected ? elementOddDownSelected : elementOddDown,
								)
							}
						}
					}
				}
			})
		})
	})
}

export const updateSelectedQuickBet = (oddRef, tempOddData, quickBet) => {}

export const updateSelectedOdds = (
	oddRef,
	selectedOdds,
	tempOddData,
	eventIdOnClickName,
	quickBet,
	enableQuickBet,
) => {
	const arrayOfButton = oddRef.current?.querySelectorAll(`button`)
	const quickBetId = get(quickBet, ['selection', 'selectionId'], '')
	let quickBetWithoutLineId = quickBetId.split('|')
	quickBetWithoutLineId.shift()
	quickBetWithoutLineId = quickBetWithoutLineId.join('|')
	quickBetWithoutLineId = `t${quickBetWithoutLineId}`
	const oddIdArray = selectedOdds.map((odd) => `t${odd.oddsId}`)
	const arrayOfSelectedIds = selectedOdds.map((odd) => {
		const arrayOddDetail = odd.oddsId.split('|')
		return {
			eventId: arrayOddDetail[0],
			period: arrayOddDetail[1],
			betType: arrayOddDetail[2],
			selectionType: arrayOddDetail[3],
			point: arrayOddDetail[5],
			leagueId: odd.leagueId,
		}
	})
	if (arrayOfButton?.length > 0) {
		arrayOfButton.forEach((element) => {
			if (element.id) {
				if (enableQuickBet) {
					if (element.id === quickBetWithoutLineId) {
						element?.classList.add('styleSelected-quick-bet')
					} else {
						element?.classList.remove('styleSelected-quick-bet')
						let elementIdToSearch = element.id.split('|')
						elementIdToSearch.pop()
						elementIdToSearch = elementIdToSearch.join('|')
						if (!oddIdArray.includes(elementIdToSearch)) {
							element?.classList.remove('styleSelected')
						}
					}
					return
				}

				const elementId = element.id.split('|')
				const elementIdObject = {
					eventId: elementId[0].replace('t', ''),
					period: elementId[1],
					betType: elementId[2],
					selectionType: elementId[3],
					alternateLine: elementId[4],
					point: elementId[5],
					teamType: elementId[6],
				}
				const findSelected = arrayOfSelectedIds.find((selected) => {
					return (
						selected.eventId === elementIdObject.eventId &&
						selected.period === elementIdObject.period &&
						selected.betType === elementIdObject.betType
					)
				})
				if (findSelected) {
					if (
						findSelected.point === elementIdObject.point &&
						findSelected.selectionType === elementIdObject.selectionType
					) {
						if (!element.classList.contains('styleDisabled')) {
							element?.classList.add('styleSelected')
						}
					} else {
						/*
						// case selected is in Alternate Line
						*/
						const findLeague = get(tempOddData, ['leagues'], []).find(
							(le) => le.id === findSelected.leagueId,
						)
						const { events } = findLeague || {}
						const findEvent = events.find(
							(ev) => `${ev.id}` === findSelected.eventId,
						)
						const period = get(
							findEvent,
							['periods', findSelected.period],
							null,
						)
						if (period) {
							const { handicap = [], overUnder = [] } = period
							if (findSelected.betType === '2') {
								const currentIsHome = elementIdObject.selectionType === '0'
								let selectedHandicap = null

								if (findSelected.selectionType === '0') {
									selectedHandicap = handicap.find(
										(h) => h.homeSpread === findSelected.point,
									)
								} else {
									selectedHandicap = handicap.find(
										(h) => h.awaySpread === findSelected.point,
									)
								}
								if (selectedHandicap) {
									const alternateLineHd = selectedHandicap.isAlt ? '1' : '0'

									element.setAttribute('lineId', selectedHandicap.lineId)

									if (currentIsHome) {
										if (
											findSelected.point === selectedHandicap.homeSpread &&
											elementIdObject.selectionType ===
												findSelected.selectionType
										) {
											if (!element.classList.contains('styleDisabled')) {
												element?.classList.add('styleSelected')
											}
										}
										const newCurrentId = `t${elementIdObject.eventId}|${elementIdObject.period}|2|0|${alternateLineHd}|${selectedHandicap.homeSpread}|${findEvent.homeTeamType}`
										element.setAttribute('title', selectedHandicap.homeSpread)
										element.setAttribute('id', newCurrentId)
										element.innerHTML = ` <span class="styleLabelOdd">${selectedHandicap.homeSpread}</span>
								  			<span class="stylePrice">${selectedHandicap.homeOdds}</span>`
										element.setAttribute(
											'onclick',
											`${eventIdOnClickName}(event,'${newCurrentId.replace(
												't',
												'',
											)}', '${findSelected.leagueId}','${
												findSelected.eventId
											}','${selectedHandicap.homeOdds}')`,
										)
									} else {
										if (
											findSelected.point === selectedHandicap.awaySpread &&
											elementIdObject.selectionType ===
												findSelected.selectionType
										) {
											if (!element.classList.contains('styleDisabled')) {
												element?.classList.add('styleSelected')
											}
										}
										const newCurrentId = `t${elementIdObject.eventId}|${elementIdObject.period}|2|1|${alternateLineHd}|${selectedHandicap.awaySpread}|${findEvent.awayTeamType}`
										element.setAttribute('title', selectedHandicap.awaySpread)
										element.setAttribute('id', newCurrentId)
										element.innerHTML = ` <span class="styleLabelOdd">${selectedHandicap.awaySpread}</span>
								  			<span class="stylePrice">${selectedHandicap.awayOdds}</span>`
										element.setAttribute(
											'onclick',
											`${eventIdOnClickName}(event,'${newCurrentId.replace(
												't',
												'',
											)}', '${findSelected.leagueId}','${
												findSelected.eventId
											}','${selectedHandicap.awayOdds}')`,
										)
									}
								}
							}
							if (findSelected.betType === '3') {
								const currentIsHome = elementIdObject.selectionType === '3'
								let selectedOverUnder = null

								selectedOverUnder = overUnder.find(
									(ou) => ou.points === findSelected.point,
								)

								if (selectedOverUnder) {
									const alternateLineHd = selectedOverUnder.isAlt ? '1' : '0'

									element.setAttribute('lineId', selectedOverUnder.lineId)

									if (currentIsHome) {
										if (
											findSelected.point === selectedOverUnder.points &&
											elementIdObject.selectionType ===
												findSelected.selectionType
										) {
											if (!element.classList.contains('styleDisabled')) {
												element?.classList.add('styleSelected')
											}
										}
										const newCurrentId = `t${elementIdObject.eventId}|${elementIdObject.period}|3|3|${alternateLineHd}|${selectedOverUnder.points}|${findEvent.homeTeamType}`

										element.setAttribute('title', selectedOverUnder.points)
										element.setAttribute('id', newCurrentId)
										element.innerHTML = ` <span class="styleLabelOdd">${selectedOverUnder.points}</span>
								  			<span class="stylePrice">${selectedOverUnder.overOdds}</span>`
										element.setAttribute(
											'onclick',
											`${eventIdOnClickName}(event,'${newCurrentId.replace(
												't',
												'',
											)}', '${findSelected.leagueId}','${
												findSelected.eventId
											}','${selectedOverUnder.overOdds}')`,
										)
									} else {
										if (
											findSelected.point === selectedOverUnder.points &&
											elementIdObject.selectionType ===
												findSelected.selectionType
										) {
											if (!element.classList.contains('styleDisabled')) {
												element?.classList.add('styleSelected')
											}
										}
										const newCurrentId = `t${elementIdObject.eventId}|${elementIdObject.period}|3|4|${alternateLineHd}|${selectedOverUnder.points}|${findEvent.awayTeamType}`

										element.setAttribute('title', selectedOverUnder.points)
										element.setAttribute('id', newCurrentId)
										element.innerHTML = ` <span class="styleLabelOdd">${selectedOverUnder.points}</span>
								  			<span class="stylePrice">${selectedOverUnder.underOdds}</span>`
										element.setAttribute(
											'onclick',
											`${eventIdOnClickName}(event,'${newCurrentId.replace(
												't',
												'',
											)}', '${findSelected.leagueId}','${
												findSelected.eventId
											}','${selectedOverUnder.underOdds}')`,
										)
									}
								}
							}
						}
					}
				} else {
					element?.classList.remove('styleSelected')
					if (elementIdObject.alternateLine === '1') {
						const onClickAttrArr = (
							element.getAttribute('onClick') || ''
						).split(',')
						const leagueId = onClickAttrArr[2]?.replaceAll("'", '').trim()
						const findLeague = get(tempOddData, ['leagues'], []).find(
							(le) => le.id === parseInt(leagueId),
						)
						const { events } = findLeague || {}
						const findEvent = events.find(
							(ev) => `${ev.id}` === elementIdObject.eventId,
						)
						const period = get(
							findEvent,
							['periods', elementIdObject.period],
							null,
						)
						if (period) {
							const { handicap = [], overUnder = [] } = period
							if (elementIdObject.betType === '2') {
								const currentIsHome = elementIdObject.selectionType === '0'
								const mainLineHandicap = handicap.find((h) => !h.isAlt)
								if (mainLineHandicap) {
									element.setAttribute('lineId', mainLineHandicap.lineId)
									if (currentIsHome) {
										const newCurrentId = `t${elementIdObject.eventId}|${elementIdObject.period}|2|0|0|${mainLineHandicap.homeSpread}|${findEvent.homeTeamType}`
										element.setAttribute('title', mainLineHandicap.homeSpread)
										element.setAttribute('id', newCurrentId)
										element.innerHTML = ` <span class="styleLabelOdd">${mainLineHandicap.homeSpread}</span>
								  			<span class="stylePrice">${mainLineHandicap.homeOdds}</span>`
										element.setAttribute(
											'onclick',
											`${eventIdOnClickName}(event,'${newCurrentId.replace(
												't',
												'',
											)}', '${leagueId}','${elementIdObject.eventId}','${
												mainLineHandicap.homeOdds
											}')`,
										)
									} else {
										const newCurrentId = `t${elementIdObject.eventId}|${elementIdObject.period}|2|1|0|${mainLineHandicap.awaySpread}|${findEvent.awayTeamType}`
										element.setAttribute('title', mainLineHandicap.awaySpread)
										element.setAttribute('id', newCurrentId)
										element.innerHTML = ` <span class="styleLabelOdd">${mainLineHandicap.awaySpread}</span>
								  			<span class="stylePrice">${mainLineHandicap.awayOdds}</span>`
										element.setAttribute(
											'onclick',
											`${eventIdOnClickName}(event,'${newCurrentId.replace(
												't',
												'',
											)}', '${leagueId}','${elementIdObject.eventId}','${
												mainLineHandicap.awayOdds
											}')`,
										)
									}
								}
							}
							if (elementIdObject.betType === '3') {
								const currentIsHome = elementIdObject.selectionType === '3'
								const mainLineOverUnder = overUnder.find((ou) => !ou.isAlt)
								if (mainLineOverUnder) {
									element.setAttribute('lineId', mainLineOverUnder.lineId)
									if (currentIsHome) {
										const newCurrentId = `t${elementIdObject.eventId}|${elementIdObject.period}|3|3|0|${mainLineOverUnder.points}|${findEvent.homeTeamType}`
										element.setAttribute('title', mainLineOverUnder.points)
										element.setAttribute('id', newCurrentId)
										element.innerHTML = ` <span class="styleLabelOdd">${mainLineOverUnder.points}</span>
								  			<span class="stylePrice">${mainLineOverUnder.overOdds}</span>`
										element.setAttribute(
											'onclick',
											`${eventIdOnClickName}(event,'${newCurrentId.replace(
												't',
												'',
											)}', '${leagueId}','${elementIdObject.eventId}','${
												mainLineOverUnder.overOdds
											}')`,
										)
									} else {
										const newCurrentId = `t${elementIdObject.eventId}|${elementIdObject.period}|3|4|0|${mainLineOverUnder.points}|${findEvent.awayTeamType}`
										element.setAttribute('title', mainLineOverUnder.points)
										element.setAttribute('id', newCurrentId)
										element.innerHTML = ` <span class="styleLabelOdd">${mainLineOverUnder.points}</span>
								  			<span class="stylePrice">${mainLineOverUnder.underOdds}</span>`
										element.setAttribute(
											'onclick',
											`${eventIdOnClickName}(event,'${newCurrentId.replace(
												't',
												'',
											)}', '${leagueId}','${elementIdObject.eventId}','${
												mainLineOverUnder.underOdds
											}')`,
										)
									}
								}
							}
						}
					}
				}
			}
		})
	}
}

export const updateDataTemp = (dataEuro, tempOddData) => {
	const tempDataCopy = cloneDeep(tempOddData)
	const { leagues = [], update = [] } = dataEuro
	leagues.forEach((leagueDataUpdate, leagueDataUpdateIndex) => {
		const { events = [] } = leagueDataUpdate
		events.forEach((eventUpdate, eventUpdateIndex) => {
			const findLeagueIndexOrigin = (tempDataCopy.leagues || []).findIndex(
				(lea) => lea.id === leagueDataUpdate.id,
			)
			if (findLeagueIndexOrigin !== -1) {
				const findEventIndexOrigin = (
					tempDataCopy.leagues[findLeagueIndexOrigin].events || []
				).findIndex((ev) => ev.id === eventUpdate.id)
				if (findLeagueIndexOrigin !== -1 && findEventIndexOrigin !== -1) {
					tempDataCopy.leagues[findLeagueIndexOrigin].events[
						findEventIndexOrigin
					] = eventUpdate
				}
			}
		})
	})

	if (!tempDataCopy) {
		return
	}

	update.forEach((leagueDataUpdate, leagueDataUpdateIndex) => {
		const { events = [] } = leagueDataUpdate
		events.forEach((eventUpdate, eventUpdateIndex) => {
			const findLeagueIndexOrigin = (tempDataCopy.leagues || []).findIndex(
				(lea) => lea.id === leagueDataUpdate.id,
			)
			if (findLeagueIndexOrigin !== -1) {
				const findEventIndexOrigin = (
					tempDataCopy.leagues[findLeagueIndexOrigin].events || []
				).findIndex((ev) => ev.id === eventUpdate.id)
				if (findEventIndexOrigin !== -1) {
					const originEvent = get(
						tempDataCopy,
						['leagues', findLeagueIndexOrigin, 'events', findEventIndexOrigin],
						{},
					)

					const periods = get(eventUpdate, ['periods'], {})
					Object.entries(periods).forEach((periodEntry) => {
						const [periodToDisplay, period] = periodEntry
						const handicapOrigin = get(
							originEvent,
							['periods', periodToDisplay, 'handicap'],
							[],
						)
						const overUnderOrigin = get(
							originEvent,
							['periods', periodToDisplay, 'overUnder'],
							[],
						)
						let moneyLineOrigin = get(
							originEvent,
							['periods', periodToDisplay, 'moneyLine'],
							{},
						)
						const handicapUpdate = get(period, ['handicap'], [])
						const overUnderUpdate = get(period, ['overUnder'], [])
						const moneyLineUpdate = get(period, ['moneyLine'], {})
						handicapUpdate.forEach((hdu) => {
							const findIndexHandicapUpdate = handicapOrigin.findIndex(
								(hdo) => {
									return (
										hdo.homeSpread === hdu.homeSpread &&
										hdo.awaySpread === hdu.awaySpread
									)
								},
							)
							if (findIndexHandicapUpdate !== -1) {
								set(handicapOrigin, [findIndexHandicapUpdate], {
									...handicapOrigin[findIndexHandicapUpdate],
									...hdu,
								})
							}
						})
						overUnderUpdate.forEach((ouupdate) => {
							const findIndexOUUpdate = overUnderOrigin.findIndex(
								(ouorigin) => {
									return ouorigin.points === ouupdate.points
								},
							)
							if (findIndexOUUpdate !== -1) {
								set(overUnderOrigin, [findIndexOUUpdate], {
									...overUnderOrigin[findIndexOUUpdate],
									...ouupdate,
								})
							}
						})
						moneyLineOrigin = {
							...moneyLineOrigin,
							...moneyLineUpdate,
						}
						set(
							originEvent,
							['periods', periodToDisplay, 'handicap'],
							handicapOrigin,
						)
						set(
							originEvent,
							['periods', periodToDisplay, 'overUnder'],
							overUnderOrigin,
						)
						set(
							originEvent,
							['periods', periodToDisplay, 'moneyLine'],
							moneyLineOrigin,
						)
					})

					set(
						tempDataCopy,
						['leagues', findLeagueIndexOrigin, 'events', findEventIndexOrigin],
						originEvent,
					)
				}
			}
		})
	})
	return tempDataCopy
}

export const updateDataTempTeamTotal = (dataUpdate, tempOddData) => {
	const tempDataCopy = cloneDeep(tempOddData)
	if (!tempDataCopy) {
		return {}
	}
	const { leaguesByDateTotal = [], leaguesByDateTotalUpdate: update = [] } =
		dataUpdate
	leaguesByDateTotal.forEach((leagueByDateUpdate) => {
		const { leagues = [] } = leagueByDateUpdate

		if (leagues[0]) {
			const league = leagues[0]
			const { events = [] } = league
			const findIndexLeagueByDate = tempDataCopy.leaguesByDateTotal.findIndex(
				(le) => le.leagueTime === leagueByDateUpdate.leagueTime,
			)
			if (findIndexLeagueByDate !== -1) {
				const eventsOrigin =
					tempDataCopy.leaguesByDateTotal[findIndexLeagueByDate].leagues[0]
						.events || []
				const eventsUpdate = eventsOrigin.map((evOrigin) => {
					const findEv = events.find((evUpdate) => evUpdate.id === evOrigin.id)
					if (findEv) {
						return findEv
					} else {
						return evOrigin
					}
				})
				set(
					tempDataCopy,
					[
						'leaguesByDateTotal',
						findIndexLeagueByDate,
						'leagues',
						'0',
						'events',
					],
					eventsUpdate,
				)
			}
		}
	})

	update.forEach((leagueByDateUpdate) => {
		const { leagues = [] } = leagueByDateUpdate

		if (leagues[0]) {
			const league = leagues[0]
			const { events = [] } = league
			const findIndexLeagueByDate = tempDataCopy.leaguesByDateTotal.findIndex(
				(le) => le.leagueTime === leagueByDateUpdate.leagueTime,
			)
			if (findIndexLeagueByDate !== -1) {
				const eventsOrigin =
					tempDataCopy.leaguesByDateTotal[findIndexLeagueByDate].leagues[0]
						.events || []
				const eventsUpdate = eventsOrigin.map((evOrigin) => {
					const findEv = events.find((evUpdate) => evUpdate.id === evOrigin.id)
					const { periods } = evOrigin
					const result = { ...evOrigin }
					if (findEv) {
						Object.entries(periods).forEach((periodEntry) => {
							const [periodToDisplay] = periodEntry
							const away = get(
								findEv,
								['periods', periodToDisplay, 'teamTotals', 'away'],
								{},
							)
							const home = get(
								findEv,
								['periods', periodToDisplay, 'teamTotals', 'home'],
								{},
							)
							const awayResult = {
								lineId: away.lineId,
								isAlt: away.isAlt,
								overOdds: away.overOdds,
								underOdds: away.underOdds,
								points: away.points,
								offline: away.offline,
								unavailable: away.unavailable,
							}

							const homeResult = {
								lineId: home.lineId,
								isAlt: home.isAlt,
								overOdds: home.overOdds,
								underOdds: home.underOdds,
								points: home.points,
								offline: home.offline,
								unavailable: home.unavailable,
							}

							set(
								result,
								['periods', periodToDisplay, 'teamTotals', 'away'],
								awayResult,
							)
							set(
								result,
								['periods', periodToDisplay, 'teamTotals', 'home'],
								homeResult,
							)
						})

						return result
					} else {
						return evOrigin
					}
				})
				set(
					tempDataCopy,
					[
						'leaguesByDateTotal',
						findIndexLeagueByDate,
						'leagues',
						'0',
						'events',
					],
					eventsUpdate,
				)
			}
		}
	})

	return tempDataCopy
}

export const updateLiveIndicatorTeamTotal = (
	oddRef,
	dataUpdate,
	originData,
	eventIdOnClickName,
	translate = {},
) => {
	let { leaguesByDateTotalUpdate: update = [] } = dataUpdate
	if (!oddRef?.current) return
	const timeStamp = Date.now()
	const classIndicator = 'indicator'
	const elementOddUps = renderOddUp(classIndicator, timeStamp)
	const elementOddDown = renderOddDown(classIndicator, timeStamp)
	const elementOddUpSelected = renderOddUpSelected(classIndicator, timeStamp)
	const elementOddDownSelected = renderOddDownSelected(
		classIndicator,
		timeStamp,
	)
	oddRef.current.querySelectorAll(`.${classIndicator}`).forEach((element) => {
		element.remove()
	})
	update.forEach((leagueByDate) => {
		const { leagues = [], leagueTime } = leagueByDate
		if (leagues[0]) {
			const league = leagues[0]
			const { events } = league
			events.forEach((event) => {
				const homeTeamType = get(event, 'homeTeamType', 0)
				const awayTeamType = get(event, 'awayTeamType', 1)
				const { periods } = event
				Object.entries(periods).forEach((periodEntry) => {
					const [periodToDisplay, period] = periodEntry
					const { teamTotals = {} } = period
					const { away, home } = teamTotals
					if (away && home) {
						const isAltAway = away.isAlt ? 1 : 0
						const isAltHome = home.isAlt ? 1 : 0
						const idHomeOver = `${
							event.id
						}\\|${periodToDisplay}\\|4\\|5\\|${isAltHome}\\|${(
							home.points || ''
						)
							.replace('.', '\\.')
							.replace('+', '\\+')}\\|${homeTeamType}`
						const idHomeUnder = `${
							event.id
						}\\|${periodToDisplay}\\|4\\|6\\|${isAltHome}\\|${(
							home.points || ''
						)
							.replace('.', '\\.')
							.replace('+', '\\+')}\\|${homeTeamType}`
						const idAwayOver = `${
							event.id
						}\\|${periodToDisplay}\\|5\\|7\\|${isAltAway}\\|${(
							away.points || ''
						)
							.replace('.', '\\.')
							.replace('+', '\\+')}\\|${awayTeamType}`
						const idAwayUnder = `${
							event.id
						}\\|${periodToDisplay}\\|5\\|8\\|${isAltAway}\\|${(
							away.points || ''
						)
							.replace('.', '\\.')
							.replace('+', '\\+')}\\|${awayTeamType}`
						const homeOverElement = oddRef.current.querySelector(
							`#t${idHomeOver}`,
						)
						const homeUnderElement = oddRef.current.querySelector(
							`#t${idHomeUnder}`,
						)
						const awayOverElement = oddRef.current.querySelector(
							`#t${idAwayOver}`,
						)
						const awayUnderElement = oddRef.current.querySelector(
							`#t${idAwayUnder}`,
						)
						if (homeOverElement && homeUnderElement) {
							const isHomeOverSelected =
								homeOverElement.classList.contains('styleSelected')
							const isHomeUnderSelected =
								homeUnderElement.classList.contains('styleSelected')
							homeOverElement.setAttribute('lineId', home.lineId)
							homeUnderElement.setAttribute('lineId', home.lineId)
							homeOverElement.setAttribute('title', home.points)
							homeUnderElement.setAttribute('title', home.points)
							homeOverElement.innerHTML = `<span class="styleLabelTotal">${home.points}</span
							><span class="stylePrice">${home.overOdds}</span>`
							homeUnderElement.innerHTML = `<span class="styleLabelTotal">${home.points}</span
							><span class="stylePrice">${home.underOdds}</span>`
							if (home.overOdds) {
								homeOverElement.setAttribute(
									'onclick',
									`${eventIdOnClickName}(event,'${leagueTime}', '${league.id}','${event.id}','${periodToDisplay}', '4','5','${home.overOdds}','${home.points}','${home.isAlt},'${homeTeamType}')`,
								)
							}
							if (home.underOdds) {
								homeUnderElement.setAttribute(
									'onclick',
									`${eventIdOnClickName}(event,'${leagueTime}', '${league.id}','${event.id}','${periodToDisplay}', '4','6','${home.underOdds}','${home.points}','${home.isAlt},'${homeTeamType}')`,
								)
							}
							if (home.overSignal === 1) {
								homeOverElement.insertAdjacentHTML(
									'afterbegin',
									isHomeOverSelected ? elementOddUpSelected : elementOddUps,
								)
							}
							if (home.overSignal === -1) {
								homeOverElement.insertAdjacentHTML(
									'afterbegin',
									isHomeOverSelected ? elementOddDownSelected : elementOddDown,
								)
							}
							if (home.underSignal === 1) {
								homeUnderElement.insertAdjacentHTML(
									'afterbegin',
									isHomeUnderSelected ? elementOddUpSelected : elementOddUps,
								)
							}
							if (home.underSignal === -1) {
								homeUnderElement.insertAdjacentHTML(
									'afterbegin',
									isHomeUnderSelected ? elementOddDownSelected : elementOddDown,
								)
							}
						}
						if (awayOverElement && awayUnderElement) {
							const isAwayOverSelected =
								homeOverElement.classList.contains('styleSelected')
							const isAwayUnderSelected =
								homeUnderElement.classList.contains('styleSelected')
							awayOverElement.setAttribute('lineId', away.lineId)
							awayUnderElement.setAttribute('lineId', away.lineId)
							awayOverElement.setAttribute('title', away.points)
							awayUnderElement.setAttribute('title', away.points)
							awayOverElement.innerHTML = `<span class="styleLabelTotal">${away.points}</span
							><span class="stylePrice">${away.overOdds}</span>`
							awayUnderElement.innerHTML = `<span class="styleLabelTotal">${away.points}</span
							><span class="stylePrice">${away.underOdds}</span>`
							if (away.overOdds) {
								awayOverElement.setAttribute(
									'onclick',
									`${eventIdOnClickName}(event,'${leagueTime}', '${league.id}','${event.id}','${periodToDisplay}', '5','7','${away.overOdds}','${away.points}','${away.isAlt},'${awayTeamType}')`,
								)
							}
							if (away.underOdds) {
								homeUnderElement.setAttribute(
									'onclick',
									`${eventIdOnClickName}(event,'${leagueTime}', '${league.id}','${event.id}','${periodToDisplay}', '5','8','${away.underOdds}','${away.points}','${away.isAlt},'${awayTeamType}')`,
								)
							}
							if (away.overSignal === 1) {
								awayOverElement.insertAdjacentHTML(
									'afterbegin',
									isAwayOverSelected ? elementOddUpSelected : elementOddUps,
								)
							}
							if (away.overSignal === -1) {
								awayOverElement.insertAdjacentHTML(
									'afterbegin',
									isAwayOverSelected ? elementOddDownSelected : elementOddDown,
								)
							}
							if (away.underSignal === 1) {
								awayUnderElement.insertAdjacentHTML(
									'afterbegin',
									isAwayUnderSelected ? elementOddUpSelected : elementOddUps,
								)
							}
							if (away.underSignal === -1) {
								awayUnderElement.insertAdjacentHTML(
									'afterbegin',
									isAwayUnderSelected ? elementOddDownSelected : elementOddDown,
								)
							}
						}
					}
				})
			})
		}
	})
}

export const updateAlternateLineIndicator = (oddRef, dataAlternate) => {
	if (!dataAlternate) return
	let { update = [] } = dataAlternate
	if (!oddRef?.current) return
	const timeStamp = Date.now()
	const classIndicator = 'indicator'
	if (oddRef?.current) {
		oddRef.current.querySelectorAll(`.${classIndicator}`).forEach((element) => {
			element.remove()
		})
	}

	const elementOddUps = renderOddUp(classIndicator, timeStamp)
	const elementOddDown = renderOddDown(classIndicator, timeStamp)
	const elementOddUpSelected = renderOddUpSelected(classIndicator, timeStamp)
	const elementOddDownSelected = renderOddDownSelected(
		classIndicator,
		timeStamp,
	)
	if (update.length < 1) return
	const { events = [] } = update[0]
	events.forEach((event) => {
		const homeTeamType = get(event, 'homeTeamType', 0)
		const awayTeamType = get(event, 'awayTeamType', 1)
		const periods = get(event, ['periods'], {})
		Object.entries(periods).forEach((periodEntry) => {
			const [periodToDisplay, period] = periodEntry
			const handicap = get(period, 'handicap', [])
			const overUnder = get(period, 'overUnder', [])

			handicap.forEach((hd, indexHandicap) => {
				const isAltLine = hd.isAlt ? 1 : 0
				const idHomeToSearch = `${
					event.id
				}\\|${periodToDisplay}\\|2\\|0\\|${isAltLine}\\|${(hd.homeSpread || '')
					.replace('.', '\\.')
					.replace('+', '\\+')}\\|${homeTeamType}`
				const idAwayToSearch = `${
					event.id
				}\\|${periodToDisplay}\\|2\\|1\\|${isAltLine}\\|${(hd.awaySpread || '')
					.replace('.', '\\.')
					.replace('+', '\\+')}\\|${awayTeamType}`
				const homeElement = oddRef.current.querySelector(`#t${idHomeToSearch}`)
				const awayElement = oddRef.current.querySelector(`#t${idAwayToSearch}`)

				if (homeElement && awayElement) {
					homeElement.setAttribute('lineId', hd.lineId)
					awayElement.setAttribute('lineId', hd.lineId)
					if (hd.homeSpread && hd.homeOdds) {
						homeElement.setAttribute('title', hd.homeSpread)
						homeElement.innerHTML = ` <span class="name">${hd.homeSpread}</span>
						  <span class="odd">${hd.homeOdds}</span>`
					}
					if (hd.awaySpread && hd.awayOdds) {
						awayElement.setAttribute('title', hd.awaySpread)
						awayElement.innerHTML = ` <span class="name">${hd.awaySpread}</span>
						 <span class="odd">${hd.awayOdds}</span>`
					}
					const isHomeSelected = homeElement.classList.contains('styleSelected')
					const isAwaySelected = awayElement.classList.contains('styleSelected')
					if (hd.homeSignal === 1) {
						homeElement.insertAdjacentHTML(
							'afterbegin',
							isHomeSelected ? elementOddUpSelected : elementOddUps,
						)
					}
					if (hd.homeSignal === -1) {
						homeElement.insertAdjacentHTML(
							'afterbegin',
							isHomeSelected ? elementOddDownSelected : elementOddDown,
						)
					}

					if (hd.awaySignal === 1) {
						awayElement.insertAdjacentHTML(
							'afterbegin',
							isAwaySelected ? elementOddUpSelected : elementOddUps,
						)
					}
					if (hd.awaySignal === -1) {
						awayElement.insertAdjacentHTML(
							'afterbegin',
							isAwaySelected ? elementOddDownSelected : elementOddDown,
						)
					}
				}
			})

			overUnder.forEach((ou, indexOverUnder) => {
				const isAltLine = ou.isAlt ? 1 : 0
				const idHomeToSearch = `${
					event.id
				}\\|${periodToDisplay}\\|3\\|3\\|${isAltLine}\\|${(ou.points || '')
					.replace('.', '\\.')
					.replace('+', '\\+')}\\|${homeTeamType}`
				const idAwayToSearch = `${
					event.id
				}\\|${periodToDisplay}\\|3\\|4\\|${isAltLine}\\|${(ou.points || '')
					.replace('.', '\\.')
					.replace('+', '\\+')}\\|${awayTeamType}`
				const homeElement = oddRef.current.querySelector(`#t${idHomeToSearch}`)
				const awayElement = oddRef.current.querySelector(`#t${idAwayToSearch}`)
				if (homeElement && awayElement) {
					homeElement.setAttribute('lineId', ou.lineId)
					awayElement.setAttribute('lineId', ou.lineId)
					if (ou.overOdds) {
						homeElement.innerHTML = ` <span class="name">${ou.points}</span>
						  <span class="odd">${ou.overOdds}</span>`
					}
					if (ou.underOdds) {
						awayElement.innerHTML = ` <span class="name">${ou.points}</span>
						 <span class="odd">${ou.underOdds}</span>`
					}

					const isHomeSelected = homeElement.classList.contains('styleSelected')
					const isAwaySelected = awayElement.classList.contains('styleSelected')

					if (ou.overSignal === 1) {
						homeElement.insertAdjacentHTML(
							'afterbegin',
							isHomeSelected ? elementOddUpSelected : elementOddUps,
						)
					}
					if (ou.overSignal === -1) {
						homeElement.insertAdjacentHTML(
							'afterbegin',
							isHomeSelected ? elementOddDownSelected : elementOddDown,
						)
					}

					if (ou.underSignal === 1) {
						awayElement.insertAdjacentHTML(
							'afterbegin',
							isAwaySelected ? elementOddUpSelected : elementOddUps,
						)
					}
					if (ou.underSignal === -1) {
						awayElement.insertAdjacentHTML(
							'afterbegin',
							isAwaySelected ? elementOddDownSelected : elementOddDown,
						)
					}
				}
			})
		})
	})
}

export const checkQuickBetExist = (oddData, quickBet) => {
	let hasQuickBet = false
	if (!oddData || oddData?.length === 0 || !quickBet.selection) return false
	const { leagues = [] } = oddData
	const { selection = {} } = quickBet
	const {
		betType: quickBetBetType,
		selectionType: quickBetSelectionType,
		handicap: spread,
	} = selection
	const arrayQuickBet = selection.oddsId.split('|')
	const quickBetPeriod = arrayQuickBet[1]

	const league = leagues.find((le) => le.id === selection.leagueId)
	if (!league) return false
	const { events = [] } = league
	const event = events.find((ev) => ev.id === selection.eventId)
	if (!event) return false
	const { periods = {} } = event
	const period = periods[quickBetPeriod]
	if (!period) return false
	const { moneyLine, overUnder, handicap } = period
	switch (quickBetBetType) {
		case `${BetType.HANDICAP}`:
			hasQuickBet = handicap.some((hd) => {
				if (hd.offline || hd.unavailable) return false
				if (quickBetSelectionType === '0') {
					return hd.homeSpread === spread
				} else {
					return hd.awaySpread === spread
				}
			})
			break
		case `${BetType.OU}`:
			hasQuickBet = overUnder.some((ou) => {
				if (ou.offline || ou.unavailable) return false
				return ou.points === spread
			})
			break
		default:
			if (moneyLine.homePrice && !moneyLine.unavailable && !moneyLine.offline) {
				hasQuickBet = true
			}
			break
	}

	return hasQuickBet
}

export const deleteEventElement = (oddRef, eventId) => {
	if (!oddRef?.current || !eventId) return
	const prefix = 'eventElement'
	const eventElement = oddRef.current.querySelector(`#${prefix}${eventId}`)
	if (eventElement) {
		eventElement.remove()
	}
}

export const removeEventFromData = (oddData, eventIdDelete, oddRef) => {
	if (!oddData || !eventIdDelete) return
	const tempDataCopy = cloneDeep(oddData)
	const { leagues = [] } = tempDataCopy

	leagues.some((league, indexLeague) => {
		const { events = [] } = league
		const findEvent = events.find((ev) => ev.id === eventIdDelete)
		if (findEvent) {
			const eventsAfterDelete = events.filter((ev) => ev.id !== eventIdDelete)
			if (eventsAfterDelete.length === 0 && oddRef.current) {
				const { id } = league
				const prefix = 'leagueElement'
				const leagueElement = oddRef.current.querySelector(`#${prefix}${id}`)
				if (leagueElement) {
					leagueElement.remove()
				}
			}
			set(tempDataCopy, ['leagues', indexLeague, 'events'], eventsAfterDelete)
			return true
		}
		return false
	})
	return tempDataCopy
}

export const getPeriodName = (sportIndex, periodList, periodIndex = 0) => {
	const intSportIndex = parseInt(sportIndex, 10)
	const findPeriod = (periodList || []).find((list) => {
		return list[0] === intSportIndex
	})
	const sportPeriod = (findPeriod[1] || []).find((period) => {
		return period[0] === periodIndex
	})
	return get(sportPeriod, ['1'], '(Match)') || '(Match)'
}

export const getPeriodNameList = (sportIndex, periodList = {}) => {
	const periodObject = {}
	Object.entries(periodList).forEach((periodEntry) => {
		const [periodToDisplay, period] = periodEntry
		const periodArray = periodToDisplay.split('.')
		if (`${sportIndex}` === `${periodArray[0]}` && periodArray.length === 2) {
			periodObject[periodArray[1]] = `(${period})`
		}
	})
	return periodObject
}

export const formatOddType = (oddTypeName) => {
	const oddTypeList = {
		AM: 0,
		EU: 1,
		HK: 2,
		ID: 3,
		MY: 4,
	}
	return oddTypeList[oddTypeName] || 0
}

export const notShowAllPeriodSports = [
	'19',
	'15',
	'4',
	'34',
	'27',
	'8',
	'28',
	'33',
	'1',
	'10',
	'17',
	'18',
	'45',
	'22',
	'6',
]

export const EVENT_TYPE = {
	NORMAL: 0,
	CORNER: 1,
	BOOKING: 2,
}

export const BTG = {
	TEAM_TOTAL: 100,
	SPECIAL: 102,
}

export const generateTranslationId = (value = '', prefix = 'text') => {
	return `${prefix}.${value.toLowerCase().replaceAll(' ', '_')}`
}

export const getScoreLiveEvent = ({
	sportId,
	periodToDisplay,
	participants,
}) => {
	const result = {
		participants_0_score: null,
		participants_1_score: null,
		participants_0_redCards: null,
		participants_1_redCards: null,
	}
	if (toNumber(sportId) === SPORT_NAME.soccer) {
		if (periodToDisplay === 0) {
			result.participants_0_score = participants[0]?.score?.match
			result.participants_1_score = participants[1]?.score?.match
			result.participants_0_redCards = participants[0]?.redCards?.match
			result.participants_1_redCards = participants[1]?.redCards?.match
			return result
		}
		if (periodToDisplay === 3) {
			result.participants_0_score = participants[0]?.score?.extraTime
			result.participants_1_score = participants[1]?.score?.extraTime
			result.participants_0_redCards =
				typeof participants[0]?.redCards === 'object'
					? Object.values(participants[0]?.redCards).reduce((a, b) => a + b, 0)
					: participants[0]?.redCards
			result.participants_1_redCards =
				typeof participants[1]?.redCards === 'object'
					? Object.values(participants[1]?.redCards).reduce((a, b) => a + b, 0)
					: participants[1]?.redCards
			return result
		}
	}
	return result
}

window.generateTranslationId = generateTranslationId
window.getScoreLiveEvent = getScoreLiveEvent

export const renderGameIcon = () => {
	return `
		<% if(league.gameCode && env?.isB2B) { %>
			<i class="icon icon-game-default icon-<%= league.gameCode %>"></i>
    <% } %>
  `
}

export const showTabAllPeriodSports = ['29', '12', '15', '4']

export const getOffset = (el) => {
	const rect = el.getBoundingClientRect()
	return {
		left: rect.left + window.scrollX,
		top: rect.top + window.scrollY,
		height: rect.height,
		width: rect.width,
	}
}

export const parseSelectionIdWithoutAltLineId = (selectionId) => {
	const parts = selectionId.split('|')
	return {
		lineId: parts[0],
		eventId: parts[1],
		period: parts[2],
		betType: parts[3],
		selectionType: parts[4],
		isAlt: parts[5],
		handicap: parts[6],
		teamType: parts[7],
	}
}

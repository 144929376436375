import Odds from './Odds'
import { get } from 'lodash'
import { BET_TYPE_ONE_COLUMN } from 'constants/common'

class Futures {
	constructor(data, oddsFormat, cached, type, leaguedId) {
		this.futures = new Map()
		this.oddsFormat = oddsFormat
		this.cached = cached
		if (type === 'euro') {
			this.transformDataTypeEuro(data, leaguedId)
		} else {
			this.transformData(data)
		}
	}

	transformData(data = []) {
		for (let i = 0; i < data.length; i++) {
			const oldDataLeague = this.cached.find(
				(league) => league?.leagueId === data[i].li,
			)
			const oldSpecialEvent = oldDataLeague?.specialEvent ?? {}
			if (this.futures.has(data[i].li)) {
				const currentEvent = structuredClone(this.futures.get(data[i].li))
				const specialEvent = this.addSpecialEvent(data[i].se, oldSpecialEvent)
				currentEvent.specialEvent = {
					...currentEvent.specialEvent,
					...specialEvent,
				}
				this.futures.set(data[i].li, currentEvent)
				continue
			}

			const info = {
				leagueId: data[i].li,
				leagueName: data[i].ln,
				date: data[i].lt,
				specialEvent: this.addSpecialEvent(data[i].se, oldSpecialEvent),
			}
			this.futures.set(data[i].li, info)
		}
	}

	addSpecialEvent(events = [], oldSpecialEvent) {
		const specialEvents = {}
		events.forEach((event) => {
			const specialEvent = {
				id: event.si,
				eventName: event.n,
				status: event.st,
				betType: event.bt || '',
			}
			if (event.l.length > 0) {
				specialEvent.participants = event.l.map((participant) => {
					const price = new Odds(participant.p, this.oddsFormat)
					let oddsChangeStatus = ''
					const oldParticipants = get(oldSpecialEvent, [
						event.si,
						'participants',
					])
					if (oldParticipants) {
						const oldPrice = oldParticipants.find((p) => p.id === participant.i)
						if (oldPrice) {
							if (Number(oldPrice.price) > Number(price.formatInteger())) {
								oddsChangeStatus = 'DOWN'
							} else if (
								Number(oldPrice.price) < Number(price.formatInteger())
							) {
								oddsChangeStatus = 'UP'
							}
						}
					}
					const key = `${event.si}|0|99|10|0|${participant.h ?? 0}|${
						participant.i
					}`
					return {
						key: key,
						oddsId: participant.l,
						id: participant.i,
						name: participant.n,
						status: oddsChangeStatus,
						handicap: participant.h,
						price: price.formatInteger(),
					}
				})
			}
			specialEvents[event.si] = specialEvent
		})
		return specialEvents
	}

	transformDataTypeEuro(data = [], leaguedId) {
		for (let i = 0; i < data.length; i++) {
			const oldDataLeague = this.cached.find(
				(league) => league?.leagueId === leaguedId,
			)
			const contestantSort =
				data[i].bt === BET_TYPE_ONE_COLUMN
					? data[i].contestants.sort((contestantA, contestantB) => {
							return parseFloat(contestantA.p) - parseFloat(contestantB.p)
					  })
					: data[i].contestants
			const oldSpecialEvent = oldDataLeague?.specialEvent ?? {}
			if (this.futures.has(leaguedId)) {
				const currentEvent = structuredClone(this.futures.get(leaguedId))
				const specialEvent = this.addSpecialEvent(
					contestantSort,
					oldSpecialEvent,
				)
				currentEvent.specialEvent = {
					...currentEvent.specialEvent,
					...specialEvent,
				}
				this.futures.set(leaguedId, currentEvent)
				continue
			}

			const info = {
				leagueId: leaguedId,
				leagueName: data[i].name,
				overrideLeagueName: data[i].leagueName,
				date: data[i].time,
				specialEvent: this.addSpecialEventTypeEuro(
					contestantSort,
					data[i].id,
					data[i].name,
					data[i].bt,
					data[i].un,
					oldSpecialEvent,
				),
			}

			this.futures.set(data[i].id, info)
		}
	}

	addSpecialEventTypeEuro(
		events = [],
		leaguedId,
		eventName,
		betType,
		unit,
		oldSpecialEvent,
	) {
		const participants = events.map((participant) => {
			const price = new Odds(participant.p, this.oddsFormat)
			let oddsChangeStatus = ''
			const oldParticipants = get(oldSpecialEvent, [leaguedId, 'participants'])
			if (oldParticipants) {
				const oldPrice = oldParticipants.find((p) => p.id === participant.i)
				if (oldPrice) {
					if (Number(oldPrice.price) > Number(price.formatInteger())) {
						oddsChangeStatus = 'DOWN'
					} else if (Number(oldPrice.price) < Number(price.formatInteger())) {
						oddsChangeStatus = 'UP'
					}
				}
			}
			const key = `${leaguedId}|0|99|10|0|${participant.h ?? 0}|${
				participant.i
			}`
			return {
				key: key,
				oddsId: participant.l,
				id: participant.i,
				name: participant.n,
				handicap: participant.h,
				status: oddsChangeStatus,
				price: price.formatInteger(),
			}
		})
		return {
			[leaguedId]: {
				id: leaguedId,
				eventName: eventName,
				participants: participants,
				betType,
				unit,
			},
		}
	}
}

export default Futures

import { createApi } from '@reduxjs/toolkit/query/react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import axiosBaseQuery from './rtkApi'
import { BET_PLACEMENT_SERVICES } from 'constants/servicesApi'
import {
	BET_STATUS,
	ODDS_FORMAT,
	BET_LOCATION_TRACKING,
} from 'constants/common'
import {
	BET_SLIP_TYPE,
	PLACED_BET_ERROR,
	PLACED_BET_WARNING,
	ROUND_ROBIN,
	combineMultiples,
	generateUUID,
	getBetSingles,
	getBetPending,
	handleDenyBet,
} from 'apps/components/Sportsbook/Betslip/utils'
import { getSelections } from './selector/sportsbookSelector'
import {
	setProcessingBets,
	setSelectionsBetSlip,
	updateAcceptQuickBet,
	updateBetsSuccess,
	updatePendingBets,
	updatePendingMultipleBets,
} from 'stores/slices/Betslip'
import { MULTIPLES } from '../../apps/components/Sportsbook/Betslip/Messages/messages'
import Session from 'apps/types/Session'
import localeInstance from 'apps/types/Locale'

const { BASE, BET_SINGLES, BET_PARLAY } = BET_PLACEMENT_SERVICES
export const BET_PLACEMENT_PATH = 'betPlacement'
export const betPlacementServices = createApi({
	reducerPath: BET_PLACEMENT_PATH,
	baseQuery: axiosBaseQuery(BASE),
	endpoints: () => ({}),
})

const betPlacementServicesQuery = betPlacementServices.injectEndpoints({
	endpoints: (builder) => ({
		betSingles: builder.mutation({
			async queryFn(
				_arg,
				{ dispatch, getState },
				_extraOptions,
				betPlacementServicesBQ,
			) {
				try {
					const args = structuredClone(_arg)
					delete args.isQuickBet
					const selections = _arg.isQuickBet
						? [getState().Betslip.quickBet.selection]
						: getState().Betslip.selections
					args.clientVersion = process.env.REACT_APP_VERSION || ''
					const singles = await betPlacementServicesBQ({
						endpoint: BET_SINGLES,
						params: {
							uniqueRequestId: generateUUID(),
						},
						body: args,
						method: 'post',
					})
					const responseFulfilled = singles.data?.response
					const betSuccess = {
						type: BET_SLIP_TYPE.SINGLES,
						myBets: [],
					}
					const pendingBets = [...getState().Betslip.pendingBets]
					const selectionQuickBet = getState().Betslip.quickBet?.selection
					const result = responseFulfilled.reduce(
						(betAccepted, bet) => {
							switch (bet.status) {
								case BET_STATUS.ACCEPTED:
									betSuccess.myBets.push(getBetSingles(bet, selections))
									return betAccepted
								case BET_STATUS.PENDING_ACCEPTANCE: {
									const pendingAcceptance = getBetPending(bet, selections)
									if (_arg.isQuickBet) {
										if (
											isNil(selectionQuickBet) ||
											selectionQuickBet?.oddsId !== bet.oddsId
										) {
											dispatch(
												setProcessingBets([
													...getState().Betslip.processingBets,
													pendingAcceptance,
												]),
											)
										} else {
											betAccepted.processingBet.push(pendingAcceptance)
										}
									} else {
										pendingBets.push(pendingAcceptance)
									}
									return betAccepted
								}
								default:
									betAccepted.denyBet.push(handleDenyBet(bet, _arg))
									return betAccepted
							}
						},
						{
							acceptedBet: [],
							denyBet: [],
							processingBet: [],
						},
					)
					const selectionRemain = selections.filter(
						(selection) =>
							![
								...betSuccess.myBets,
								...result.processingBet,
								...pendingBets,
							].some(
								(acceptedBet) => acceptedBet.bets.oddsId === selection.oddsId,
							),
					)
					result.previousSelection = selections.map(
						(selection) => selection.oddsId,
					)
					if (responseFulfilled.length > 0) {
						if (
							_arg.isQuickBet &&
							(isNil(selectionQuickBet) ||
								betSuccess.myBets.length === 0 ||
								selectionQuickBet?.oddsId ===
									betSuccess.myBets[0]?.bets?.oddsId) &&
							selectionRemain.length === 0
						) {
							result.wagerIdQuickBet = betSuccess.myBets[0]?.bets?.wagerId
							dispatch(
								updateAcceptQuickBet(
									getState().Betslip.quickBet.selection?.oddsId,
								),
							)
						}
						if (!_arg.isQuickBet) {
							dispatch(setSelectionsBetSlip(selectionRemain))
						}
						if (pendingBets.length > 0) {
							dispatch(updatePendingBets(pendingBets))
						}
						dispatch(updateBetsSuccess(betSuccess))
					}
					if (PLACED_BET_WARNING.includes(singles.data?.errorCode)) {
						result.warningCode = singles.data.errorCode
					}
					if (PLACED_BET_ERROR.includes(singles.data?.errorCode)) {
						result.errorCode = singles.data.errorCode
					}
					return { data: result }
				} catch (error) {
					return {
						isError: true,
						data: error.data,
						error: error,
					}
				}
			},
		}),
		betParlay: builder.mutation({
			async queryFn(
				{ legs, options, oneStake, displayMode },
				{ dispatch, getState },
				_extraOptions,
				betPlacementServicesBQ,
			) {
				try {
					const { selections, parlaySelections } = getSelections(getState())
					const oddsFormat = ODDS_FORMAT[getState().Preferences.odds]
					const latestSelection = getState().Betslip?.selections[0]
					const preferences = Session.getPreferences()
					const roundRobinOption = oneStake
						? options[0].roundRobinOptions
						: options.map((arg) => arg.roundRobinOptions.join(''))
					const [...multiples] = await Promise.allSettled(
						options.map((option) => {
							const { ticketId, roundRobinOptions, stake } = option
							const body = {
								acceptBetterOdds: get(
									Session.getPreferences(),
									'acceptBetterOdds',
									false,
								),
								clientVersion: process.env.REACT_APP_VERSION || '',
								winRiskStake: 'RISK',
								oddsFormat,
								roundRobinOptions,
								stake,
								ticketId,
								uniqueRequestId: generateUUID(),
							}
							if (window.env.enableBetLocationTracking) {
								body.betLocationTracking = {
									...latestSelection.betLocationTracking,
									language: localeInstance.getLanguage(),
									timeZone: get(
										Session.getPreferences(),
										'timeZoneId',
										'UNKNOWN',
									),
									device: getState().Layout.view,
									displayMode,
									marketType: get(
										preferences,
										'marketType',
										BET_LOCATION_TRACKING.UNKNOWN,
									),
									eventSorting: get(
										preferences,
										'eventSorting',
										BET_LOCATION_TRACKING.UNKNOWN,
									),
									pageType: get(
										preferences,
										'pageType',
										BET_LOCATION_TRACKING.UNKNOWN,
									),
									defaultPage: get(
										preferences,
										'defaultPage',
										BET_LOCATION_TRACKING.UNKNOWN,
									),
								}
							}
							return betPlacementServicesBQ({
								endpoint: BET_PARLAY,
								method: 'post',
								params: {
									uniqueRequestId: generateUUID(),
								},
								body: body,
							})
						}),
					)
					const error = multiples[0].value.data?.errorCode
					if (error) {
						return {
							data: {
								bets: {},
								betSelections: parlaySelections,
								warningCode: PLACED_BET_WARNING.includes(error) ? error : null,
								errorCode: PLACED_BET_ERROR.includes(error) ? error : null,
							},
							isError: true,
						}
					}

					const responseFulfilled = multiples.map(
						(response) => response.value?.data?.response,
					)
					let resultFulfilled = responseFulfilled
					if (oneStake) {
						resultFulfilled = roundRobinOption.reduce((result, rr) => {
							const filterLegs = responseFulfilled[0].filter((item) => {
								if (item.status !== BET_STATUS.ACCEPTED) {
									return true
								}
								return rr === 'Parlay'
									? item.parlayBet.legs.length === legs.length
									: item.parlayBet.legs.length === ROUND_ROBIN[rr]
							})
							result.push(filterLegs)
							return result
						}, [])
					}
					const pendingBets = [...getState().Betslip.pendingMultipleBets]
					const result = resultFulfilled.reduce(
						(betAccepted, bets, index) => {
							let totalStake = 0
							let totalWin = 0
							const commonBet = bets[0]
							const roundRobin = commonBet.roundRobinOptionWithOdds.find(
								(option) => option.roundRobinOption === roundRobinOption[index],
							)
							if (commonBet.status === BET_STATUS.PENDING_ACCEPTANCE) {
								pendingBets.push({
									option: roundRobinOption[index],
									wagerId: commonBet.betId,
									errorCode: commonBet.errorCode,
									status: commonBet.status,
									legsNumber: commonBet.validLegs.length,
									betSelections: parlaySelections,
								})
								return betAccepted
							}
							if (
								[
									MULTIPLES.INVALID_LEGS,
									MULTIPLES.ROUND_ROBIN_DISALLOWED,
								].includes(commonBet.errorCode)
							) {
								betAccepted.denyBet.push({
									option: roundRobinOption[index],
									wagerId: commonBet.betId,
									errorCode: get(
										commonBet,
										['invalidLegs', '0', 'errorCode'],
										commonBet.errorCode,
									),
									status: commonBet.status,
									legsNumber: commonBet?.invalidLegs?.length ?? 0,
								})
								return betAccepted
							}

							for (let i = 0; i < bets.length; i++) {
								totalStake += bets[i].parlayBet.risk
								totalWin += bets[i].parlayBet.win
							}

							betAccepted.acceptedBet.push({
								option: roundRobinOption[index],
								odds: roundRobin.odds || 0,
								wagerId: commonBet.betId,
								errorCode: commonBet.errorCode,
								status: commonBet.status,
								oddsFormat: ODDS_FORMAT[commonBet.parlayBet.oddsFormat],
								totalStake: totalStake,
								totalWin: totalWin,
								legsNumber: commonBet.validLegs.length,
							})
							return betAccepted
						},
						{
							acceptedBet: [],
							denyBet: [],
						},
					)

					if (result.denyBet.length === 0) {
						const selectionRemain = selections.filter(
							(selection) =>
								!parlaySelections.some(
									(parlay) => parlay.oddsId === selection.oddsId,
								),
						)
						dispatch(setSelectionsBetSlip(selectionRemain))
					}
					if (result.acceptedBet.length > 0) {
						dispatch(
							updateBetsSuccess({
								type: BET_SLIP_TYPE.MULTIPLES,
								myBets: {
									bets: result.acceptedBet,
									betSelections: combineMultiples(parlaySelections, legs),
								},
							}),
						)
					}
					if (pendingBets.length > 0) {
						dispatch(updatePendingMultipleBets(pendingBets))
					}
					return {
						data: {
							bets: result,
							betSelections: combineMultiples(parlaySelections, legs),
						},
					}
				} catch (error) {
					return {
						isError: true,
						data: error.data,
						error: error,
					}
				}
			},
		}),
	}),
})

export const { useBetSinglesMutation, useBetParlayMutation } =
	betPlacementServicesQuery

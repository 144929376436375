/** Global */
import { createApi } from '@reduxjs/toolkit/query/react'
import isEmpty from 'lodash/isEmpty'
/** Local */
import axiosBaseQuery from './rtkApi'
import { MEMBER_SERVICES } from 'constants/servicesApi'
import {
	setUser,
	resetSession,
	setTermAndConditions,
	setEnabledPersonalMessage,
} from 'stores/slices/User'
import Session from 'apps/types/Session'
import { sportServices } from './sportServicesRTK'
import Periods from 'apps/types/Periods'
import {
	ODDS_FORMAT,
	ODDS_SELECTION_STORAGE_KEY,
	STATUS_LOGIN_SUCCESS,
	VIEWS,
	WAGER_MYBET_MAP,
	STATUS_WAGER,
	LOCAL_STORAGE,
} from 'constants/common'
import { setSelectedOdds } from 'stores/slices/OddPage'
import {
	buildRouteUrl,
	getCurrentRouterProvider,
	isEsportsHubPath,
} from 'services/Url'
import {
	resetBetsSuccess,
	setSelectionsBetSlip,
	updateMultiplesTicketId,
} from 'stores/slices/Betslip'
import {
	BET_SLIP_DISPLAY,
	attachMoreFromState,
	attachMoreParlayFromState,
	generateUUID,
	getParamsSingleSelection,
} from 'apps/components/Sportsbook/Betslip/utils'
import {
	getSelectionQuickBet,
	getSelections,
} from './selector/sportsbookSelector'
import { ERROR_MESSAGES } from 'apps/components/Sportsbook/Betslip/Messages/messages'
import Api from 'apps/types/Api'
import {
	addFavourite,
	orderFavourite,
	removeFavourite,
	undoFavourite,
} from '../slices/FavouriteSports'
import {
	FAVOURITES_TYPE,
	UPDATE_FAVOURITES_ACTION,
} from '../../constants/leftMenu'
import { createParamsGetFavouriteTotalEvents } from '../../services/LeftMenu'
import { initTheme } from 'services/Styles'
import { processDataNoCacheV1, processDataNoCacheV2 } from '../../services/Api'
import {
	getDynamicParameter,
	setDynamicParameter,
	setHeaderTokens,
	setHeaderXAppData,
	updateHeaderTokens,
	updateHeaderXAppData,
} from '../../services/LocalStorage'
import { getB2BParentDomain } from 'services/Common'

export const MEMBER_PATH = 'memberServices'

const {
	BASE,
	ACCEPT_TERM_CONDITION,
	ACCOUNT_BALANCE,
	ALL_ODDS_SELECTIONS,
	AUTHENTICATE,
	AUTH_TOKEN,
	DATA_NOCACHE,
	DATA_NOCACHE_V2,
	LAST_MESSAGE,
	LOAD_ESPORTS_HUB_TEMPLATE,
	LOAD_TEMPLATE,
	LOGIN,
	LOGOUT,
	MAINTENANCE_BY_LANG,
	MY_BETS,
	PARLAY_ODDS_SELECTIONS,
	SEARCH_WAGER,
	UPDATE_DEFAULT_VIEW,
	UPDATE_LOCALE,
	UPDATE_MODE,
	UPDATE_REASON_FAIL_DP,
} = MEMBER_SERVICES

export const memberServices = createApi({
	reducerPath: MEMBER_PATH,
	baseQuery: axiosBaseQuery(BASE),
	endpoints: () => ({}),
})

const memberServicesQuery = memberServices.injectEndpoints({
	endpoints: (builder) => ({
		getTemplate: builder.query({
			async queryFn(_arg, _queryApi, _extraOptions, memberServicesBQ) {
				try {
					const template = _arg
						? await Api.get(
								`${process.env.PUBLIC_URL}/standardst/template/${_arg}.json`,
						  )
						: await memberServicesBQ({
								endpoint: isEsportsHubPath()
									? LOAD_ESPORTS_HUB_TEMPLATE
									: LOAD_TEMPLATE,
						  })
					const {
						template: templateSkin,
						initDataForComps,
						globalAttribute,
					} = template.data
					const parentDomainHeaderRequest = getB2BParentDomain(
						globalAttribute.isB2B,
					)
						? { 'X-Parent-Domain': getB2BParentDomain(globalAttribute.isB2B) }
						: {}
					let _dataNoCache
					window.env = globalAttribute
					if (globalAttribute?.headerBasedEnabled) {
						const dataNoCache = await memberServicesBQ({
							endpoint: DATA_NOCACHE_V2,
						})
						_dataNoCache = processDataNoCacheV2({
							dataNoCache,
						})
					} else {
						const dataNoCache = await memberServicesBQ({
							endpoint: DATA_NOCACHE,
							headers: {
								...parentDomainHeaderRequest,
							},
						})
						_dataNoCache = processDataNoCacheV1({ dataNoCache })
					}
					const mode = initTheme({
						auth: _dataNoCache.auth,
						displayMode: _dataNoCache.displayMode,
						enableLightMode: globalAttribute.enableLightMode,
						enableDarkMode: globalAttribute.enableDarkMode,
						enableDarkModeForGuest: globalAttribute.enableDarkModeForGuest,
						isB2B: globalAttribute.isB2B,
					})
					const periods = await _queryApi.dispatch(
						sportServices.endpoints.getPeriodList.initiate(undefined, {
							forceRefetch: true,
						}),
					)
					const Period = new Periods(periods.data)
					if (_dataNoCache.auth && !globalAttribute.isB2B && _dataNoCache.view !== VIEWS.FUTURE) {
						await _queryApi.dispatch(
							memberServices.endpoints.updateDefaultView.initiate(
								{ view: VIEWS.FUTURE },
								{
									forceRefetch: true,
								},
							),
						)
					}
					return {
						data: {
							globalAttribute: globalAttribute,
							template: templateSkin,
							initDataForComps: initDataForComps,
							dataNoCache: _dataNoCache,
							periods: Period.periods,
							mode: mode.toLowerCase(),
						},
					}
				} catch (e) {
					console.warn('warn: ', e)
					return { error: e.error }
				}
			},
		}),
		getDataNoCache: builder.query({
			query: () => {
				const parentDomainHeaderRequest = getB2BParentDomain(window?.env?.isB2B)
					? { 'X-Parent-Domain': getB2BParentDomain(window?.env?.isB2B) }
					: {}
				return {
					endpoint: DATA_NOCACHE,
					method: 'get',
					headers: {
						...parentDomainHeaderRequest,
					},
				}
			},
			onQueryStarted: async (_args, { queryFulfilled, dispatch }) => {
				try {
					const queryResult = await queryFulfilled
					processDataNoCacheV1({
						dataNoCache: queryResult,
					})
				} catch (e) {
					console.error('Error: ', e)
				}
			},
		}),

		getDataNoCacheV2: builder.query({
			query: () => ({
				endpoint: DATA_NOCACHE_V2,
				method: 'get',
			}),
			onQueryStarted: async (_args, { queryFulfilled, dispatch }) => {
				try {
					const queryResult = await queryFulfilled
					processDataNoCacheV2({
						dataNoCache: queryResult,
					})
				} catch (e) {
					console.error('Error: ', e)
				}
			},
		}),
		getAccountBalance: builder.query({
			query: () => ({
				endpoint: ACCOUNT_BALANCE,
				method: 'get',
			}),
			onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled
					if (!isEmpty(data?.tokens)) {
						updateHeaderTokens(data.tokens)
					}
					if (!isEmpty(data)) {
						dispatch(setUser(data))
					}
				} catch (e) {
					console.error('Error: ', e)
				}
			},
		}),
		login: builder.mutation({
			async queryFn(
				_arg,
				{ dispatch, getState },
				_extraOptions,
				memberServicesBQ,
			) {
				const { loginId, password, captcha, captchaToken } = _arg
				const trustCode = JSON.parse(localStorage.getItem('trustCode')) ?? {}
				const loginResult = await memberServicesBQ({
					endpoint: LOGIN,
					method: 'post',
					body: {
						loginId,
						password,
						captcha,
						captchaToken,
					},
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Trust-Code': trustCode[loginId] || '',
					},
				})
				const error = Session.catchLoginError(loginResult)
				if (error) {
					return {
						data: loginResult.data,
						error: error,
					}
				}
				const loginResponseCode = loginResult.data
				const logLoggedInFail =
					typeof loginResponseCode === 'number'
						? loginResponseCode
						: loginResponseCode.split('\n')
				const status =
					logLoggedInFail.length > 0
						? parseInt(logLoggedInFail[logLoggedInFail.length - 1])
						: loginResponseCode
				if (STATUS_LOGIN_SUCCESS.includes(status)) {
					Session.transformTrustCodeToStorage(loginId)
					dispatch(
						memberServices.endpoints.getDataNoCache.initiate(
							{},
							{ forceRefetch: true },
						),
					)
				}
				return {
					data: loginResult.data,
				}
			},
		}),
		authenticate: builder.mutation({
			async queryFn(
				_arg,
				{ dispatch, getState },
				_extraOptions,
				memberServicesBQ,
			) {
				const { loginId, password, captcha, captchaToken } = _arg
				const trustCode = JSON.parse(localStorage.getItem('trustCode')) ?? {}
				const loginResult = await memberServicesBQ({
					endpoint: AUTHENTICATE,
					method: 'post',
					body: {
						loginId,
						password,
						captcha,
						captchaToken,
					},
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Trust-Code': trustCode[loginId] || '',
					},
				})
				const error = Session.catchLoginError(loginResult)
				if (error) {
					return {
						data: loginResult.data,
						error: error,
					}
				}
				const loginResponseCode = loginResult.data?.code
				const loginResponseFailedAttempts = loginResult.data?.failedAttempts
				const logLoggedInFail =
					typeof loginResponseCode === 'number'
						? loginResponseCode
						: loginResponseFailedAttempts.split('\n')
				const status =
					logLoggedInFail.length > 0
						? parseInt(logLoggedInFail[logLoggedInFail.length - 1])
						: loginResponseCode
				if (STATUS_LOGIN_SUCCESS.includes(status)) {
					setHeaderTokens(loginResult.data?.tokens)
					setHeaderXAppData(loginResult.headers[LOCAL_STORAGE.X_APP_DATA])
					setDynamicParameter({ ...getDynamicParameter() })
					Session.transformTrustCodeToStorage(loginId)
					dispatch(
						memberServices.endpoints.getDataNoCacheV2.initiate(
							{},
							{ forceRefetch: true },
						),
					)
				}
				return {
					data: loginResult.data,
				}
			},
		}),
		authToken: builder.mutation({
			async queryFn(
				_arg,
				{ dispatch, getState },
				_extraOptions,
				memberServicesBQ,
			) {
				const response = await memberServicesBQ({
					endpoint: AUTH_TOKEN,
					method: 'post',
					headers: {
						'Content-Type': 'application/json',
					},
					body: {
						token: _arg?.token,
						locale: _arg?.locale,
						oddsFormat: _arg?.oddsFormat,
						sport: _arg?.sport,
						view: _arg?.view,
						mode: _arg?.mode,
					},
				})
				if (response.data?.success) {
					response.data?.tokens && updateHeaderTokens(response.data.tokens)
					response.headers[LOCAL_STORAGE.X_APP_DATA] &&
						updateHeaderXAppData(response.headers[LOCAL_STORAGE.X_APP_DATA])
					dispatch(
						memberServices.endpoints.getDataNoCacheV2.initiate(
							{},
							{ forceRefetch: true },
						),
					)
					return {
						data: {
							redirectPath: _arg?.redirectPath,
							isAuth: true,
						},
					}
				}
				const { routes } = getCurrentRouterProvider()
				if (response.data?.blockedAuthentication) {
					return {
						data: {
							redirectPath: buildRouteUrl(routes.blockPage, {}, true),
							isAuth: false,
							info: response.data?.blockedAuthentication,
						},
					}
				}
				return {
					data: {
						redirectPath: buildRouteUrl(routes.errorPage, {}, true),
						isAuth: false,
					},
				}
			},
		}),

		logout: builder.mutation({
			async queryFn(
				_arg,
				{ dispatch, getState },
				_extraOptions,
				memberServicesBQ,
			) {
				const response = await memberServicesBQ({
					endpoint: LOGOUT,
					method: 'post',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				})
				dispatch(setSelectedOdds([]))
				localStorage.setItem(ODDS_SELECTION_STORAGE_KEY, JSON.stringify([]))
				dispatch(setSelectionsBetSlip([]))
				Session.logout()
				dispatch(resetSession())
				dispatch(resetBetsSuccess())
				if (window.env.headerBasedEnabled) {
					dispatch(
						memberServices.endpoints.getDataNoCacheV2.initiate(
							{},
							{ forceRefetch: true },
						),
					)
				} else {
					dispatch(
						memberServices.endpoints.getDataNoCache.initiate(
							{},
							{ forceRefetch: true },
						),
					)
				}
				return {
					data: response.data,
					error: response.error,
				}
			},
		}),
		agreeTermsAndConditions: builder.query({
			query: (promoEmail) => ({
				endpoint: ACCEPT_TERM_CONDITION,
				method: 'get',
				params: {
					promoEmail,
				},
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
			}),
			onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled
					if (data) {
						dispatch(setTermAndConditions(false))
					}
				} catch (e) {
					console.error('Error: ', e)
				}
			},
		}),
		getPersonalMessage: builder.query({
			query: () => ({
				endpoint: LAST_MESSAGE,
				method: 'get',
			}),
			onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
				const { data } = await queryFulfilled
				if (data?.message) {
					dispatch(setEnabledPersonalMessage(false))
				}
			},
		}),
		getAllSelection: builder.query({
			async queryFn(
				_arg,
				{ dispatch, getState },
				_extraOptions,
				memberServicesBQ,
			) {
				try {
					const { selections } =
						_arg === BET_SLIP_DISPLAY.quickBet
							? getSelectionQuickBet(getState())
							: getSelections(getState())
					const oddsFormat = ODDS_FORMAT[getState().Preferences.odds]
					const { data: cached } = await dispatch(
						memberServices.endpoints.getAllSelection.initiate(_arg, {
							subscribe: false,
							forceRefetch: false,
						}),
					)
					const singles = await memberServicesBQ({
						endpoint: ALL_ODDS_SELECTIONS,
						method: 'post',
						body: {
							oddsSelections: [
								...getParamsSingleSelection(selections, oddsFormat),
							],
						},
					})
					if (singles.data?.error === ERROR_MESSAGES.E999) {
						return {
							data: [],
							error: ERROR_MESSAGES.E999,
						}
					}
					const singleSelections = singles?.data || []

					return {
						data:
							singleSelections.length > 0
								? attachMoreFromState(
										singleSelections,
										cached || [],
										oddsFormat,
								  )
								: selections,
					}
				} catch (err) {
					return {
						data: err.data,
						error: err,
					}
				}
			},
		}),
		getParlaySelections: builder.query({
			async queryFn(
				_arg,
				{ dispatch, getState },
				_extraOptions,
				memberServicesBQ,
			) {
				try {
					const { data: cached } = await dispatch(
						memberServices.endpoints.getParlaySelections.initiate(undefined, {
							subscribe: false,
							forceRefetch: false,
						}),
					)
					const { parlaySelections: legsParlay } = getSelections(getState())
					const oddsFormat = ODDS_FORMAT[getState().Preferences.odds]
					const request = {
						oddsFormat,
						ticketId: generateUUID(),
						parlayOddsSelections: legsParlay.map((leg) => {
							return {
								legId: generateUUID(),
								selectionId: leg.selectionId,
							}
						}),
					}
					dispatch(updateMultiplesTicketId(request?.ticketId))
					const multiples = await memberServicesBQ({
						endpoint: PARLAY_ODDS_SELECTIONS,
						method: 'post',
						body: {
							parlayOddsRequest: request,
						},
					})
					const multiplesSelections = multiples?.data || {}
					let updatedSelection = false
					if (
						cached?.legs &&
						multiplesSelections?.legs &&
						cached.legs.length === multiplesSelections.legs.length
					) {
						updatedSelection = multiplesSelections.legs.every((leg) =>
							cached.legs.some(
								(legCached) => leg.selectionId === legCached.selectionId,
							),
						)
					}
					return {
						data: updatedSelection
							? attachMoreParlayFromState(
									multiplesSelections,
									cached || [],
									oddsFormat,
							  )
							: multiplesSelections,
					}
				} catch (err) {
					return {
						data: err.data,
						error: err,
					}
				}
			},
		}),
		getPlannedMaintenance: builder.query({
			query: (locale) => ({
				endpoint: MAINTENANCE_BY_LANG,
				method: 'get',
				params: {
					locale,
				},
			}),
		}),
		getMyBets: builder.query({
			query: () => ({
				endpoint: MY_BETS,
				method: 'get',
			}),
			keepUnusedDataFor: 0,
		}),
		getMyBetStatus: builder.query({
			async queryFn(
				_arg,
				{ dispatch, getState },
				_extraOptions,
				memberServicesBQ,
			) {
				try {
					const wagerId = _arg.wagerId
					const myBet = await memberServicesBQ({
						endpoint: MY_BETS,
					})
					const status = wagerId.reduce(
						(result, wagerId) => {
							const findBet = myBet.data.find(
								(bet) => bet[WAGER_MYBET_MAP.wagerId] === wagerId,
							)
							if (!isEmpty(findBet)) {
								switch (findBet[WAGER_MYBET_MAP.status]) {
									case STATUS_WAGER.PENDING:
										result.pending.push(findBet)
										break
									case STATUS_WAGER.CANCELLED:
									case STATUS_WAGER.REJECTED:
										result.rejected.push(findBet)
										break
									default:
										result.accepted.push(findBet)
										break
								}
							}
							return result
						},
						{
							pending: [],
							accepted: [],
							rejected: [],
						},
					)
					return {
						data: status,
						isSuccess: true,
					}
				} catch (err) {
					return {
						data: err.data,
						error: err,
					}
				}
			},
		}),
		searchWager: builder.query({
			async queryFn(
				wagerIds,
				{ dispatch, getState },
				_extraOptions,
				memberServicesBQ,
			) {
				try {
					const [...wagers] = await Promise.allSettled(
						wagerIds.map((id) => {
							return memberServicesBQ({
								endpoint: SEARCH_WAGER,
								params: {
									wagerid: id,
								},
							})
						}),
					)
					const responseFulfilled = wagers.map(
						(response) => response.value?.data?.page?.records?.[0],
					)
					return {
						data: responseFulfilled,
						isSuccess: true,
					}
				} catch (err) {
					return {
						data: err.data,
						error: err,
					}
				}
			},
		}),
		updateDefaultView: builder.query({
			query: ({ view }) => ({
				endpoint: UPDATE_DEFAULT_VIEW,
				method: 'post',
				params: {
					defaultView: view,
				},
			}),
		}),
		updateCurrentLocale: builder.mutation({
			query: ({ locale }) => ({
				endpoint: UPDATE_LOCALE,
				method: 'post',
				params: {
					lang: locale,
				},
			}),
		}),
		updateDisplayMode: builder.mutation({
			query: ({ mode }) => ({
				endpoint: UPDATE_MODE,
				method: 'post',
				params: {
					displayMode: mode,
				},
			}),
		}),
		updateReasonDpFailedURL: builder.query({
			query: (reason) => ({
				endpoint: UPDATE_REASON_FAIL_DP,
				method: 'get',
				params: {
					reason: reason,
				},
			}),
		}),
		updateFavourite: builder.mutation({
			query: ({ data, action, favouritePayload }) => {
				let favouriteData = []
				const processFavouriteDB = (favoriteDb, index) => {
					let entityId = favoriteDb.entityId?.toString()
					switch (favoriteDb.entityType) {
						case FAVOURITES_TYPE.PARTICIPANT: {
							entityId = entityId?.split('-').slice(1).join('-')
							break
						}
						default: {
							break
						}
					}
					return { ...favoriteDb, order: index, entityId }
				}
				switch (action) {
					case UPDATE_FAVOURITES_ACTION.ADD: {
						favouriteData = [
							...favouritePayload,
							{
								sportId: data.sportId,
								entityId: data.id,
								entityType: data.type,
							},
						].map((item, index) => processFavouriteDB(item, index))
						break
					}
					case UPDATE_FAVOURITES_ACTION.REMOVE: {
						favouriteData = favouritePayload
							.filter(({ entityId }) => entityId !== data.id)
							.map((item, index) => processFavouriteDB(item, index))
						break
					}
					case UPDATE_FAVOURITES_ACTION.ORDER:
						favouriteData = data?.map((item, index) => {
							const favoriteDb = {
								sportId: item.sportId,
								entityId: item.id,
								entityType: item.type,
							}
							return processFavouriteDB(favoriteDb, index)
						})
						break
					default:
						break
				}
				return {
					endpoint: getCurrentRouterProvider()?.apiEndpoint?.updateFavourite,
					method: 'post',
					body: favouriteData,
				}
			},
			onQueryStarted: async (
				{ data, action, triggerTooltip = () => {} },
				{ queryFulfilled, dispatch, getState },
			) => {
				switch (action) {
					case UPDATE_FAVOURITES_ACTION.ADD:
						dispatch(addFavourite(data))
						break
					case UPDATE_FAVOURITES_ACTION.REMOVE:
						dispatch(removeFavourite(data))
						break
					case UPDATE_FAVOURITES_ACTION.ORDER:
						dispatch(orderFavourite(data))
						break
					default:
						break
				}
				try {
					const response = await queryFulfilled
					if (response.data) {
						const favouriteItem = getState().FavouriteSports?.favouriteItem
						const params = createParamsGetFavouriteTotalEvents(
							Object.values(favouriteItem),
						)
						triggerTooltip()
						return dispatch(
							sportServices?.endpoints?.getFavouriteLeftMenuTotalEvents.initiate(
								{ ...params },
								{ forceRefetch: true },
							),
						)
					}
				} catch (e) {
					dispatch(undoFavourite())
				}
			},
		}),
	}),
})

export const {
	useGetTemplateQuery,
	useGetAccountBalanceQuery,
	useGetMyBetsQuery,
	useGetMyBetStatusQuery,
	useLoginMutation,
	useLogoutMutation,
	useLazyGetDataNoCacheQuery,
	useLazyGetDataNoCacheV2Query,
	useGetPlannedMaintenanceQuery,
	useLazyGetPersonalMessageQuery,
	useLazyAgreeTermsAndConditionsQuery,
	useGetAllSelectionQuery,
	useGetParlaySelectionsQuery,
	useLazyGetMyBetsQuery,
	useUpdateCurrentLocaleMutation,
	useUpdateDisplayModeMutation,
	useUpdateFavouriteMutation,
	useAuthenticateMutation,
	useAuthTokenMutation,
} = memberServicesQuery

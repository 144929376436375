/**
 *
 * @type {Object.<number, string>}
 * @description  Mapping of sport ID to sport name, which using on URLs or sport icon names.
 */
export const SPORT_ID = {
	1: 'badminton',
	2: 'bandy',
	3: 'baseball',
	4: 'basketball',
	6: 'boxing',
	5: 'beach-volleyball',
	7: 'chess',
	8: 'cricket',
	9: 'curling',
	10: 'darts',
	11: 'Darts',
	12: 'esports',
	13: 'fieldhockey',
	14: 'floorball',
	15: 'football',
	16: 'futsal',
	17: 'golf',
	18: 'handball',
	19: 'hockey',
	20: 'horse-racing',
	21: 'lacrosse',
	22: 'mma',
	23: 'other-sports',
	26: 'rugby-league',
	27: 'rugby-union',
	28: 'snooker',
	29: 'soccer',
	24: 'politics',
	30: 'softball',
	31: 'squash',
	33: 'tennis',
	32: 'table-tennis',
	34: 'volleyball',
	35: 'volleyball-point',
	36: 'waterpolo',
	37: 'padel-tennis',
	39: 'aussie-rules',
	40: 'alpine-skiing',
	41: 'biathlon',
	42: 'skijumping',
	43: 'cross-country',
	44: 'formula-1',
	45: 'cycling',
	46: 'bobsleigh',
	47: 'figureskating',
	48: 'freestyleskiing',
	49: 'luge',
	50: 'nordiccombined',
	51: 'shorttrack',
	52: 'skeleton',
	53: 'snowboarding',
	54: 'speedskating',
	55: 'olympics',
	56: 'athletics',
	57: 'crossfit',
	58: 'entertainment',
	59: 'virtual-dog',
	60: 'drone-racing',
	62: 'poker',
	63: 'motorsport',
	64: 'simulated-games',
	65: 'sumo',
}

/**
 *
 * @type {Object.<string, number>}
 * @description  Mapping of sport name to sport ID, which using on MAPPING_ROUTES.
 */
export const SPORT_NAME = {
	badminton: 1,
	bandy: 2,
	baseball: 3,
	basketball: 4,
	boxing: 6,
	'beach-volleyball': 5,
	chess: 7,
	cricket: 8,
	curling: 9,
	darts: 10,
	Darts: 11,
	esports: 12,
	'e-sports': 12,
	fieldhockey: 13,
	floorball: 14,
	football: 15,
	futsal: 16,
	golf: 17,
	handball: 18,
	hockey: 19,
	'horse-racing': 20,
	lacrosse: 21,
	mma: 22,
	'other-sports': 23,
	'rugby-league': 26,
	'rugby-union': 27,
	snooker: 28,
	soccer: 29,
	politics: 24,
	softball: 30,
	squash: 31,
	tennis: 33,
	'table-tennis': 32,
	volleyball: 34,
	'volleyball-point': 35,
	waterpolo: 36,
	'padel-tennis': 37,
	'aussie-rules': 39,
	'alpine-skiing': 40,
	biathlon: 41,
	skijumping: 42,
	'cross-country': 43,
	'formula-1': 44,
	cycling: 45,
	bobsleigh: 46,
	figureskating: 47,
	freestyleskiing: 48,
	luge: 49,
	nordiccombined: 50,
	shorttrack: 51,
	skeleton: 52,
	snowboarding: 53,
	speedskating: 54,
	olympics: 55,
	athletics: 56,
	crossfit: 57,
	entertainment: 58,
	'virtual-dog': 59,
	'drone-racing': 60,
	poker: 62,
	motorsport: 63,
	'simulated-games': 64,
	sumo: 65,
}

export const ALL_LEAGUES = -1

const unAvailableButton = `<button
class="market-btn styleButton stylePill styleHorizontal styleDisabled" tooltip="<%= data.translate?.unavailable   %>"
>
<i class="icon-unavailable-2 styleUnavailable styleUnavailableTeamTotal"></i>

</button>`

const renderOfflineButton = (idOffline) => {
	return `<button
 id="<%= 't'+${idOffline}  %>"
class="market-btn styleButton stylePill styleHorizontal styleDisabled" tooltip="<%= data.translate?.currentlyOffline   %>"
>
<i class="icon icon-lock styleUnavailable offlineButtonTeamTotal"></i>

</button>`
}

const homeOverUnderButtons = `<div class="styleButtonWrapper">
<% if(isShowHome) {  %>
    <button
    title="<%= home.points  %>"
    aria-label="Money Line 104"
    class="market-btn styleButton stylePill styleHorizontal <%= home.overOdds ? '' : 'styleDisabled'  %> <%= selectedOddIds.includes(homeOverId) || homeOverId === quickBet ?"styleSelected":""%>"
    lineId="<%= home.lineId %>"
    id="<%= homeOverIdToSet %>"
    onclick="<%= data.eventClickFunction %>(event,'<%= leagueTime %>', '<%= leagueId %>', '<%= eventId %>', '<%= periodEntry %>','4', '5','<%= home.overOdds %>','<%= home.points %>','<%= home.isAlt %>','<%= homeTeamType %>')"
>  

    <span class="styleLabelTotal"><%= home.points  %></span
    ><span class="stylePrice"><%= home.overOdds  %></span>
</button>
<% } else { %>
    <%  
    if (isHomeOffline) {
    %>
    ${renderOfflineButton('homeOverIdToSet')}
    <% } else { %>
        ${unAvailableButton}
        <%  } %>
    <% } %> 
</div>
<div class="styleButtonWrapper">
<% if(isShowHome) {  %>
    <button
    title="<%= home.points  %>"
    aria-label="Money Line -119"
    lineId="<%= home.lineId %>"
    id="<%= homeUnderIdToSet %>"
    onclick="<%= data.eventClickFunction %>(event,'<%= leagueTime %>', '<%= leagueId %>', '<%= eventId %>', '<%= periodEntry %>','4', '6', '<%= home.underOdds %>','<%= home.points %>','<%= home.isAlt %>', '<%= homeTeamType %>')"
    class="market-btn styleButton stylePill styleHorizontal  <%= home.underOdds ? '' : 'styleDisabled'  %>   <%= selectedOddIds.includes(homeUnderId) || homeUnderId === quickBet ? "styleSelected" : "" %>"
>

<span class="styleLabelTotal"><%= home.points  %></span
><span class="stylePrice"><%= home.underOdds  %></span>
</button>
<% } else { %>
    <%  
    if (isHomeOffline) {
    %>
    ${renderOfflineButton('homeUnderIdToSet')}
    <% } else { %>
        ${unAvailableButton}
        <%  } %>
    <% } %> 
   
</div>`

const awayOverUnderButtons = `<div class="styleButtonWrapper">
<% if(isShowAway) {  %>
    <button
        title="<%= away.points  %>"
        aria-label="Money Line -119"
        lineId="<%= away.lineId %>"
        id="<%= awayOverIdToSet %>"
        onclick="<%= data.eventClickFunction %>(event,'<%= leagueTime %>', '<%= leagueId %>', '<%= eventId %>', '<%= periodEntry %>','5', '7', '<%= away.overOdds %>','<%= away.points %>','<%= away.isAlt %>', '<%= awayTeamType %>')"
        class="market-btn styleButton stylePill styleHorizontal  <%= away.overOdds ? '' : 'styleDisabled'  %>  <%= selectedOddIds.includes(awayOverId) || awayOverId === quickBet ?"styleSelected":""%>"
    >

    <span class="styleLabelTotal"><%= away.points  %></span
    ><span class="stylePrice"><%= away.overOdds  %></span>
</button>
<% } else { %>
    <%  
    if (isAwayOffline) {
    %>
    ${renderOfflineButton('awayOverIdToSet')}
    <% } else { %>
        ${unAvailableButton}
        <%  } %>
    <% } %> 
      
   
</div>
<div class="styleButtonWrapper">

<% if(isShowAway) {  %>
    <button
    title="<%= away.points  %>"
    aria-label="Money Line -119"
    lineId="<%= away.lineId %>"
    id="<%= awayUnderIdToSet %>"
    onclick="<%= data.eventClickFunction %>(event,'<%= leagueTime %>', '<%= leagueId %>', '<%= eventId %>', '<%= periodEntry %>','5', '8', '<%= away.underOdds %>','<%= away.points %>','<%= away.isAlt %>', '<%= awayTeamType %>')"
    class="market-btn styleButton stylePill styleHorizontal  <%= away.underOdds ? '' : 'styleDisabled'  %>  <%= selectedOddIds.includes(awayUnderId) || awayUnderId === quickBet ? "styleSelected" : "" %>"
    >

    <span class="styleLabelTotal"><%= away.points  %></span
    ><span class="stylePrice"><%= away.underOdds  %></span>
</button>
<% } else { %>
    <%  
    if (isAwayOffline) {
    %>
    ${renderOfflineButton('awayUnderIdToSet')}
    <% } else { %>
        ${unAvailableButton}
        <%  } %>
    <% } %> 
      
    
</div>`

export const renderTeamTotalEvent = () => {
	return `<%
    const {selectedOddIds =[], periodNameList, baseRoute, selectedSportName, timeZoneId = '', locale, timeStampDifference, event, gameCode='', leagueCode='', leagueName='',leagueId='', leagueTime, width = 1440, isMobile, selectedPeriod = 0, quickBet, isFavouriteParticipant} = data;

    let validTeamTotalPeriod = data.validTeamTotalPeriod || [];
    if (!isMobile || isFavouriteParticipant) {
        if(width < 1080) {
            validTeamTotalPeriod = validTeamTotalPeriod.slice(0,1)
        }
        if(width < 1280 && width >= 1080) {
            validTeamTotalPeriod = validTeamTotalPeriod.slice(0,2)
        }
    } else {
        validTeamTotalPeriod = validTeamTotalPeriod.filter(period=>period.toString() === selectedPeriod.toString())
    }
    const { time, moreBet, id:eventId, periods=[], homeTeamType, awayTeamType} = event;
    let { runningState = '' } = event;
    if (generateTranslationId?.(runningState) === data?.translate?.liveNow?.id) {
        runningState = data?.translate?.liveNow?.translation;
    };
    const homeTeam = event.participants.find(p=>p.type === 'HOME');
    const awayTeam = event.participants.find(p=>p.type === 'AWAY');
    const participants = [];
    const isShowHomeFirst = homeTeamType === 0;
    if(isShowHomeFirst) {
        participants[0] = homeTeam;
        participants[1] = awayTeam;
    } else {
        participants[0] = awayTeam;
        participants[1] = homeTeam;
    }
    const timeLocale = new Intl.DateTimeFormat(locale, {
        hourCycle: 'h23',
        timeStyle: 'short',
        timeZone: timeZoneId,
      }).format(time + timeStampDifference);
    const { participants_0_score, participants_1_score } = getScoreLiveEvent({periodToDisplay: 0, sportId: data.selectedSport, participants});  
    const navigateEventData = JSON.stringify({ sportId:data.selectedSport, gameCode, leagueCode,
        sportName:data.selectedSportName, leagueId, leagueName, eventId, eventName: participants[0].name.replace(' ','-') + "-vs-" + participants[1].name.replace(' ', '-')
        }) ;
    const navigateEventLink = baseRoute + '/'+selectedSportName.replace(' ','')+ '/' +leagueCode+ '/' + participants[0].name.replaceAll(' ','-') + '-vs-' + participants[1].name.replaceAll(' ','-') + '/' + eventId +'/';
    
      %>

    
    <div class="style-total-metadata  <%= isFavouriteParticipant && isMobile ? 'favourite-participant-mobile': ''   %>">
     <a href="<%= navigateEventLink  %>"  onclick="onNavigateEventClick(event,'<%= navigateEventData  %>')">
        <div class="flex-row styleGameInfo">
            <div class="styleMatchupMetadata <%= isFavouriteParticipant && isMobile ? 'favourite-participant-mobile': ''   %>">
                <div class="ellipsis styleGameInfoLabel">
                    <span class="ellipsis event-row-participant styleParticipant"
                        ><%= participants[0].name   %></span>
                    <%  if(data.isLiveTeamTotal && data.selectedSport === '29' && isFavouriteParticipant){ %>
                            <span class="staticScore styleScore" id="eventScoreHome<%= eventId %>"><%= participants_0_score %></span>
                    <% } %>
                </div>
                <div class="ellipsis styleGameInfoLabel">
                    <span class="ellipsis event-row-participant styleParticipant"
                ><%= participants[1].name   %></span>
                <%  if(data.isLiveTeamTotal && data.selectedSport === '29' && isFavouriteParticipant){ %>
                    <span class="staticScore styleScore" id="eventScoreAway<%= eventId %>"><%= participants_1_score %></span>
                <% } %>
                </div>
                <div class="styleMatchupDate  <%= isFavouriteParticipant && isMobile ? 'favourite-participant-mobile': ''   %> <%= data.isLiveTeamTotal ? 'styleLive':''  %>">
                    <span><%= data.isLiveTeamTotal? runningState : timeLocale %></span>
                    <% if(isFavouriteParticipant && isMobile) { %>
                        <span class="favourite-event-count">+<%= moreBet %><i class="icon-chevron-right-sml count-favourite"></i></span>
                     <% } %>  
                </div>
            </div>
        </div>
     </a>
    </div>
    
    <%  
    validTeamTotalPeriod.forEach(periodEntry => {
        const period = periods[periodEntry] || {}
        const {teamTotals = {}} = period;
        const {away = {},home={}} = teamTotals;
        const isAltHome = home.isAlt ? '1' : '0';
        const isAltAway = away.isAlt ? '1' : '0';
        const homeOverId = eventId+'|'+periodEntry+'|4|5|'+isAltHome+'|'+home?.points;
        const homeUnderId = eventId+'|'+periodEntry+'|4|6|'+isAltHome+'|'+home?.points;
        const awayOverId = eventId+'|'+periodEntry+'|5|7|'+isAltAway+'|'+away?.points;
        const awayUnderId = eventId+'|'+periodEntry+'|5|8|'+isAltAway+'|'+away?.points;
        const isShowHome = home?.points;
        const isShowAway = away?.points;
        const isHomeOffline = home?.offline;
        const isAwayOffline = away?.offline;
        const homeOverIdToSet = 't' +homeOverId + '|' + homeTeamType;
        const homeUnderIdToSet = 't' +homeUnderId + '|' + homeTeamType;
        const awayOverIdToSet = 't' +awayOverId + '|' + awayTeamType;
        const awayUnderIdToSet = 't' +awayUnderId + '|' + awayTeamType;
       
    %>
        <div class="style-slot-teamTotal">
            <div class="style-group-buttons-teamTotal">
            <%  if(isShowHomeFirst){  %>
                ${homeOverUnderButtons}
                ${awayOverUnderButtons}
                <% } else { %>
                ${awayOverUnderButtons}
                ${homeOverUnderButtons}
                <%  } %>
            </div>
        </div>
       
    <%
    })
    %>
    <% if(!isMobile) { %>
        <div class="styleRowEnd">
            <a href="<%= navigateEventLink  %>"  onclick="onNavigateEventClick(event,'<%= navigateEventData  %>')"
                class="styleBtnRowEnd"
                href="#"
                >+<%= moreBet  %><i class="icon-chevron-right-sml"></i>
            </a>
        </div>
    <% } %>

    %>
    
    `
}

export const renderTeamTotalTemplate = (config) => {
	return `
    <%
    const {selectedOddIds =[], periodNameList, baseRoute, selectedSportName, timeZoneId = '', locale, timeStampDifference, matchData:dataTotalAll, isLiveTeamTotal, translate, selectedDate = 0, width = 1440, isMobile, selectedPeriod = 0, showLeagueLink = false, quickBet, isFavouriteParticipant } = data;
    const {leaguesByDateTotal = []} = dataTotalAll;
    let validTeamTotalPeriod = dataTotalAll.validTeamTotalPeriod || [];
    
    if (!isMobile || isFavouriteParticipant) {
        if(width < 1080) {
            validTeamTotalPeriod = validTeamTotalPeriod.slice(0,1)
        }
        if(width < 1280 && width >= 1080) {
            validTeamTotalPeriod = validTeamTotalPeriod.slice(0,2)
        }
    } else {
        validTeamTotalPeriod = validTeamTotalPeriod.filter(period=>period.toString() === selectedPeriod.toString())
    }
    
    
    const leaguesFilter = leaguesByDateTotal.filter(le=>{
        if(!selectedDate) 
        {
            return true
        } else {
            return le.dateTime === selectedDate
        }
        
    })
   

    leaguesFilter.forEach(function(dateLeague,indexLeague){
        const  { leagues=[], leagueTime, eventCount = 0 } = dateLeague;
        let headerLocale = new Intl.DateTimeFormat(locale, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
          }).format(leagueTime + timeStampDifference);
          if(dateLeague.isToday) {
            headerLocale = translate.today + ' (' + eventCount + ')';
          }

          if(dateLeague.isTomorrow) {
            headerLocale = translate.tomorrow
          }
          const leagueData = leagues[0] || {};
           const navigateData = JSON.stringify({ sportId:data.selectedSport,
            sportName:data.selectedSportName, leagueId:leagueData.id, leagueCode:leagueData.leagueCode, leagueName:leagueData.name, gameCode:leagueData.gameCode
            }) ;
    %>
    <div>
        <% if(!isLiveTeamTotal) { %>
            <div class="style-datebar">
                <span><%= headerLocale   %></span>
            </div>
        <% } %>
      
        <div class="styleRowTotal styleRowMetaPeriod">
        <div class="style-total-metadata"><span></span></div>
		    
            <% 
            validTeamTotalPeriod.forEach(function(period,indexPeriod){
            %>
                <div class="style-slot-teamTotal">
                    <span class="styleLabelMeta"><%= periodNameList[period].replaceAll('(','').replaceAll(')','')  %></span>
                </div>
            <%
            })
            %>
		   
            <% if(!isMobile) { %>  <div class="styleRowEnd"><span></span></div> <% } %>
	    </div>
        <div class="styleRowTotal styleRowMetaBetType">
            <div class="style-total-metadata">
            <% if(showLeagueLink) { %> 
                <a class="styleRowLink"  onclick="onLeagueClick('<%= navigateData  %>')">
                    <span class="ellipsis"><%= leagueData.name %></span>
                    <i class="icon-chevron-right-sml styleIcon"></i>
                </a>
            <% } %>
            </div>
            
            <% 
            validTeamTotalPeriod.forEach(function(period,indexPeriod){
            %>
                <div class="style-slot-teamTotal">
                    <span class="styleLabelMeta"><%= data.translate?.overHeader   %></span>
                    <span class="styleLabelMeta"><%= data.translate?.underHeader   %></span>
                </div>
            <%
            })
            %>
            <% if(!isMobile) { %>   <div class="styleRowEnd"><span></span></div> <% } %>
        </div>

        <%  
            leagues.forEach(function(league,leagueIndex){
                const  { events = [] } = league;
                const leagueId = league.id; 
                const { name: leagueName, gameCode='', leagueCode } = league;
               
                events.forEach(function(event,eventIndex){
                    const { time, moreBet, id:eventId, periods=[], homeTeamType, awayTeamType} = event;
                    let { runningState = '' } = event;
                    if (generateTranslationId?.(runningState) === data?.translate?.liveNow?.id) {
                        runningState = data?.translate?.liveNow?.translation;
                    };
                    const homeTeam = event.participants.find(p=>p.type === 'HOME');
                    const awayTeam = event.participants.find(p=>p.type === 'AWAY');
                    const isShowHomeFirst = homeTeamType === 0;
                    const participants = [];
                    if(isShowHomeFirst) {
                        participants[0] = homeTeam;
                        participants[1] = awayTeam;
                    } else {
                        participants[0] = awayTeam;
                        participants[1] = homeTeam;
                    }
                    const participants_0_score = typeof participants[0]?.score === 'object' ? Object.values(participants[0]?.score).reduce((a, b) => a + b, 0) : participants[0]?.score;
                    const participants_1_score  = typeof participants[1]?.score === 'object' ? Object.values(participants[1]?.score).reduce((a, b) => a + b, 0) : participants[1]?.score;
                    const navigateEventData = JSON.stringify({ sportId:data.selectedSport, gameCode, leagueCode,
                        sportName:data.selectedSportName, leagueId, leagueName, eventId, eventName: participants[0].name.replace(' ','-') + "-vs-" + participants[1].name.replace(' ', '-')
                        }) ;
                    const navigateEventLink = baseRoute + '/'+selectedSportName.replace(' ','')+ '/' +leagueCode+ '/' + participants[0].name.replaceAll(' ','-') + '-vs-' + participants[1].name.replaceAll(' ','-') + '/' + eventId +'/';
                    const timeLocale = new Intl.DateTimeFormat(locale, {
                        hourCycle: 'h23',
                        timeStyle: 'short',
                        timeZone: timeZoneId,
                      }).format(time + timeStampDifference);
        %>
                <div class="styleRowTotal styleRowMetaOddData <%= isFavouriteParticipant && isMobile ? 'favourite-participant-mobile': ''   %>" id="eventTeamTotal<%= eventId %>">
                    <div class="style-total-metadata  <%= isFavouriteParticipant && isMobile ? 'favourite-participant-mobile': ''   %>">
                     <a href="<%= navigateEventLink  %>"  onclick="onNavigateEventClick(event,'<%= navigateEventData  %>')">
                        <div class="flex-row styleGameInfo">
                            <div class="styleMatchupMetadata <%= isFavouriteParticipant && isMobile ? 'favourite-participant-mobile': ''   %>">
                                <div class="ellipsis styleGameInfoLabel">
                                    <span class="ellipsis event-row-participant styleParticipant"
                                        ><%= participants[0].name   %></span>
                                    <%  if(data.isLiveTeamTotal && data.selectedSport === '29' && isFavouriteParticipant){ %>
                                         <span class="staticScore styleScore" id="eventScoreHome<%= eventId %>"><%= participants_0_score %></span>
                                    <% } %>
                                </div>
						        <div class="ellipsis styleGameInfoLabel">
							        <span class="ellipsis event-row-participant styleParticipant"
								    ><%= participants[1].name   %></span>
                                    <%  if(data.isLiveTeamTotal && data.selectedSport === '29' && isFavouriteParticipant){ %>
                                        <span class="staticScore styleScore" id="eventScoreAway<%= eventId %>"><%= participants_1_score %></span>
                                    <% } %>
						        </div>
                                <div class="styleMatchupDate  <%= isFavouriteParticipant && isMobile ? 'favourite-participant-mobile': ''   %> <%= data.isLiveTeamTotal ? 'styleLive':''  %>">
                                    <span><%= data.isLiveTeamTotal? runningState : timeLocale %></span>
                                    <% if(isFavouriteParticipant && isMobile) { %>
                                        <span class="favourite-event-count">+<%= moreBet %><i class="icon-chevron-right-sml count-favourite"></i></span>
                                     <% } %>   
                                    
                                </div>
                            </div>
                        </div>
                     </a>
                    </div>
                    
                    <%  
                    validTeamTotalPeriod.forEach(periodEntry => {
                        const period = periods[periodEntry] || {}
                        const {teamTotals = {}} = period;
                        const {away = {},home={}} = teamTotals;
                        const isAltHome = home.isAlt ? '1' : '0';
                        const isAltAway = away.isAlt ? '1' : '0';
                        const homeOverId = eventId+'|'+periodEntry+'|4|5|'+isAltHome+'|'+home?.points;
                        const homeUnderId = eventId+'|'+periodEntry+'|4|6|'+isAltHome+'|'+home?.points;
                        const awayOverId = eventId+'|'+periodEntry+'|5|7|'+isAltAway+'|'+away?.points;
                        const awayUnderId = eventId+'|'+periodEntry+'|5|8|'+isAltAway+'|'+away?.points;
                        const isShowHome = home?.points;
                        const isShowAway = away?.points;
                        const isHomeOffline = home?.offline;
                        const isAwayOffline = away?.offline;
                        const homeOverIdToSet = 't' +homeOverId + '|' + homeTeamType;
                        const homeUnderIdToSet = 't' +homeUnderId + '|' + homeTeamType;
                        const awayOverIdToSet = 't' +awayOverId + '|' + awayTeamType;
                        const awayUnderIdToSet = 't' +awayUnderId + '|' + awayTeamType;
                       
                    %>
                        <div class="style-slot-teamTotal <%= isFavouriteParticipant && isMobile ? 'favourite-participant-mobile': ''   %>">
                            <div class="style-group-buttons-teamTotal <%= isFavouriteParticipant && isMobile ? 'favourite-participant-mobile': ''   %>">
                            <%  if(isShowHomeFirst){  %>
                                ${homeOverUnderButtons}
                                ${awayOverUnderButtons}
                                <% } else { %>
                                ${awayOverUnderButtons}
                                ${homeOverUnderButtons}
                                <%  } %>
                               
                            </div>
                        </div>
                       
                    <%
                    })
                    %>
                    <% if(!isMobile) { %>
                        <div class="styleRowEnd">
                            <a  href="<%= navigateEventLink  %>"  onclick="onNavigateEventClick(event,'<%= navigateEventData  %>')"
                                class="styleBtnRowEnd"
                                href="#"
                                >+<%= moreBet  %><i class="icon-chevron-right-sml"></i>
                            </a>
                        </div>
                    <% } %>
                </div>
        <%
                })
            })
        %>
      
    </div>
    <%  
    })
    %>
    `
}

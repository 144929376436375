import { createSlice } from '@reduxjs/toolkit'
import {
	addToSelections,
	removeFromSelections,
	separateOddsId,
	updateSelection,
} from 'services/Selections'
import { BET_TYPE, ODDS_FORMAT, VIEW_PORT } from 'constants/common'
import { deepCopy } from 'services/Common'
import PreciseOdds from 'apps/types/PreciseOdds'

const EMPTY_VALUE = ''
const initialState = {
	minMaxBets: {},
	totalStake: 0,
	invalidStake: false,
	stakePerBet: EMPTY_VALUE,
	winRiskOptionPerBet: EMPTY_VALUE,
	isLoading: false,
	isError: false,
	selections: [],
	isMaxPicks: false,
	openMobile: false,
	totalStakeMultiplesMobile: 0,
	multiplesTicketId: '',
	bets: {},
	pendingBets: [],
	pendingMultipleBets: [],
	processingBets: [],
	quickBet: {
		btnOddsWidth: 0, // 62
		btnOddsHeight: 0, // 45
		coordinateX: 0, // 946
		coordinateY: 0, // 963
		acceptedBet: false,
		oddsIdBet: null,
		selection: null,
	},
}

export const Betslip = createSlice({
	name: 'Betslip',
	initialState,
	reducers: {
		addSelection: (state, { payload }) => {
			state.bets = initialState.bets
			state.selections = addToSelections(state.selections, payload)
		},
		removeSelection: (state, { payload }) => {
			if (payload.isQuickBet) {
				state.quickBet.acceptedBet = false
				state.quickBet.oddsIdBet = null
				state.quickBet.selection = null
			} else {
				state.selections = removeFromSelections(state.selections, payload)
				state.isMaxPicks = false
			}
		},
		removeAllSelection: (state) => {
			state.selections = []
			state.isMaxPicks = false
		},
		setIsMaxPicks: (state, { payload }) => {
			state.isMaxPicks = payload
		},
		setSelectionsBetSlip: (state, { payload }) => {
			state.selections = payload
		},
		updateWagerSelection: (state, { payload }) => {
			const selections = updateSelection(
				payload.isQuickBet ? [state.quickBet.selection] : state.selections,
				{
					oddsId: payload.oddsId,
					wager: payload.wager,
					winRiskStake: payload.winRiskStake,
				},
			)
			if (payload.isQuickBet) {
				state.quickBet.acceptedBet = false
				state.quickBet.selection = selections[0]
			} else {
				state.selections = selections
			}
		},
		addSelectionEventDetail: (state, { payload }) => {
			if (state.selections.length < 10) {
				state.bets = initialState.bets
				state.selections = addToSelections(state.selections, payload)
			}
		},
		updateMultiplesTicketId: (state, { payload }) => {
			state.multiplesTicketId = payload
		},
		updateBetsSuccess: (state, { payload }) => {
			state.bets = { ...payload }
		},
		resetBetsSuccess: (state) => {
			state.bets = initialState.bets
		},
		updatePendingBets: (state, { payload }) => {
			state.pendingBets = payload
		},
		updatePendingMultipleBets: (state, { payload }) => {
			state.pendingMultipleBets = payload
		},
		reuseMultipleBets: (state, { payload = [] }) => {
			const _selections = JSON.parse(JSON.stringify(state.selections))
			if (_selections.length + payload.length > 10) {
				state.selections = payload
			} else {
				state.selections = [..._selections, ...payload]
			}
		},
		setOpenMobile: (state, { payload }) => {
			state.openMobile = payload
		},
		setTotalStakeMultiplesMobile: (state, { payload }) => {
			state.totalStakeMultiplesMobile = payload
		},
		setQuickBetSelection: (state, { payload }) => {
			state.quickBet = { ...payload, oddsIdBet: null }
		},
		setQuickBetPosition: (state, { payload }) => {
			state.quickBet.btnOddsHeight = payload?.btnOddsHeight ?? 0
			state.quickBet.btnOddsWidth = payload?.btnOddsWidth ?? 0
			state.quickBet.coordinateX = payload?.coordinateX ?? 0
			state.quickBet.coordinateY = payload?.coordinateY ?? 0
		},
		setProcessingBets: (state, { payload }) => {
			state.processingBets = payload ?? {}
		},
		reuseQuickBetSelection: (state, { payload }) => {
			state.quickBet.selection = payload ?? {}
		},
		updateAcceptQuickBet: (state, { payload }) => {
			state.quickBet.acceptedBet = true
			state.quickBet.oddsIdBet = payload
			state.quickBet.selection = null
		},
		addToBetSlipFromQuickBet: (state) => {
			const _selection = deepCopy(state.quickBet.selection)
			const cloneSelections = deepCopy(state.selections)
			state.quickBet.selection = null
			state.bets = initialState.bets
			const isExistSelection = cloneSelections.some(
				(selection) => selection.oddsId === _selection.oddsId,
			)
			state.selections = isExistSelection
				? cloneSelections.filter(
						(selection) => selection.oddsId !== _selection.oddsId,
				  )
				: addToSelections(cloneSelections, _selection)
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase('Preferences/setOddType', (state, { payload }) => {
				const _selection = deepCopy(state.selections)
				const user = JSON.parse(localStorage.getItem('_User'))
				const updatedSelections = _selection.map((selection) => {
					let odds = selection.odds
					if (user.isLoggedIn !== 'true') {
						const oddsInfo = separateOddsId(selection.oddsId)
						odds = new PreciseOdds(selection.odds, selection.oddsFormat)
							.convertTo(ODDS_FORMAT[payload])
							.rounded()
						if (parseInt(oddsInfo.betType) === BET_TYPE.THE_1X2) {
							odds =
								ODDS_FORMAT[payload] !== ODDS_FORMAT.AMERICAN &&
								selection.oddsFormat !== ODDS_FORMAT.AMERICAN
									? selection.odds
									: new PreciseOdds(
											selection.odds,
											selection.oddsFormat !== ODDS_FORMAT.AMERICAN
												? ODDS_FORMAT.DECIMAL
												: ODDS_FORMAT.AMERICAN,
									  )
											.convertTo(
												ODDS_FORMAT[payload] !== ODDS_FORMAT.AMERICAN
													? ODDS_FORMAT.DECIMAL
													: ODDS_FORMAT.AMERICAN,
											)
											.rounded()
						}
					}
					return {
						...selection,
						odds: odds,
						oddsFormat: ODDS_FORMAT[payload],
					}
				})
				state.selections = updatedSelections
			})
			.addCase('Layout/setView', (state, { payload }) => {
				if (payload === VIEW_PORT.MOBILE) {
					state.isQuickBet = {
						btnOddsWidth: 0,
						btnOddsHeight: 0,
						coordinateX: 0,
						coordinateY: 0,
						acceptedBet: false,
						oddsIdBet: null,
						selection: null,
					}
				}
			})
	},
})

export const {
	addSelectionFutures,
	addSelection,
	removeSelection,
	removeAllSelection,
	setIsMaxPicks,
	setSelectionsBetSlip,
	setOpenMobile,
	updateWagerSelection,
	addSelectionEventDetail,
	updateOldOddsSelection,
	updateMultiplesTicketId,
	updateBetsSuccess,
	updatePendingBets,
	updatePendingMultipleBets,
	reuseMultipleBets,
	resetBetsSuccess,
	setProcessingBets,
	setTotalStakeMultiplesMobile,
	setQuickBetSelection,
	reuseQuickBetSelection,
	updateAcceptQuickBet,
	addToBetSlipFromQuickBet,
	setQuickBetPosition,
} = Betslip.actions

export default Betslip.reducer

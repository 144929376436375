import { isArray, isEmpty, isNil } from 'lodash'
import { transformArrayToObject } from '../Common/object'
import { SPORT_MARKET, SPORT_MARKET_TAB, SPORT_ID, SPORT_NAME } from 'constants'
import {
	buildFavoriteLink,
	buildLeftMenuLeaguesURL,
	buildLeftMenuSportsURL,
	buildRouteUrl,
} from '../Url'
import { match } from 'path-to-regexp'
import { routesStandardEsportsHub, routesStandard } from '../Routes/routes'
import { FAVOURITES_TYPE } from '../../constants/leftMenu'
import { appIntl } from 'apps/components/LocalizedApp/IntGlobalProvider'

// TODO synchronous after websocket update

/**
 *
 * @param sportId  - the sport id
 * @returns {string} - the icon name
 */
export const getIconName = (sportId) => {
	return `icon-generic icon-${SPORT_ID[sportId]}`
}

/**
 *
 * @param {string} gameCode
 * @returns {string} icon name
 */
export const getIconGameName = (gameCode) => {
	return `icon-game-default icon-${gameCode}`
}

/**
 *
 * @param sportList
 * @param featuredIdsList
 * @returns {[]}
 */
const transformSportData = (sportList = [], featuredIdsList = []) => {
	if (!isArray(sportList) || isEmpty(sportList)) {
		return []
	}

	const result = sportList.map((sport) => {
		const transformedSport = transformArrayToObject(sport, SPORT_MARKET_TAB)
		transformedSport.early = transformArrayToObject(
			transformedSport.early,
			SPORT_MARKET,
		)
		transformedSport.live = transformArrayToObject(
			transformedSport.live,
			SPORT_MARKET,
		)
		transformedSport.today = transformArrayToObject(
			transformedSport.today,
			SPORT_MARKET,
		)
		return transformedSport
	})

	if (!isArray(featuredIdsList) || isEmpty(featuredIdsList)) {
		return result
	}

	return result.map((sport) => {
		return {
			id: sport.id,
			name: sport?.early?.name,
			iconName: getIconName(sport.id),
			totalEvents:
				sport?.early?.totalEvents +
				sport?.early?.outrightEvents +
				sport?.today?.totalEvents +
				sport?.today?.outrightEvents,
			isFeatured: featuredIdsList.some((id) => id === sport?.id),
			featureOrder: -1,
			hasChildList: false,
			urlPath: SPORT_ID[sport.id],
		}
	})
}

/**
 *
 * @param {object} updatedSport
 * @param {array} listSport
 * @returns {*[]}
 */
const updateSportData = (updatedSport = {}, listSport = []) => {
	if (isNil(updatedSport) || isEmpty(listSport)) {
		return listSport
	}
	const indexSport = listSport.findIndex(
		(sport) => sport.id === updatedSport.id,
	)
	if (indexSport < 0) {
		return listSport
	}
	const result = [...listSport]
	result[indexSport] = {
		...result[indexSport],
		totalEvents: updatedSport.totalEvents,
	}
	return result
}

/**
 *
 * @param {array} sportListResponse
 * @param {number[]} featuredIdsList
 * @returns {{topSportsList: *[], azSportsList: *[]}}
 */
export const createLeftMenu = (
	sportListResponse = [],
	featuredIdsList = [],
) => {
	if (isEmpty(sportListResponse) || !isArray(sportListResponse)) {
		return {
			azSportsList: [],
			topSportsList: [],
		}
	}
	let sportList = transformSportData(sportListResponse, featuredIdsList)
	const azSportsList = [...sportList]?.sort((firstSport, secondSport) =>
		firstSport?.name?.localeCompare(secondSport?.name),
	)
	const topSportsList = [...sportList]
		?.filter((sport) => sport.isFeatured)
		.map((sport) => ({ ...sport, hasChildList: true }))
	return { azSportsList, topSportsList }
}

/**
 *
 * @param {array} response
 * @param {object} currentLeftMenu
 * @param {number[]} featuredIdsList
 * @returns {{topSportsList: *[], azSportsList: *[]}|{}}
 */
export const updateLeftMenu = (
	response,
	currentLeftMenu = {},
	featuredIdsList,
) => {
	if (typeof response[0] !== 'number') return currentLeftMenu
	let { azSportsList = [], topSportsList = [] } = currentLeftMenu
	const updatedSport = transformSportData([response], featuredIdsList)?.[0]
	azSportsList = updateSportData(updatedSport, azSportsList)
	topSportsList = updateSportData(updatedSport, topSportsList)
	return { azSportsList, topSportsList }
}

/**
 *
 * @typedef {Object} LeftMenuResponseItem
 * @property {number} id - sport ID or league ID
 * @property {string} name - sport name or league name
 * @property {string} englishName - name leagues at English
 * @property {string} gameName - game name
 * @property {string} gameCode - game code use when build url
 * @property {string} leagueCode - league code use when build url
 * @property {number} totalEvents - current total event
 * @property {number} featuresOrder - order of Top Sport
 * @property {boolean} isLive - flag detect has live event
 * @property {boolean} isHighlight - flag detect redirect to highlight page
 * @property {boolean} hasTopLeagues - flag detect sport has a child list
 * @property {'moneyline' | 'spread' | 'handicap'} primaryMarketType - default view market odds page when clicked menu
 */
/**
 *
 * @typedef {Object} LeftMenuItem
 * @property {number} id - sport ID or league ID
 * @property {string} name - sport name or league name
 * @property {string} gameCode - game code use when build url
 * @property {string} leagueCode - league code use when build url
 * @property {string=} iconName - sport icon (league do not have icon)
 * @property {string} urlPath - url use by Link of react-router-dom
 * @property {boolean=} isFeatured - flag detect in Top list
 * @property {boolean} hasChildList - flag detect render child menu or not
 * @property {boolean} isLive - flag detect has live event
 * @property {boolean} isHighlight - flag detect redirect to highlight page
 * @property {number} featuresOrder - order of Top list if not equal -1
 * @property {number} totalEvents - current total event
 * @property {'moneyline' | 'spread' | 'handicap'} primaryMarketType - default view market odds page when clicked menu
 */
/**
 *
 * @param {LeftMenuResponseItem[]} leftMenuResponse
 * @returns {{azSportsIds: number[] | [], topSportsIds: number[] | [], sport: {number: LeftMenuItem} | {}}}
 */
export const createSportMenu = (leftMenuResponse = []) => {
	const result = {
		azSportsIds: [],
		topSportsIds: [],
		sport: {},
		originData: leftMenuResponse,
	}

	if (!leftMenuResponse?.length) {
		return result
	}

	leftMenuResponse.forEach((item) => {
		const temp = {
			...item,
			iconName: getIconName(item.id),
			isFeatured: item.featuresOrder > 0,
			hasChildList: false,
			urlPath: buildLeftMenuSportsURL(item.id, item.isHighlight),
			displayPeriods: item.displayPeriods || [0],
			name: appIntl().formatMessage({
				id: `sport_name.${SPORT_ID[item.id]}`,
				defaultMessage: item.name,
			}),
			sportCode: SPORT_ID[item.id],
		}
		Object.assign(result.sport, { [item.id]: temp })
		result.azSportsIds.push(item.id)

		if (temp.isFeatured) {
			result.topSportsIds.push(item.id)
			result.sport[item.id].hasChildList = item.hasTopLeagues
		}
	})

	result.azSportsIds = result.azSportsIds.sort((firstSportId, secondSportId) =>
		result.sport?.[firstSportId]?.name?.localeCompare(
			result.sport?.[secondSportId]?.name,
		),
	)

	result.topSportsIds = result.topSportsIds.sort(
		(firstSportId, secondSportId) =>
			result.sport?.[firstSportId]?.featuresOrder -
			result.sport?.[secondSportId]?.featuresOrder,
	)
	return result
}
/**
 *
 * @typedef {Object} FavouriteItem
 * @property {number} sportId - The ID of the sport.
 * @property {string} type - The type of the favourite item. It can be one of the following: 'GAME', 'GAME_LEAGUE', 'SPORT_LEAGUE', 'PARTICIPANT'.
 * @property {string} iconName - The name of the icon.
 * @property {number} featuresOrder - The order of the favourite item.
 * @property {number|null} totalEvents - The total number of events.
 * @property {string|number} id - The ID of the favourite item. This can be the game code, league ID, or participant's English name, depending on the type.
 * @property {string} name - The name of the favourite item. This can be the game name, league name, or participant's name, depending on the type.
 * @property {string} urlPath - The URL path of the favourite item.
 */
/**
 *
 * @param {Object} favouriteItemResponse - The favourite item data.
 * @param {number} favouriteItemResponse.sportId - The ID of the sport.
 * @param {string} favouriteItemResponse.type - The type of the favourite item. It can be one of the following: 'GAME', 'GAME_LEAGUE', 'GAME_SPECIAL_LEAGUE','SPORT_LEAGUE', 'SPORT_SPECIAL_LEAGUE', 'PARTICIPANT'.
 * @param {number} favouriteItemResponse.featuresOrder - The order of the favourite item.
 * @param {string} favouriteItemResponse.gameCode - The game code of the favourite item.
 * @param {string} favouriteItemResponse.gameName - The game name of the favourite item.
 * @param {number} favouriteItemResponse.leagueId - The league ID of the favourite item.
 * @param {string} favouriteItemResponse.leagueName - The league name of the favourite item.
 * @param {string} favouriteItemResponse.specialId - The special ID of the favourite item.
 * @param {string} favouriteItemResponse.specialName - The special name of the favourite item.
 * @param {string} favouriteItemResponse.leagueCode - The league code of the favourite item.
 * @param {number} favouriteItemResponse.eventCount - The event count of the favourite item.
 * @param {string} favouriteItemResponse.participantEnglishName - The participant's English name of the favourite item.
 * @param {string} favouriteItemResponse.participantName - The participant's name of the favourite item.
 * @param {Object} globalConfigs - The global configs.
 * @returns {FavouriteItem} The created favourite item object.
 */
export const createFavouriteItem = (favouriteItemResponse, globalConfigs) => {
	const result = {
		sportId: favouriteItemResponse.sportId,
		type: favouriteItemResponse.type,
		iconName: getIconName(favouriteItemResponse.sportId),
		featuresOrder: favouriteItemResponse.featuresOrder,
		totalEvents: null,
	}
	switch (favouriteItemResponse.type) {
		case FAVOURITES_TYPE.GAME:
			return {
				...result,
				id: favouriteItemResponse.gameCode,
				name: favouriteItemResponse.gameName,
				urlPath: buildFavoriteLink({
					type: favouriteItemResponse.type,
					gameCode: favouriteItemResponse.gameCode,
				}),
				totalEvents: favouriteItemResponse.eventCount,
				iconName: globalConfigs?.isB2B
					? getIconGameName(favouriteItemResponse.gameCode)
					: result.iconName,
			}
		case FAVOURITES_TYPE.GAME_LEAGUE:
			return {
				...result,
				id: favouriteItemResponse.leagueId,
				name: favouriteItemResponse.leagueName,
				urlPath: buildFavoriteLink({
					type: favouriteItemResponse.type,
					gameCode: favouriteItemResponse.gameCode,
					leagueCode: favouriteItemResponse.leagueCode,
				}),
				totalEvents: favouriteItemResponse.eventCount,
				iconName: globalConfigs?.isB2B
					? getIconGameName(favouriteItemResponse.gameCode)
					: result.iconName,
				leagueCode: favouriteItemResponse.leagueCode,
			}
		case FAVOURITES_TYPE.GAME_SPECIAL_LEAGUE:
			return {
				...result,
				id: favouriteItemResponse.specialId,
				name: favouriteItemResponse.specialName,
				urlPath: buildFavoriteLink({
					type: favouriteItemResponse.type,
					gameCode: favouriteItemResponse.gameCode,
					leagueCode: favouriteItemResponse.leagueCode,
				}),
				iconName: globalConfigs?.isB2B
					? getIconGameName(favouriteItemResponse.gameCode)
					: result.iconName,
				totalEvents: favouriteItemResponse.eventCount,
				leagueCode: favouriteItemResponse.leagueCode,
			}
		case FAVOURITES_TYPE.SPORT_LEAGUE:
			return {
				...result,
				id: favouriteItemResponse.leagueId,
				name: favouriteItemResponse.leagueName,
				urlPath: buildFavoriteLink({
					type: favouriteItemResponse.type,
					sportCode: SPORT_ID[favouriteItemResponse.sportId],
					leagueCode: favouriteItemResponse.leagueCode,
				}),
				totalEvents: favouriteItemResponse.eventCount,
				leagueCode: favouriteItemResponse.leagueCode,
			}
		case FAVOURITES_TYPE.SPORT_SPECIAL_LEAGUE:
			return {
				...result,
				id: favouriteItemResponse.specialId,
				name: favouriteItemResponse.specialName,
				urlPath: buildFavoriteLink({
					type: favouriteItemResponse.type,
					sportCode: SPORT_ID[favouriteItemResponse.sportId],
					leagueCode: favouriteItemResponse.leagueCode,
				}),
				totalEvents: favouriteItemResponse.eventCount,
				leagueCode: favouriteItemResponse.leagueCode,
			}
		case FAVOURITES_TYPE.PARTICIPANT:
			return {
				...result,
				id: `${favouriteItemResponse.sportId}-${favouriteItemResponse.participantEnglishName}`,
				name: favouriteItemResponse.participantName,
				urlPath: buildFavoriteLink({
					type: favouriteItemResponse.type,
					name: favouriteItemResponse.participantEnglishName,
					sportCode: SPORT_ID[favouriteItemResponse.sportId],
				}),
			}
		default: {
			return {}
		}
	}
}

/**
 *
 * @param {Object} params - The parameters for creating a favourite item.
 * @param {Object} params.data - The data of the favourite item.
 * @param {string|number} params.id - The ID of the favourite item.
 * @param {string} params.type - The type of the favourite item. It can be one of the following: 'GAME', 'GAME_LEAGUE', 'SPORT_LEAGUE', 'PARTICIPANT'.
 * @param {string} params.sportCode - The sport code of the favourite item.
 * @param {string} params.gameCode - The game code of the favourite item. This is only applicable if the type is 'GAME' or 'GAME_LEAGUE'.
 * @param {string} params.leagueCode - The league code of the favourite item. This is only applicable if the type is 'GAME_LEAGUE' or 'SPORT_LEAGUE'.
 * @param {number} params.sportId - The ID of the sport.
 * @param {Object} params.globalConfigs - The global configs.
 * @returns {FavouriteItem} The created favourite item object.
 */
export const createFavouriteItemByFavouriteStar = ({
	data,
	id,
	type,
	sportCode,
	gameCode,
	leagueCode,
	sportId,
	globalConfigs,
}) => {
	const favouriteItem = {
		id: null,
		sportCode: sportCode ? sportCode : SPORT_ID[data?.sportId],
		gameCode,
		sportId,
		leagueId: null,
		leagueCode,
		iconName: getIconName(sportId),
		name: data?.name,
		englishName: data?.englishName,
		totalEvents: data?.totalEvents,
		type: type,
		featuresOrder: null,
		urlPath:
			data?.urlPath ||
			buildFavoriteLink({
				type,
				gameCode,
				leagueCode,
				sportCode,
				name: data?.englishName,
			}),
	}
	switch (type) {
		case FAVOURITES_TYPE.GAME:
			favouriteItem.id = data?.gameCode
			favouriteItem.gameCode = data?.gameCode
			favouriteItem.gameCode = data?.gameCode
			favouriteItem.iconName = globalConfigs?.isB2B
				? getIconGameName(favouriteItem.gameCode)
				: favouriteItem.iconName
			break
		case FAVOURITES_TYPE.GAME_LEAGUE:
			favouriteItem.id = data?.id
			favouriteItem.gameCode = data?.gameCode
			favouriteItem.leagueId = data?.id
			favouriteItem.leagueCode = data?.leagueCode
			favouriteItem.iconName = globalConfigs?.isB2B
				? getIconGameName(favouriteItem.gameCode)
				: favouriteItem.iconName
			break
		case FAVOURITES_TYPE.GAME_SPECIAL_LEAGUE:
			favouriteItem.id = data?.specialId
			favouriteItem.gameCode = data?.gameCode
			favouriteItem.leagueId = data?.id
			favouriteItem.leagueCode = data?.leagueCode
			favouriteItem.iconName = globalConfigs?.isB2B
				? getIconGameName(favouriteItem.gameCode)
				: favouriteItem.iconName
			break
		case FAVOURITES_TYPE.SPORT_LEAGUE:
			favouriteItem.id = data?.id
			favouriteItem.leagueCode = data?.leagueCode
			break
		case FAVOURITES_TYPE.SPORT_SPECIAL_LEAGUE:
			favouriteItem.id = data?.specialId
			favouriteItem.leagueCode = data?.leagueCode
			break
		case FAVOURITES_TYPE.PARTICIPANT:
			favouriteItem.id = id
			favouriteItem.leagueCode = data?.leagueCode
			favouriteItem.name = data?.teamName
			break
		default:
			break
	}
	return favouriteItem
}

const createFavouriteDbItem = (favouriteItem) => {
	return {
		sportId: favouriteItem.sportId,
		entityId: favouriteItem.id,
		entityType: favouriteItem.type,
		order: favouriteItem.featuresOrder,
		englishName: favouriteItem.englishName,
	}
}

/**
 *
 * @param {LeftMenuResponseItem[]} leaguesMenuResponse
 * @param {number} sportId
 * @param {Object} globalConfigs
 * @returns {LeftMenuItem[]}
 */
export const createLeagueMenu = (
	leaguesMenuResponse,
	sportId,
	globalConfigs,
) => {
	let result = leaguesMenuResponse
		.map((league) => {
			return {
				id: league?.id,
				prefixName: league?.prefixName,
				name: league?.name,
				totalEvents: league?.totalEvents || 0,
				featuresOrder: league?.featuresOrder || -1,
				primaryMarketType: league?.primaryMarketType,
				hasChildList: false,
				isHighlight: league.isHighlight,
				gameCode: league?.gameCode,
				gameName: league?.gameName,
				leagueCode: league.leagueCode,
				englishName: league.englishName,
				urlPath: buildLeftMenuLeaguesURL({
					sportId,
					leagueId: league?.id,
					leagueCode: league?.leagueCode || league?.englishName,
				}),
				isLive: league?.isLive,
			}
		})
		.sort(
			(firstSport, secondSport) =>
				firstSport?.featuresOrder - secondSport?.featuresOrder,
		)

	if (sportId === SPORT_NAME.esports) {
		result = result.reduce((previousValue, currentValue) => {
			const gameName = currentValue?.gameName || currentValue?.name
			const indexGameName = previousValue.findIndex(
				(game) => game.name === gameName,
			)
			if (indexGameName >= 0) {
				previousValue[indexGameName] = {
					...previousValue[indexGameName],
					totalEvents:
						previousValue[indexGameName]?.totalEvents +
						currentValue?.totalEvents,
				}
			} else {
				previousValue.push({
					...currentValue,
					name: gameName,
					urlPath: buildLeftMenuLeaguesURL({
						sportId,
						leagueId: currentValue?.id,
						gameCode: currentValue?.gameCode || gameName,
					}),
				})
			}
			return [...previousValue]
		}, [])
		if (globalConfigs?.isB2B) {
			result = result.map((game) => ({
				...game,
				iconName: getIconGameName(game.gameCode),
			}))
		}
		return result
	}
	return result
}

/**
 *
 * @param {boolean} isActive
 * @param {number} parentId
 * @param {number} id
 * @param {string} urlPath
 * @param {number} level
 * @returns {"active"|""}
 */
export const checkLeftMenuItemActive = ({
	isActive,
	parentId,
	id,
	urlPath,
	level,
}) => {
	if (level === 1) {
		if (id.toString() === 'all-games') {
			return window.location.pathname.split('/').length === 4 ? 'active' : ''
		}
		return isActive ? 'active' : ''
	}
	const isSportMenuItem = !parentId
	if (isSportMenuItem) {
		const sportMatch = match('/' + routesStandard.sport + '+', {
			decode: decodeURIComponent,
		})
		const { params = {} } = sportMatch(window.location.pathname)
		return urlPath.includes(params?.sportCode?.[0]) ? 'active' : ''
	}
	const isAllGameESportMenuItem = parentId === SPORT_NAME.esports && id === -1
	if (isAllGameESportMenuItem) {
		const eSportMatch = match('/' + routesStandard.esportGames, {
			decode: decodeURIComponent,
		})
		const { params = {} } = eSportMatch(window.location.pathname)
		return params?.lang ? 'active' : ''
	}
	return isActive ? 'active' : ''
}

/**
 *
 * @param favouriteMenuResponse
 * @returns {{favouritePayload: *[], favouriteItem: {}, favouriteItemIds: *[]}}
 */
export const createFavouriteMenu = (
	favouriteMenuResponse = [],
	globalConfigs,
) => {
	const result = {
		favouriteItem: {},
		favouriteItemIds: [],
		favouritePayload: [],
	}
	if (favouriteMenuResponse?.length) {
		favouriteMenuResponse?.forEach((item) => {
			item = createFavouriteItem(item, globalConfigs)
			result.favouriteItem[item.id] = item
			result.favouritePayload.push(createFavouriteDbItem(item))
		})
		result.favouriteItemIds = Object.keys(result.favouriteItem)?.sort(
			(firstItem, secondItem) =>
				result.favouriteItem?.[firstItem]?.featuresOrder -
				result.favouriteItem?.[secondItem]?.featuresOrder,
		)
		result.favouritePayload.sort(
			(firstItem, secondItem) => firstItem.order - secondItem.order,
		)
	}
	return result
}

export const createFavouriteTotalEventsMap = (
	favouriteTotalEventsResponse = [],
) => {
	const result = []
	favouriteTotalEventsResponse.forEach((item) => {
		switch (item.type) {
			case FAVOURITES_TYPE.GAME:
				result.push({
					id: item.gameCode,
					totalEvents: item.eventCount,
				})
				break
			case FAVOURITES_TYPE.GAME_LEAGUE:
			case FAVOURITES_TYPE.SPORT_LEAGUE:
				result.push({
					id: item.leagueId,
					totalEvents: item.eventCount,
				})
				break
			case FAVOURITES_TYPE.PARTICIPANT:
				break
			default:
				break
		}
	})
	return result
}

export const createParamsGetFavouriteTotalEvents = (favouriteItems = []) => {
	const result = {
		leagueIds: [],
		gameCodes: [],
		participants: [],
	}

	favouriteItems.forEach((item) => {
		switch (item.type) {
			case FAVOURITES_TYPE.GAME:
				result.gameCodes.push(item.id)
				break
			case FAVOURITES_TYPE.GAME_LEAGUE:
			case FAVOURITES_TYPE.SPORT_LEAGUE:
				result.leagueIds.push(item.id)
				break
			case FAVOURITES_TYPE.PARTICIPANT:
				result.participants.push(item.id)
				break
			default:
				break
		}
	})

	return result
}

/**
 *
 * @typedef {Object} GameResponse
 * @property {string} code - game code
 * @property {string} name - game name
 * @property {string} englishName - game name at English
 * @property {number} totalEvents - current total event
 * @property {boolean} onlyFuture - flag check has outright
 */
/**
 *
 * @typedef {Object} Game
 * @property {string} id - game id
 * @property {string} gameCode - game code
 * @property {string} name - game name
 * @property {string} englishName - game name at English
 * @property {string} urlPath - url use by Link of react-router-dom
 * @property {number} totalEvents - current total event
 * @property {boolean} onlyFuture - flag check has outright
 */
/**
 *
 * @param {GameResponse[]} allGamesResponse
 * @returns {{allGamesIds: string[] | [], game: {string: Game} | {}}}
 */
export const createEsportsGamesMenu = (allGamesResponse, globalConfigs) => {
	const allGamesId = 'all-games'
	const featuredIds = globalConfigs?.esportsTopGames?.reduce(
		(acc, gameCode, index) => {
			acc[gameCode] = index + 1
			return acc
		},
		{},
	)
	const result = {
		allGamesIds: [],
		game: {
			[allGamesId]: {
				id: allGamesId,
				name: 'All games',
				totalEvents: allGamesResponse.reduce(
					(acc, game) => acc + game.totalEvents,
					0,
				),
				englishName: 'All games',
				iconName: 'icon-burger-menu',
				urlPath: buildRouteUrl(routesStandardEsportsHub.games, {}, true),
				hasChildList: false,
				featuresOrder: 0,
			},
		},
		topGamesIds: [allGamesId],
		azGamesIds: [],
	}
	if (!allGamesResponse?.length) {
		return result
	}
	allGamesResponse.forEach((game) => {
		const temp = {
			id: game?.gameCode,
			gameCode: game?.gameCode,
			name: game?.name,
			totalEvents: game?.totalEvents,
			englishName: game?.englishName,
			urlPath: buildLeftMenuLeaguesURL({
				sportId: SPORT_NAME.esports,
				gameCode: game?.gameCode,
			}),
			onlyFuture: game?.onlyFuture,
			iconName: getIconGameName(game?.gameCode),
			featuresOrder: featuredIds[game?.gameCode] || -1,
		}
		Object.assign(result.game, { [temp.id]: temp })
		if (temp.featuresOrder >= 0) {
			result.topGamesIds.push(temp.id)
		} else {
			result.azGamesIds.push(temp.id)
		}
	})
	result.topGamesIds = result.topGamesIds.sort(
		(firstGameId, secondGameId) =>
			result.game?.[firstGameId]?.featuresOrder -
			result.game?.[secondGameId]?.featuresOrder,
	)
	result.azGamesIds = result.azGamesIds.sort((firstGameId, secondGameId) => {
		return result.game?.[firstGameId]?.name?.localeCompare(
			result.game?.[secondGameId]?.name,
		)
	})
	result.allGamesIds = [...result.topGamesIds, ...result.azGamesIds]
	return result
}

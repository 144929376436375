import React, { useContext } from 'react'
import isNil from 'lodash/isNil'

const GlobalContext = React.createContext({
	configs: {},
	theme: 'default',
	language: 'en_US',
	engMessage: {},
	periods: {},
	toggleTheme: () => {},
	setPeriods: () => {},
})

export const useGlobalConfig = () => {
	const context = useContext(GlobalContext)
	if (isNil(context)) {
		throw new Error('GlobalContext is undefined')
	}

	return context
}

export default GlobalContext

export const GlobalProvider = GlobalContext.Provider

import store from 'stores/main'
import { setServiceOutage } from 'stores/slices/Layout'
import { getConfigValue } from 'services/Common'
import {
	B2B_CONFIG_KEY,
	DATA_NO_CACHE_STATUS,
	LOCAL_STORAGE,
	SESSION_MESSAGES,
} from 'constants/common'
import { postMessageToParent } from 'services/Iframe'
import { setIsLostSession, setMultiLogin } from 'stores/slices/User'
import localeInstance from 'apps/types/Locale'
import Session from 'apps/types/Session'

import {
	removeHeaderTokens,
	setHeaderTokens,
	updateHeaderXAppData,
} from '../LocalStorage'

const SPORTS_SERVICE = '/sports-service/sv/euro/left-menu'
const CASINO_SIDE_BAR = '/casino'

const validResponseUrl = (responseUrl) =>
	responseUrl &&
	(responseUrl.includes(SPORTS_SERVICE) ||
		responseUrl.includes(CASINO_SIDE_BAR))

export const onSuccessRequest = (config) => {
	const params = {
		locale: localeInstance.getLanguage() || 'en_US',
		_: new Date().getTime(),
		withCredentials: true,
	}
	const queryParams = config.url.split('?')
	if (queryParams.length > 0) {
		const searchParams = new URLSearchParams(queryParams[1])
		if (searchParams.has('locale')) {
			delete params.locale
		}
	}
	config.params = params
	return config
}

export const onSuccessResponse = (response) => {
	if (
		response?.status >= 200 &&
		response?.status < 300 &&
		validResponseUrl(response.request?.responseURL)
	) {
		if (store.getState().Layout.serviceOutage) {
			// Turn off maintain page
			store.dispatch(setServiceOutage(false))
		}
	}
	updateHeaderXAppData(response.headers?.[LOCAL_STORAGE.X_APP_DATA])
	return response
}

export const onFailureResponse = (error) => {
	if (
		(error.response.status === 404 || error.response.status >= 500) &&
		validResponseUrl(error.request?.responseURL)
	) {
		// Turn on maintain page
		store.dispatch(setServiceOutage(true))
	}
	if ([401, 403].includes(error.response?.status)) {
		if (error.response?.data?.error === SESSION_MESSAGES.MULTIPLE_LOGIN) {
			store.dispatch(setMultiLogin(true))
		} else {
			store.dispatch(setIsLostSession(true))
		}
		if (getConfigValue(B2B_CONFIG_KEY.ENABLED_POST_LOGOUT_MESSAGE, false)) {
			postMessageToParent('lostSession', true)
		}
	}
	return Promise.reject(error)
}

const parseDataNocache = (_udata) =>
	JSON.parse(_udata.replace('var __udata=', '').replaceAll(';', ''))

export const processDataNoCacheV1 = ({ dataNoCache }) => {
	const result = parseDataNocache(dataNoCache.data)
	Session.processDataNocache(result)
	return result
}

export const processDataNoCacheV2 = ({ dataNoCache, logout }) => {
	const result = dataNoCache.data?.userData || {}
	const dataNoCacheCode = dataNoCache.data?.status
	switch (dataNoCacheCode) {
		case DATA_NO_CACHE_STATUS.NEW_TOKEN: {
			setHeaderTokens(dataNoCache.data?.tokens)
			break
		}
		case DATA_NO_CACHE_STATUS.UN_AUTHENTICATED: {
			removeHeaderTokens()
			break
		}
		case DATA_NO_CACHE_STATUS.LOGOUT: {
			Session.logout()
			break
		}
		case DATA_NO_CACHE_STATUS.CONVERTED:
		default: {
			break
		}
	}
	Session.processDataNocache(result)
	return result
}

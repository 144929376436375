import { createSlice } from '@reduxjs/toolkit'
import { appIntl } from 'apps/components/LocalizedApp/IntGlobalProvider'
import { VIEW_PORT } from 'constants'
import pick from 'lodash/pick'

const initialState = {
	token: '',
	loginId: '',
	email: '',
	fullName: '',
	mode: '',
	type: '',
	betCredit: '',
	outstanding: '',
	currency: '',
	currencyUnit: 1,
	currentTierId: 1,
	message: '',
	isRefresh: false,
	locale: '',
	isLoggedIn: false,
	tncEnabled: false,
	displayMode: null,
	sumAnnouncement: 0,
	totalMessage: 0,
	defaultStake: 0,
	enabledPersonalMessage: true,
	enableMaintenance: false,
	enableQuickBet: false,
	enableQuickBetNotification: true,
	isLostSession: false,
	multiLogin: false,
	retryWebSocketNumber: 0,
	wsToken: '',
	enableEuroViewWS: false,
	liveBlockedSports: [],
}

export const User = createSlice({
	name: 'User',
	initialState,
	reducers: {
		setUser: (state, { payload }) => {
			const values = pick(payload, Object.keys(initialState)) || {}
			const currencyTranslate = appIntl()
				? appIntl().formatMessage({
						id: payload.currency,
						defaultMessage: payload.currency,
				  })
				: payload.currency
			const newState = payload.auth
				? {
						...values,
						isLoggedIn: payload.auth,
				  }
				: values
			newState.currency = currencyTranslate

			return {
				...state,
				...newState,
			}
		},
		resetSession: (state) => {
			return { ...initialState, enableMaintenance: state.enableMaintenance }
		},
		setTermAndConditions: (state, { payload }) => {
			state.tncEnabled = payload
		},
		setEnabledPersonalMessage: (state, { payload }) => {
			state.enabledPersonalMessage = payload
		},
		setEnableMaintenance: (state, { payload }) => {
			state.enableMaintenance = payload
		},
		setDefaultStake: (state, { payload }) => {
			state.defaultStake = payload
		},
		setDisplayMode: (state, { payload }) => {
			state.displayMode = payload
		},
		setIsLostSession: (state, { payload }) => {
			state.isLostSession = payload
		},
		setMultiLogin: (state, { payload }) => {
			state.multiLogin = payload
		},
		setEnableQuickBet: (state, { payload }) => {
			state.enableQuickBet = payload
		},
		setEnableQuickBetNotification: (state, { payload }) => {
			state.enableQuickBetNotification = payload
		},
		updateRetryWebSocketNumber: (state, { payload }) => {
			state.retryWebSocketNumber = payload
		},
		updateWsToken: (state, { payload }) => {
			state.wsToken = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase('Layout/setView', (state, { payload }) => {
			if (payload === VIEW_PORT.MOBILE) {
				state.enableQuickBet = false
			}
		})
	},
})

export const {
	setUser,
	resetSession,
	setTermAndConditions,
	setEnabledPersonalMessage,
	setEnableMaintenance,
	setEnableQuickBet,
	setEnableQuickBetNotification,
	setDefaultStake,
	setDisplayMode,
	setIsLostSession,
	setMultiLogin,
	updateRetryWebSocketNumber,
	updateWsToken,
} = User.actions

export default User.reducer

import { groupBy, orderBy } from 'lodash'
import { guessTimeZone } from 'apps/types/Date'
import Session from 'apps/types/Session'

export const parseMatchUpResponse = (response, isLeague = false) => {
	if (response) {
		const { timeZoneId } = Session.getPreferences()
		const _timezone = timeZoneId || guessTimeZone
		const dateList = []
		let remainingEvent = isLeague ? 99999 : 20
		const { leagues = [], refreshAll, update = [] } = response
		let arrayEvent = []
		// let arrayEventUpdate = []
		leagues.forEach((lg) => {
			const { events } = lg
			const eventMapDate = events.map((ev) => {
				return {
					...ev,
					date: new Intl.DateTimeFormat('en', {
						dateStyle: 'short',
						timeZone: _timezone,
					}).format(ev.time),
					leagueId: lg.id,
					isRefresh: true,
				}
			})
			arrayEvent.push(...eventMapDate)
		})
		update.forEach((upd) => {
			const { events } = upd
			const eventMapDate = events.map((ev) => {
				return {
					...ev,
					date: new Intl.DateTimeFormat('en', {
						dateStyle: 'short',
						timeZone: _timezone,
					}).format(ev.time),
					leagueId: upd.id,
					isUpdate: true,
				}
			})
			arrayEvent.push(...eventMapDate)
		})

		const groupByDate = groupBy(arrayEvent, 'date')
		const leaguesByDate = []

		const today = new Date().toLocaleString('en', {
			timeZone: _timezone,
			dateStyle: 'short',
		})

		const tomorrow = new Date(Date.now() + 1000 * 3600 * 24).toLocaleString(
			'en',
			{
				timeZone: _timezone,
				dateStyle: 'short',
			},
		)
		const arrayProp = Object.keys(groupByDate)
		arrayProp.sort()
		const arrayOfGroupByDate = arrayProp.map((keyDate) => {
			return {
				date: keyDate,
				listLeague: groupByDate[keyDate] ? groupByDate[keyDate] : [],
				time: groupByDate[keyDate] ? groupByDate[keyDate][0].time : 0,
			}
		})
		arrayOfGroupByDate.sort((a, b) => a.time - b.time)
		arrayOfGroupByDate.forEach((dateGroup) => {
			const { date, listLeague } = dateGroup
			let newLeagueArray = []
			let newUpdateArray = []
			let leagueTime = new Date(date).getTime()

			const groupByLeagueId = groupBy(listLeague, 'leagueId')
			Object.entries(groupByLeagueId).forEach((league) => {
				const [leagueId, leagueValue] = league
				const isToday = `${date}` === `${today}`
				const findLeague = leagues.find((le) => `${le.id}` === `${leagueId}`)
				const findUpdateLeague = update.find(
					(le) => `${le.id}` === `${leagueId}`,
				)
				let arrayEventRefresh = []
				const arrayRefresh = leagueValue.filter((lg) => lg.isRefresh)
				if (isToday) {
					arrayEventRefresh = arrayRefresh
					remainingEvent = remainingEvent - arrayEventRefresh.length
				} else {
					if (remainingEvent > 0) {
						if (arrayRefresh.length >= remainingEvent) {
							arrayEventRefresh = arrayRefresh.slice(0, 0 + remainingEvent)
						} else {
							arrayEventRefresh = arrayRefresh
						}
						remainingEvent = remainingEvent - arrayRefresh.length
					}
				}

				const arrayEventUpdate = leagueValue.filter((lg) => lg.isUpdate)
				const newLeague = {
					...findLeague,
					events: arrayEventRefresh,
				}
				const updateLeague = {
					...findUpdateLeague,
					events: arrayEventUpdate,
				}
				if (arrayEventRefresh.length > 0) {
					newLeagueArray = [...newLeagueArray, newLeague]
				}

				if (arrayEventUpdate.length > 0) {
					newUpdateArray = [...newUpdateArray, updateLeague]
				}
			})
			const sortedNewLeagueArray = orderBy(newLeagueArray, 'name', 'asc')
			const resultLeagueByDate = {
				eventCount: listLeague.length,
				dateTime: date,
				time: date,
				isToday: `${date}` === `${today}`,
				isTomorrow: `${date}` === `${tomorrow}`,
				leagues: sortedNewLeagueArray,
				leagueTime,
				refreshAll,
				update: newUpdateArray,
				header: '',
			}
			if (leagueTime) {
				const dateListObj = {
					dateStore: date,
					time: leagueTime,
				}
				dateList.push(dateListObj)
			}
			if (
				resultLeagueByDate.leagues?.length > 0 ||
				resultLeagueByDate.update?.length > 0
			) {
				leaguesByDate.push(resultLeagueByDate)
			}
		})

		const returnResponse = { ...response, leaguesByDate, dateList }
		return returnResponse || []
	}
}

export const parseMatchUpResponseTeamTotal = (response) => {
	if (response) {
		const { timeZoneId } = Session.getPreferences()
		const _timezone = timeZoneId || guessTimeZone
		const dateList = []
		const { leagues = [], refreshAll, update = [] } = response
		let validTeamTotalPeriod = []
		let arrayEvent = []
		let arrayEventUpdate = []
		leagues.forEach((lg) => {
			const { events } = lg
			const eventMapDate = events.map((ev) => {
				return {
					...ev,
					date: new Intl.DateTimeFormat('en', {
						dateStyle: 'short',
						timeZone: _timezone,
					}).format(ev.time),
					leagueId: lg.id,
					isRefresh: true,
				}
			})
			arrayEvent.push(...eventMapDate)
		})
		update.forEach((upd) => {
			const { events } = upd
			const eventMapDate = events.map((ev) => {
				return {
					...ev,
					date: new Intl.DateTimeFormat('en', {
						dateStyle: 'short',
						timeZone: _timezone,
					}).format(ev.time),
					leagueId: upd.id,
					isUpdate: true,
				}
			})
			arrayEventUpdate.push(...eventMapDate)
		})
		if (refreshAll) {
			arrayEvent.forEach((ev) => {
				const { periods } = ev
				Object.entries(periods).forEach((periodEntry) => {
					const [key] = periodEntry
					if (!validTeamTotalPeriod.includes(key)) {
						validTeamTotalPeriod.push(key)
					}
				})
			})
			if (validTeamTotalPeriod.length > 3) {
				validTeamTotalPeriod = validTeamTotalPeriod.slice(0, 3)
			}
		}

		const groupByDate = groupBy(arrayEvent, 'date')
		const groupByDateUpdate = groupBy(arrayEventUpdate, 'date')
		const leaguesByDate = []
		const leaguesTotalByDateUpdate = []

		const today = new Date().toLocaleString('en', {
			timeZone: _timezone,
			dateStyle: 'short',
		})

		const tomorrow = new Date(Date.now() + 1000 * 3600 * 24).toLocaleString(
			'en',
			{
				timeZone: _timezone,
				dateStyle: 'short',
			},
		)
		Object.entries(groupByDate).forEach((dateGroup) => {
			const [date, listLeague] = dateGroup
			let newLeagueArray = []
			let leagueTime = new Date(date).getTime()

			const groupByLeagueId = groupBy(listLeague, 'leagueId')
			Object.entries(groupByLeagueId).forEach((league) => {
				const [leagueId, leagueValue] = league
				const findLeague = leagues.find((le) => `${le.id}` === `${leagueId}`)

				let arrayEventRefresh = []
				const arrayRefresh = leagueValue.filter((lg) => lg.isRefresh)
				arrayEventRefresh = arrayRefresh
				const newLeague = {
					...findLeague,
					events: arrayEventRefresh,
				}
				if (arrayEventRefresh.length > 0) {
					newLeagueArray = [...newLeagueArray, newLeague]
				}
			})

			const resultLeagueByDate = {
				eventCount: listLeague.length,
				dateTime: date,
				time: date,
				isToday: `${date}` === `${today}`,
				isTomorrow: `${date}` === `${tomorrow}`,
				leagues: newLeagueArray,
				leagueTime,
				refreshAll,
				header: '',
			}
			if (leagueTime) {
				const dateListObj = {
					dateStore: date,
					time: leagueTime,
				}
				dateList.push(dateListObj)
			}
			if (resultLeagueByDate.leagues.length > 0) {
				leaguesByDate.push(resultLeagueByDate)
			}
		})

		Object.entries(groupByDateUpdate).forEach((dateGroup) => {
			const [date, listLeague] = dateGroup
			let newUpdateArray = []
			let leagueTime = new Date(date).getTime()

			const groupByLeagueId = groupBy(listLeague, 'leagueId')
			Object.entries(groupByLeagueId).forEach((league) => {
				const [leagueId, leagueValue] = league
				const findUpdateLeague = update.find(
					(le) => `${le.id}` === `${leagueId}`,
				)

				const arrayEventUpdate = leagueValue.filter((lg) => lg.isUpdate)

				const updateLeague = {
					...findUpdateLeague,
					events: arrayEventUpdate,
				}

				if (arrayEventUpdate.length > 0) {
					newUpdateArray = [...newUpdateArray, updateLeague]
				}
			})

			const resultLeagueByDateUpdate = {
				eventCount: listLeague.length,
				dateTime: date,
				time: date,
				isToday: `${date}` === `${today}`,
				isTomorrow: `${date}` === `${tomorrow}`,
				leagues: newUpdateArray,
				leagueTime,
				refreshAll: false,
				header: '',
			}
			if (resultLeagueByDateUpdate.leagues.length > 0) {
				leaguesTotalByDateUpdate.push(resultLeagueByDateUpdate)
			}
		})

		const returnResponse = {
			...response,
			leaguesByDateTotal: leaguesByDate,
			leaguesByDateTotalUpdate: leaguesTotalByDateUpdate,
			leaguesByDate: [],
			dateList,
			validTeamTotalPeriod,
		}
		return returnResponse || []
	}
}

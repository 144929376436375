import { LOCAL_STORAGE } from '../../constants'
import { removeCookie, setCookie } from '../Cookie'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { keepAliveSessionForB2BSettingPage } from '../PopupWindow'

export const getOddsDelayWarningViewed = () => {
	return localStorage.getItem(LOCAL_STORAGE.ODD_DELAY_WARNING_VIEWED) || false
}

export const setOddsDelayWarningViewed = (isViewed) => {
	localStorage.setItem(LOCAL_STORAGE.ODD_DELAY_WARNING_VIEWED, isViewed)
}

export const getDynamicParameter = () => {
	const DPKey = localStorage.getItem(LOCAL_STORAGE.DYNAMIC_PARAMETER_KEY)
	const DPValue = localStorage.getItem(LOCAL_STORAGE.DYNAMIC_PARAMETER_VALUE)
	return { DPKey, DPValue }
}

export const headerToObject = (header = '') => {
	const result = {}
	const headers = header.split(';')
	for (const header of headers) {
		const [key, value] = header.split('=')
		if (key && value) {
			result[key] = value
		}
	}
	return result
}

const objectToHeader = (object = {}) => {
	const result = []
	for (const key in object) {
		result.push(`${key}=${object[key]}`)
	}
	return result.join(';')
}

export const setDynamicParameter = ({ DPKey, DPValue }) => {
	try {
		if (DPKey && DPValue) {
			if (window.env?.headerBasedEnabled) {
				const XAppData = headerToObject(getHeaderXAppData())
				const { DPKey: oldDPKey } = getDynamicParameter()
				delete XAppData[oldDPKey]
				XAppData[DPKey] = DPValue
				setHeaderXAppData(objectToHeader(XAppData))
			} else {
				removeCookie(DPKey)
				setCookie(DPKey, DPValue)
			}
			localStorage.setItem(LOCAL_STORAGE.DYNAMIC_PARAMETER_KEY, DPKey)
			localStorage.setItem(LOCAL_STORAGE.DYNAMIC_PARAMETER_VALUE, DPValue)
		}
	} catch (error) {
		console.error(error)
	}
}

export const getHeaderTokens = () => {
	let result = {}
	if (localStorage.getItem(LOCAL_STORAGE.TOKEN)) {
		result = JSON.parse(localStorage.getItem(LOCAL_STORAGE.TOKEN))
	}
	return result
}

export const setHeaderTokens = (tokens, isLoginByUrl) => {
	try {
		if (isEmpty(tokens)) {
			return
		}
		if (window.env?.headerBasedEnabled || isLoginByUrl) {
			const finalTokens = {}
			for (const tokenKey in tokens) {
				finalTokens[tokenKey] = tokens[tokenKey]
			}
			localStorage.setItem(LOCAL_STORAGE.TOKEN, JSON.stringify(finalTokens))
		}
	} catch (error) {
		console.error(error)
	}
}

export const removeHeaderTokens = () => {
	if (localStorage.getItem(LOCAL_STORAGE.TOKEN)) {
		const tokens = JSON.parse(localStorage.getItem(LOCAL_STORAGE.TOKEN))
		for (let tokenKey in tokens) {
			delete axios.defaults.headers.common[tokenKey]
		}
		localStorage.removeItem(LOCAL_STORAGE.TOKEN)
	}
}

export const getHeaderXAppData = () => {
	let result = ''
	if (localStorage.getItem(LOCAL_STORAGE.X_APP_DATA)) {
		result = objectToHeader(
			JSON.parse(localStorage.getItem(LOCAL_STORAGE.X_APP_DATA)),
		)
	}
	return result
}

export const setHeaderXAppData = (XAppData, isLoginByUrl = false) => {
	try {
		if (!XAppData) {
			return
		}
		if (window.env?.headerBasedEnabled || isLoginByUrl) {
			const temp = XAppData.replace(/\w+=;|;\w+=$/g, '')
			localStorage.setItem(
				LOCAL_STORAGE.X_APP_DATA,
				JSON.stringify(headerToObject(temp)),
			)
		}
	} catch (error) {
		console.error(error)
	}
}

export const updateHeaderTokens = (tokens) => {
	try {
		if (window.env?.headerBasedEnabled && tokens) {
			const tokensNew = {
				...getHeaderTokens(),
				...tokens,
			}
			setHeaderTokens(tokensNew)
			keepAliveSessionForB2BSettingPage({ tokens })
		}
	} catch (error) {
		console.error(error)
	}
}

export const updateHeaderXAppData = (XAppData) => {
	try {
		if (window.env?.headerBasedEnabled && XAppData) {
			const XAppDataNew = {
				...headerToObject(getHeaderXAppData()),
				...headerToObject(XAppData),
			}
			axios.defaults.headers.common[LOCAL_STORAGE.X_APP_DATA] =
				objectToHeader(XAppDataNew)
			localStorage.setItem(
				LOCAL_STORAGE.X_APP_DATA,
				JSON.stringify(XAppDataNew),
			)
			keepAliveSessionForB2BSettingPage({ XAppData })
		}
	} catch (error) {
		console.error(error)
	}
}

export const removeHeaderXAppData = () => {
	const { DPKey, DPValue } = getDynamicParameter()
	if (DPKey && DPValue) {
		const XAppData = `${DPKey}=${DPValue}`
		axios.defaults.headers.common[LOCAL_STORAGE.X_APP_DATA] = XAppData
		setHeaderXAppData(XAppData)
	} else {
		delete axios.defaults.headers.common[LOCAL_STORAGE.X_APP_DATA]
		localStorage.removeItem(LOCAL_STORAGE.X_APP_DATA)
	}
}

export const removeJWTHeaderRequestOnly = () => {
	const tokens = JSON.parse(localStorage.getItem(LOCAL_STORAGE.TOKEN))
	for (let tokenKey in tokens) {
		delete axios.defaults.headers.common[tokenKey]
	}
	const { DPKey, DPValue } = getDynamicParameter()
	if (DPKey && DPValue) {
		const XAppData = `${DPKey}=${DPValue}`
		axios.defaults.headers.common[LOCAL_STORAGE.X_APP_DATA] = XAppData
	} else {
		delete axios.defaults.headers.common[LOCAL_STORAGE.X_APP_DATA]
	}
}

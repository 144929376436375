/**
 * Represents a language configuration.
 * @typedef {Object} Language
 * @property {string} name - The name displayed to users.
 * @property {string} abbr - The abbreviated name.
 * @property {string} culture - The primary key for the language.
 * @property {Object} urlPrefix - The URL prefixes for different purposes.
 * @property {string} urlPrefix.default - The default prefix in local URIs (e.g., /en/soccer).
 * @property {string} urlPrefix.bettingRules - The prefix for betting rules URIs (e.g., /en-US/betting-rules).
 * @property {string} filename - The filename for language-specific messages (e.g., en.json).
 */

/**
 * Array of language configurations.
 * @type {Language[]}
 */

export const languages = [
	{
		name: 'Deutsch',
		abbr: 'DE',
		culture: 'de_DE',
		urlPrefix: {
			default: 'de',
			bettingRules: 'de-DE',
		},
		filename: 'de.json',
	},
	{
		name: 'English',
		abbr: 'EN',
		culture: 'en_US',
		urlPrefix: {
			default: 'en',
			bettingRules: 'en-US',
			pinnacleSolution: 'en',
		},
		filename: 'en.json',
	},
	{
		name: 'Español',
		abbr: 'ES',
		culture: 'es_ES',
		urlPrefix: {
			default: 'es',
			bettingRules: 'es-ES',
			pinnacleSolution: 'es',
		},
		filename: 'es.json',
	},
	{
		name: 'Soumi',
		abbr: 'FI',
		culture: 'fi_FI',
		urlPrefix: {
			default: 'fi',
			bettingRules: 'fi-FI',
		},
		filename: 'fi.json',
	},
	{
		name: 'Français',
		abbr: 'FR',
		culture: 'fr_FR',
		urlPrefix: {
			default: 'fr',
			bettingRules: 'fr-FR',
		},
		filename: 'fr.json',
	},
	{
		name: 'עברית',
		abbr: 'HE',
		culture: 'he_IL',
		urlPrefix: {
			default: 'he',
			bettingRules: 'he-IL',
		},
		filename: 'he.json',
	},
	{
		name: 'Armenian',
		abbr: 'HY',
		culture: 'hy_AM',
		urlPrefix: {
			default: 'hy',
			bettingRules: 'hy-AM',
		},
		filename: 'hy.json',
	},
	{
		name: 'Indonesian',
		abbr: 'ID',
		culture: 'id_ID',
		urlPrefix: {
			default: 'id',
			bettingRules: 'id-ID',
		},
		filename: 'id.json',
	},
	{
		name: 'Italiano',
		abbr: 'IT',
		culture: 'it_IT',
		urlPrefix: {
			default: 'it',
			bettingRules: 'it-IT',
		},
		filename: 'it.json',
	},
	{
		name: '日本語',
		abbr: 'JA',
		culture: 'ja_JP',
		urlPrefix: {
			default: 'ja',
			bettingRules: 'ja-JP',
		},
		filename: 'ja.json',
	},
	{
		name: 'हिंदी',
		abbr: 'HI',
		culture: 'hi_IN',
		urlPrefix: {
			default: 'hi',
			bettingRules: 'hi-in',
		},
		filename: 'in.json',
	},
	{
		name: 'Georgian',
		abbr: 'KA',
		culture: 'ka_GE',
		urlPrefix: {
			default: 'ka',
			bettingRules: 'ka-GE',
		},
		filename: 'ka.json',
	},
	{
		name: 'ភាសាខ្មែរ',
		abbr: 'KM',
		culture: 'km_KH',
		urlPrefix: {
			default: 'km',
			bettingRules: 'km-KH',
		},
		filename: 'km.json',
	},
	{
		name: '한국어',
		abbr: 'KO',
		culture: 'ko_KR',
		urlPrefix: {
			default: 'ko',
			bettingRules: 'ko-KR',
			pinnacleSolution: 'ko',
		},
		filename: 'ko.json',
	},
	{
		name: 'Norsk',
		abbr: 'NO',
		culture: 'nb_NO',
		urlPrefix: {
			default: 'no',
			bettingRules: 'nb-NO',
		},
		filename: 'no.json',
	},
	{
		name: 'Portuguese',
		abbr: 'PT',
		culture: 'pt_BR',
		urlPrefix: {
			default: 'pt',
			bettingRules: 'pt-BR',
		},
		filename: 'pt.json',
	},
	{
		name: 'Русский',
		abbr: 'RU',
		culture: 'ru_RU',
		urlPrefix: {
			default: 'ru',
			bettingRules: 'ru-RU',
			pinnacleSolution: 'ru',
		},
		filename: 'ru.json',
	},
	{
		name: 'Svenska',
		abbr: 'SV',
		culture: 'sv_SE',
		urlPrefix: {
			default: 'sv',
			bettingRules: 'sv-SE',
		},
		filename: 'sv.json',
	},
	{
		name: 'ภาษา ไทย',
		abbr: 'TH',
		culture: 'th_TH',
		urlPrefix: {
			default: 'th',
			bettingRules: 'th-TH',
			pinnacleSolution: 'th',
		},
		filename: 'th.json',
	},
	{
		name: 'Türkçe',
		abbr: 'TR',
		culture: 'tr_TR',
		urlPrefix: {
			default: 'tr',
			bettingRules: 'tr-TR',
		},
		filename: 'tr.json',
	},
	{
		name: 'Ukraine',
		abbr: 'UK',
		culture: 'uk_UA',
		urlPrefix: {
			default: 'uk',
			bettingRules: 'uk-UA',
		},
		filename: 'uk.json',
	},
	{
		name: 'Tiếng Việt',
		abbr: 'VI',
		culture: 'vi_VN',
		urlPrefix: {
			default: 'vi',
			bettingRules: 'vi-VN',
		},
		filename: 'vi.json',
	},
	{
		name: '简体中文',
		abbr: 'ZH',
		culture: 'zh_CN',
		urlPrefix: {
			default: 'zh-cn',
			bettingRules: 'zh-cn',
			pinnacleSolution: 'zh-CN',
		},
		filename: 'zh-cn.json',
	},
	{
		name: '繁體中文',
		abbr: 'ZH TW',
		culture: 'zh_TW',
		urlPrefix: {
			default: 'zh-tw',
			bettingRules: 'zh-tw',
			pinnacleSolution: 'zh-CN',
		},
		filename: 'zh-tw.json',
	},
]

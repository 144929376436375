import { renderGameIcon } from 'apps/components/OddPage/oddPageUlti'

const unAvailableButton = `<button
class="market-btn styleButton stylePill styleVertical styleDisabled" tooltip="<%= data.translate?.unavailable   %>"
>
<i class="icon-unavailable-2 styleUnavailable"></i>

</button>`

export const renderOfflineButton = (idOffline) => {
	return `<button
  id="<%= 't'+${idOffline}  %>"
  tooltip="<%= data.translate?.currentlyOffline   %>"
  class="market-btn styleButton stylePill styleVertical styleDisabled" 
  >
  <i class="icon icon-lock styleUnavailable offlineButton"></i>
  </button>`
}

const handicapHome = `<div class="styleButtonWrapper tooltip-btn"  >
<% 

const handicapHomeId = eventId+'|'+periodToDisplay+'|2|0|'+handicap[indexHdpToShow]?.homeSpread;
const hdHomeIdToSet = eventId+'|'+periodToDisplay+'|2|0|'+isAltLine+'|'+handicap[indexHdpToShow]?.homeSpread+'|'+ (homeTeamType);
if(handicap[indexHdpToShow]?.unavailable || handicap[indexHdpToShow]?.offline){ %>
    <% if(handicap[indexHdpToShow]?.unavailable) { %>
        ${unAvailableButton}
     <% } %>   
     <% if(handicap[indexHdpToShow]?.offline && !handicap[indexHdpToShow]?.unavailable) { %>
        ${renderOfflineButton('hdHomeIdToSet')}
     <% } %> 

  <%  } else { 
    
     
    %>
    <button
    title="<%= handicap[indexHdpToShow]?.homeSpread %>"
    aria-label="Handicap home"
    onclick="<%= data.eventClickFunction %>(event,'<%= hdHomeIdToSet %>','<%= leagueId%>', '<%= eventId%>', '<%= handicap[indexHdpToShow]?.homeOdds %>')"
    lineId="<%= handicap[indexHdpToShow]?.lineId  %>"
    id="<%= 't'+hdHomeIdToSet %>"
    class="market-btn styleButton stylePill <%= selectedOddsWithoutAlt.includes(handicapHomeId) || handicapHomeId === quickBet ?"styleSelected":""%> styleVertical"
    >
      <span class="styleLabelOdd"><%= handicap[indexHdpToShow]?.homeSpread %></span>
      <span class="stylePrice"><%= handicap[indexHdpToShow]?.homeOdds %></span>
    </button>
    <%  }  %>
 
</div>`

const handicapAway = `<div class="styleButtonWrapper tooltip-btn" >
<%  
const handicapAwayId = eventId+'|'+periodToDisplay+'|2|1|'+handicap[indexHdpToShow]?.awaySpread;
const hdAwayIdToSet = eventId+'|'+periodToDisplay+'|2|1|'+isAltLine+'|'+handicap[indexHdpToShow]?.awaySpread+'|'+ (awayTeamType);
if(handicap[indexHdpToShow]?.unavailable || handicap[indexHdpToShow]?.offline){ 
  
  %>
    <% if(handicap[indexHdpToShow]?.unavailable) { %>
        ${unAvailableButton}
     <% } %>   
     <% if(handicap[indexHdpToShow]?.offline && !handicap[indexHdpToShow]?.unavailable) { %>
      ${renderOfflineButton('hdAwayIdToSet')}
     <% } %> 
  <% } else {
    
    %>
    <button
    aria-label="Handicap away"
    onclick="<%= data.eventClickFunction %>(event,'<%= hdAwayIdToSet %>', '<%= leagueId%>', '<%= eventId%>', '<%= handicap[indexHdpToShow]?.awayOdds %>')"
    title="<%= handicap[indexHdpToShow]?.awaySpread %>"
    lineId="<%= handicap[indexHdpToShow]?.lineId  %>"
    id="<%= 't'+hdAwayIdToSet %>"
    class="market-btn styleButton stylePill <%= selectedOddsWithoutAlt.includes(handicapAwayId) || handicapAwayId === quickBet ?"styleSelected":""%> styleVertical"
    >
    <span class="styleLabelOdd"><%= handicap[indexHdpToShow]?.awaySpread %></span>
    <span class="stylePrice"><%= handicap[indexHdpToShow]?.awayOdds %></span>
    </button>
    <% } %>
   
 
</div>`

const mlHome = `<div class="styleButtonWrapper tooltip-btn" >
<%
const mlHomeId = eventId+'|'+periodToDisplay+'|1|0|0|0';     
const mlHomeIdToSet = eventId+'|'+periodToDisplay+'|1|0|0|0|'+homeTeamType;
const mlHomeIdQuickBet = eventId+'|'+periodToDisplay+'|1|0|0'; 
    if(moneyLine.unavailable || moneyLine.offline) {
    %>
        <% if(moneyLine.unavailable) { %>
            ${unAvailableButton}
        <% } %>   
        <% if(moneyLine.offline && !moneyLine.unavailable) { %>
          ${renderOfflineButton('mlHomeIdToSet')}
        <% } %> 
    <% 
      } else {
            
    %>
    <button
    aria-label="Money Line"
    title="<%= moneyLine.homePrice %>"
    lineId="<%= moneyLine?.lineId  %>"
    id="<%= 't'+mlHomeIdToSet %>"
    class="market-btn styleButton stylePill <%= data.selectedOddIds.includes(mlHomeId) || mlHomeIdQuickBet === quickBet ?"styleSelected":""%> styleVertical  "
    onclick="<%= data.eventClickFunction %>(event,'<%= mlHomeIdToSet %>', '<%= leagueId%>', '<%= eventId%>','<%= moneyLine.homePrice %>')"
    >
        <span class="stylePrice"><%= moneyLine.homePrice %></span>
    </button>
    <% 
      }
    %>
 
</div>`

const mlAway = `<div class="styleButtonWrapper tooltip-btn">

<%
const mlAwayId = eventId+'|'+periodToDisplay+'|1|1|0|0'; 
const mlAwayIdToSet = eventId+'|'+periodToDisplay+'|1|1|0|0|'+awayTeamType;
const mlAwayIdQuickBet = eventId+'|'+periodToDisplay+'|1|1|0';
if(moneyLine?.unavailable || moneyLine?.offline) {
%>
  <% if(moneyLine.unavailable) { %>
    ${unAvailableButton}
  <% } %>   
  <% if(moneyLine.offline && !moneyLine.unavailable) { %>
    ${renderOfflineButton('mlAwayIdToSet')}
  <% } %> 
<% 
  } else {     
    
%>
<button
aria-label="Money Line 267"
title="<%= moneyLine.awayPrice %>"
id="<%= 't'+mlAwayIdToSet %>"
lineId="<%= moneyLine?.lineId   %>"
class="market-btn styleButton stylePill <%= data.selectedOddIds.includes(mlAwayId) || mlAwayIdQuickBet === quickBet ? "styleSelected": ""%> styleVertical"
onclick="<%= data.eventClickFunction %>(event,'<%= mlAwayIdToSet %>', '<%= leagueId%>', '<%= eventId%>','<%= moneyLine.awayPrice %>')"
>
    <span class="stylePrice"><%= moneyLine.awayPrice %></span>
</button>
<% 
  }
%>
   
</div>`

const mlDraw = `<div class="styleButtonWrapper tooltip-btn" >

<%
const mlDrawId = eventId+'|'+periodToDisplay+'|1|2|0|0'; 
const mlDrawIdToSet = eventId+'|'+periodToDisplay+'|1|2|0|0|2';
const mlDrawIdQuickBet =  eventId+'|'+periodToDisplay+'|1|2|0';
if(moneyLine?.unavailable || moneyLine.offline || !moneyLine.drawPrice) {
%>
  <% if(moneyLine.unavailable) { %>
    ${unAvailableButton}
  <% } %>   
  <% if((moneyLine.offline || !moneyLine.drawPrice) && !moneyLine.unavailable) { %>
    ${renderOfflineButton('mlDrawIdToSet')}
    
  <% } %> 
<% 
  } else {
    
%>
<button
aria-label="Money Line 267"
title="<%= moneyLine.drawPrice %>"
lineId="<%= moneyLine?.lineId %>"
id="<%= 't'+mlDrawIdToSet %>"
class="market-btn styleButton stylePill <%= data.selectedOddIds.includes(mlDrawId) || mlDrawIdQuickBet === quickBet ?"styleSelected":""%> styleVertical  "
onclick="<%= data.eventClickFunction %>(event,'<%= mlDrawIdToSet %>', '<%= leagueId%>', '<%= eventId%>', '<%= moneyLine.drawPrice %>')"
>
    <span class="stylePrice"><%= moneyLine.drawPrice %></span>
</button>
<% 
  }
%>
 
</div>`

const handicapColumn = `<% if(isShowHandicap) { %> <div class="styleButtons">
<%
let indexHdpToShow = handicap.findIndex( (hd,indexHd) => {
  const isAlt = hd.isAlt? '1':'0';
  const hdHomeId = eventId+'|'+periodToDisplay+'|2|0|'+hd?.homeSpread;
  const hdAwayId = eventId+'|'+periodToDisplay+'|2|1|'+hd?.awaySpread;
  return selectedOddsWithoutAlt.includes(hdAwayId) || selectedOddsWithoutAlt.includes(hdHomeId);
});
const indexMainLineHdp = handicap.findIndex(hd=>!hd.isAlt)
indexHdpToShow = indexHdpToShow === -1 ? indexMainLineHdp : indexHdpToShow;
const isAltLine = indexHdpToShow === indexMainLineHdp ? '0' : '1';
%>
<% if(isShowHomeFirst) {   %>
  ${handicapHome}
  ${handicapAway}
  
<% } else {  %>
  ${handicapAway}
  ${handicapHome}
 
  <% } %>
  <% if(handicap?.length > 1 && !handicap[indexHdpToShow]?.unavailable && !handicap[indexHdpToShow]?.offline) { 
    const alternateHdId =   'handicap' + eventId + 'p' + periodToDisplay;
    %>
    <div class="styleAlternates" id="<%= alternateHdId  %>" onclick="<%= data.alternateClickFunction %>(this,<%=indexLeague   %>,<%=indexEvent   %>,'handicap',<%= periodToDisplay  %>, '<%= alternateHdId  %>')">
      <a class="styleExpandBtn"
      ><i class="icon-expand styleExpandBtnIcon"></i></a>
    </div>
    <%} else { %>
      <div class="styleAlternates" >
      </div>
      <% } %>

</div> <%  } %>`

const overUnderColumn = `<% if(isShowOverUnder) { %> <div class="styleButtons">
<div class="styleButtonWrapper tooltip-btn">
    <%
    let indexOUToShow = overUnder.findIndex( (ou,indexOu) => {
      const isAltOU = ou.isAlt? '1':'0';
      const ouHomeId = eventId+'|'+periodToDisplay+'|3|3|'+ou?.points;
      const ouAwayId = eventId+'|'+periodToDisplay+'|3|4|'+ou?.points;
      return selectedOddsWithoutAlt.includes(ouHomeId) || selectedOddsWithoutAlt.includes(ouAwayId);
    });
    const indexMainLineOU = overUnder.findIndex(ou=>!ou.isAlt)
    indexOUToShow = indexOUToShow === -1 ? indexMainLineOU : indexOUToShow;
    const isAltLineOU = indexOUToShow === indexMainLineOU ? '0' : '1';
    const overUnderHomeId = eventId+'|'+periodToDisplay+'|3|3|'+overUnder[indexOUToShow]?.points;
    const ouHomeIdToSet = eventId+'|'+periodToDisplay+'|3|3|'+isAltLineOU+'|'+overUnder[indexOUToShow]?.points+'|'+ (homeTeamType);
    if(overUnder[indexOUToShow]?.unavailable || overUnder[indexOUToShow]?.offline) {
    %>
    <% 
  
    if(overUnder[indexOUToShow]?.unavailable) { 
      
      %>
        ${unAvailableButton}
     <% } %>   
     <% if(overUnder[indexOUToShow]?.offline && !overUnder[indexOUToShow]?.unavailable) { %>
      ${renderOfflineButton('ouHomeIdToSet')}
     <% } %> 
    <% 
      } else {        
        
    %>
      <button
        title="<%= overUnder[indexOUToShow]?.points %>"
        aria-label="Under Over"
        id="<%= 't'+ouHomeIdToSet %>"
        lineId="<%= overUnder[indexOUToShow]?.lineId   %>"
        onclick="<%= data.eventClickFunction %>(event,'<%= ouHomeIdToSet %>', '<%= leagueId%>', '<%= eventId%>', '<%= overUnder[indexOUToShow]?.overOdds %>')"
        class="market-btn styleButton stylePill <%= selectedOddsWithoutAlt.includes(overUnderHomeId) || overUnderHomeId === quickBet ?"styleSelected":""%> styleVertical"
      >
        <span class="styleLabelOdd"><%= overUnder[indexOUToShow]?.points %></span>
        <span class="stylePrice"><%= overUnder[indexOUToShow]?.overOdds %></span>
      </button>
    <% 
      }
    %>
</div>
<div class="styleButtonWrapper tooltip-btn">
<%
const overUnderAwayId = eventId+'|'+periodToDisplay+'|3|4|'+overUnder[indexOUToShow]?.points;
const ouAwayIdToSet = eventId+'|'+periodToDisplay+'|3|4|'+isAltLineOU+'|'+overUnder[indexOUToShow]?.points+'|'+ (awayTeamType);

    if(overUnder[indexOUToShow]?.unavailable || overUnder[indexOUToShow]?.offline) {
%>
    <% if(overUnder[indexOUToShow]?.unavailable) { %>
        ${unAvailableButton}
    <% } %>   
    <% if(overUnder[indexOUToShow]?.offline && !overUnder[indexOUToShow]?.unavailable) { %>
      ${renderOfflineButton('ouAwayIdToSet')}
    <% } %> 
<% 
    } else {        
      
%>
    <button
    title="<%= overUnder[indexOUToShow]?.points %>"
    aria-label="Under Over"
    id="<%= 't'+ouAwayIdToSet %>"
    lineId="<%= overUnder[indexOUToShow]?.lineId  %>"
    onclick="<%= data.eventClickFunction %>(event,'<%= ouAwayIdToSet %>', '<%= leagueId%>', '<%= eventId%>','<%= overUnder[indexOUToShow]?.underOdds %>')"
    class="market-btn styleButton stylePill  <%= selectedOddsWithoutAlt.includes(overUnderAwayId) || overUnderAwayId === quickBet ?"styleSelected":""%> styleVertical"
    >
        <span class="styleLabelOdd"><%= overUnder[indexOUToShow]?.points %></span>
        <span class="stylePrice"><%= overUnder[indexOUToShow]?.underOdds %></span>
    </button>
<% 
    }
%>
  
</div>
<% if(overUnder?.length > 1 && !overUnder[indexOUToShow]?.unavailable && !overUnder[indexOUToShow]?.offline) { 
  const alternateOuId =  'overUnder' + eventId + 'p' + periodToDisplay;
  %>
  <div class="styleAlternates" id="<%= alternateOuId %>" onclick="<%= data.alternateClickFunction %>(this,<%=indexLeague   %>,<%=indexEvent   %>,'overUnder', <%= periodToDisplay  %>, '<%= alternateOuId %>')">
    <a class="styleExpandBtn"
    ><i class="icon-expand styleExpandBtnIcon"></i></a>
  </div>
  <%} else { %>
    <div class="styleAlternates" >
    </div>
    <% } %>

</div> <%   } %>`

const mlColumn = `<% if(isShowML){ %><div class="styleButtons styleMoneyline  <%= data.ml3Line || renderThreeLine || renderThreeLineEsport ? 'styleThreeButtons':''  %>">

<% if(data.ml3Line || renderThreeLine || renderThreeLineEsport){ 
  if(isShowHomeFirst) {
  %>
  ${mlHome}
  ${mlDraw}
  ${mlAway}
  <% } else { %>
    ${mlAway}
    ${mlDraw}
    ${mlHome}
    <% }   %>
    <% } else { %>
      <%   if(isShowHomeFirst) {  %>
        ${mlHome}
        ${mlAway}
        <% } else {  %>
          ${mlAway}
          ${mlHome}
          <% }  %>
     
        <% } %>


    
</div> <% } %>`

const renderMetaWithOrder = (order = '') => {
	switch (order) {
		case `HD/ML/OU`:
			return `
      <div class="styleRowMeta styleRowData">
        <div class="styleMetadata">
        <% if(!data.isHideLeagueLink) { %>
          <a class="styleRowLink"  onclick="onLeagueClick('<%= navigateData  %>')">
           	${renderGameIcon()}
           	<span class="ellipsis"><%= league.name %></span>
            <i class="icon-chevron-right-sml styleIcon"></i>
          </a>
        <% } %>
        </div>
        
        <%  if(isShowHandicap) { %>
          <div class="styleButtons">
          <%= data.translate?.handicapHeader   %>
            <div style="width: 13px"></div>
          </div>
        <%  }   %>
        <%  if(isShowML) { %>
          <div class="styleButtons styleMoneyline"><span>
          <%= data.translate?.moneyLineHeader   %>
          </span></div>
          <% } %>
        <%  if(isShowOverUnder) { %>
          <div class="styleButtons">
            <span class="styleLabelMeta">
              <span> <%= data.translate?.overHeader   %> </span>
            </span>
            <span class="styleLabelMeta">
              <span><%= data.translate?.underHeader   %></span>
            </span>
            <div style="width: 13px"></div>
          </div>
          <% } %>
          <%  if(!isMobile) { %>  <div class="styleRowEnd"><span></span></div> <%  } %>
      </div>
`

		case `ML/HD/OU`:
			return `
        <div class="styleRowMeta styleRowData">
        <div class="styleMetadata">
        <% if(!data.isHideLeagueLink) { %>
          <a class="styleRowLink"  onclick="onLeagueClick('<%= navigateData  %>')">
            ${renderGameIcon()}
            <span class="ellipsis"><%= league.name %></span>
            <i class="icon-chevron-right-sml styleIcon"></i>
          </a>
        <% } %>
        </div>
        
        <div class="styleButtons styleMoneyline"><span> <%= data.translate?.moneyLineHeader   %> </span></div>
        <%  if(isShowHandicap) { %>
          <div class="styleButtons">
          <%= data.translate?.handicapHeader   %>
            <div style="width: 13px"></div>
          </div>
        <%  }   %>
        
        <%  if(isShowOverUnder) { %>
          <div class="styleButtons">
            <span class="styleLabelMeta">
              <span> <%= data.translate?.overHeader   %> </span>
            </span>
            <span class="styleLabelMeta">
              <span><%= data.translate?.underHeader   %></span>
            </span>
            <div style="width: 13px"></div>
          </div>
          <% } %>
       
          <%  if(!isMobile) { %>  <div class="styleRowEnd"><span></span></div> <%  } %>
      </div>`

		case `1X2`:
		case `1X2ML`:
			return `
      <div class="styleRowMeta styleRowData">
        <div class="styleMetadata">
        <% if(!data.isHideLeagueLink) { %>
          <a class="styleRowLink"  onclick="onLeagueClick('<%= navigateData  %>')">
            ${renderGameIcon()}
            <span class="ellipsis"><%= league.name %></span>
            <i class="icon-chevron-right-sml styleIcon"></i>
          </a>
        <% } %>
        </div>
        <%  if(isShowML) { %>
          ${
						order === '1X2'
							? ` <div class="styleButtons styleMoneyline styleThreeButtons">
              <div class="styleLabelMeta">1</div>
              <div class="styleLabelMeta">X</div>
              <div class="styleLabelMeta">2</div>
            </div>`
							: ` <div class="styleButtons styleMoneyline"><span>
          <%= data.translate?.moneyLineHeader   %>
          </span></div>`
					}
       
        <% } %>
        <%  if(isShowHandicap) { %>
        <div class="styleButtons">
        <%= data.translate?.handicapHeader   %>
          <div style="width: 13px"></div>
        </div>
        <%  }   %>

        <%  if(isShowOverUnder) { %>
          <div class="styleButtons">
            <span class="styleLabelMeta">
              <span> <%= data.translate?.overHeader   %> </span>
            </span>
            <span class="styleLabelMeta">
              <span> <%= data.translate?.underHeader   %> </span>
            </span>
            <div style="width: 13px"></div>
          </div>
        <% } %>
        <%  if(!isMobile) { %>  <div class="styleRowEnd"><span></span></div> <%  } %>
      </div>`

		default:
			return `
        `
	}
}

const renderContentWithOrder = (order = '') => {
	switch (order) {
		case `HD/ML/OU`:
			return `
        ${handicapColumn}
        ${mlColumn}
        ${overUnderColumn}`

		case `ML/HD/OU`:
			return `
        ${mlColumn}
        ${handicapColumn}
        ${overUnderColumn}`

		case `1X2`:
			return `
          ${mlColumn}
          ${handicapColumn}
          ${overUnderColumn}`
		default:
			return `
        ${mlColumn}
        ${handicapColumn}
        ${overUnderColumn}`
	}
}

export const checkSelectedMarket = `
if(isMobile) {
  switch (selectedMarket) {
    case 'total':
      isShowOverUnder = true;
      isShowHandicap = false;
      isShowML = false;
      break;
  
    case 'handicap':
      isShowOverUnder = false;
      isShowHandicap = true;
      isShowML = false;
      break;  
  
    case 'moneyline':
      isShowOverUnder = false;
      isShowHandicap = false;
      isShowML = true;
        break;   
  
    default:
      break;
  }
}


`

export const renderEventRow = (config) => {
	return `
  <%  
  const notShowHandicap = ['6'];
  const notShowOverUnder = [];
 
  const { event, periodName, leagueId, selectedOddIds =[], indexLeague, indexEvent, leagueName , baseRoute, leagueCode, selectedSportName = '', timeZoneId = '', locale, isHlE, timeStampDifference, gameCode, subfix='', width = 1440, selectedMarket=null, isHomePage = false, hockeyNaContainers = [], league = {}, quickBet } = data;
  let isShowHandicap = !notShowHandicap.includes(data.selectedSport) && width >= 1080;
  let isShowOverUnder = !notShowOverUnder.includes(data.selectedSport) && width >= 1280;
  const isMobile = width <= 768;
  let isShowML = true;
  let { periodToDisplay = 0 } = data;
  let isNormalPeriod = true;
  let renderThreeLine = false;
  if(data.selectedSport === '19' && !hockeyNaContainers.includes(league.container) && isHomePage) {
    periodToDisplay = 6;
    isNormalPeriod = false;
    renderThreeLine = true;
  }
  if(data.orderConfig === 'HD/ML/OU') {
    isShowML = width >= 1080
    isShowHandicap = true
  }
  ${checkSelectedMarket}
  const selectedOddsWithoutAlt = selectedOddIds.map((odd) => {
    const oddWithoutAlt = odd.split('|')
    oddWithoutAlt.splice(4, 1)
    return oddWithoutAlt.join('|')
  })
  const { time, moreBet, id:eventId, homeTeamType, awayTeamType, periods, parentId=0} = event;
  let { runningState = '', hasLiveStream, hasScoreboard } = event;
  const availableLiveStreamIcon = (hasLiveStream || hasScoreboard) && window?.env?.enableEsportsMatchupPage; 
 	 if (generateTranslationId?.(runningState) === data?.translate?.liveNow?.id) {
  	runningState = data?.translate?.liveNow?.translation;
  };
  const { handicap=[], moneyLine = {} , overUnder=[]} = periods[periodToDisplay] || {};
  // Render three line for Esport with Draw Price on
  let renderThreeLineEsport = false;
  if(moneyLine.drawPrice && data.selectedSport === '12') {
    renderThreeLineEsport = true;
  }
  const participants = [];
  const homeTeam = event.participants.find(p=>p.type === 'HOME');
  const awayTeam = event.participants.find(p=>p.type === 'AWAY');
  const isShowHomeFirst = homeTeamType === 0;
  if(isShowHomeFirst) {
      participants[0] = homeTeam;
      participants[1] = awayTeam;
  } else {
      participants[0] = awayTeam;
      participants[1] = homeTeam;
  }
    const navigateEventData = JSON.stringify({ sportId:data.selectedSport,gameCode, subfix,parentId,
    sportName:data.selectedSportName, leagueId, leagueCode, leagueName, eventId, eventName: participants[0].englishName.replaceAll(' ','-').replaceAll("'","") + "-vs-" + participants[1].englishName.replaceAll(' ', '-').replaceAll("'","")
    }) ;
    let navigateEventLink = baseRoute + '/'+ selectedSportName.replace(' ','')+ '/' +leagueCode+ '/' + participants[0].englishName.replaceAll(' ','-') + '-vs-' + participants[1].englishName.replaceAll(' ','-') + '/' + eventId +'/';
    
    // Special link for Esport
    if(data.selectedSport === '12') {
      navigateEventLink = baseRoute + '/'+ selectedSportName.replace(' ','')+'/games/'+ gameCode+ '/' +leagueCode+ '/' + participants[0].englishName.replaceAll(' ','-') + '-vs-' + participants[1].englishName.replaceAll(' ','-') + '/' + eventId +'/';
    }
    let timeLocale =isHlE ? new Intl.DateTimeFormat(locale, {
      hourCycle: 'h23',
      month:'numeric',day:'numeric', year:'numeric',
      hour:'numeric',
      minute:'numeric',
      timeZone: timeZoneId,
    }).format(time + timeStampDifference).replaceAll(',','') : new Intl.DateTimeFormat(locale, {
      hourCycle: 'h23',
      timeStyle: 'short',
      timeZone: timeZoneId,
    }).format(time + timeStampDifference);
    const { participants_0_score, participants_1_score, participants_0_redCards, participants_1_redCards } = getScoreLiveEvent({periodToDisplay, sportId: data.selectedSport, participants});
    const todayInt = new Intl.DateTimeFormat(locale,{month:'numeric',day:'numeric', year:'numeric',}).format(new Date().getTime() + timeStampDifference);
    const currentInt = new Intl.DateTimeFormat(locale,{month:'numeric',day:'numeric', year:'numeric',}).format(time + timeStampDifference);
    const isToday = todayInt === currentInt
    if(isToday && isHlE) {
      timeLocale = data.translate.today + ' ' + new Intl.DateTimeFormat(locale, {
        hourCycle: 'h23',
        timeStyle: 'short',
        timeZone: timeZoneId,
      }).format(time + timeStampDifference);
    }
    let notShowPeriod = listSportsNotShowPeriod.includes(data.selectedSport);
    if((data.selectedSport === '4' || data.selectedSport === '15') && periodToDisplay === 2) {
      notShowPeriod = false;
    }

    

  %>
  <div class="styleMetadata">
  <div>
    <a href="<%= navigateEventLink  %>" onclick="onNavigateEventClick(event,'<%= navigateEventData  %>')">
      <div class="flex-row styleGameInfo">
        <div class="styleMatchupMetadata" >
          <div class="ellipsis styleGameInfoLabel">
            <span class="ellipsis event-row-participant styleParticipant" 
              ><%= participants[0].name + ' ' +(notShowPeriod ? '' : periodName) %> 
            </span>
            <%  if(data.isLive && data.selectedSport === '29'){ %>
              <span class="styleRedcard <%= participants_0_redCards > 0 ? '' : 'styleBlank' %>" id="eventRedcardHome<%= eventId %>"><%= participants_0_redCards > 0 ? participants_0_redCards : '' %></span>
              <span class="staticScore styleScore" id="eventScoreHome<%= eventId %>"><%= participants_0_score %></span>
              <% } %>
            
          </div>
          <div class="ellipsis styleGameInfoLabel">
            <span class="ellipsis event-row-participant styleParticipant" 
              ><%= participants[1].name + ' ' +(notShowPeriod ? '' : periodName) %> 
            </span>
            <%  if(data.isLive && data.selectedSport === '29'){ %>
              <span class="styleRedcard <%= participants_1_redCards > 0 ? '' : 'styleBlank' %> " id="eventRedcardAway<%= eventId %>"><%= participants_1_redCards > 0 ? participants_1_redCards : '' %></span>
              <span class="staticScore styleScore" id="eventScoreAway<%= eventId %>"><%= participants_1_score %></span>
              <% } %>
          
          </div>
          <div class="styleMatchupDate <%= data.isLive ? 'styleLive':''  %> ">
          <span><%= data.isLive? runningState : timeLocale %></span>
          <%- data.isLive && availableLiveStreamIcon ? '<i class="live-stream-icon"></i>' : '' %>
            <% if(isMobile) {  %>
              <div class="styleRowEnd">
                <span class="styleBtnRowEnd"
                >+<%= moreBet %><i class="icon-chevron-right-sml"></i>
                </span>
              </div>
            <% } %>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

  <% if (isNormalPeriod) {  %>
    ${renderContentWithOrder(config.order)}
  <%  } else { %>
    ${renderContentWithOrder('1X2')}
  <% }  %>

<% if(!isMobile) {  %>
<div class="styleRowEnd">
  <a class="styleBtnRowEnd" href="<%= navigateEventLink  %>" onclick="onNavigateEventClick(event,'<%= navigateEventData  %>')"
    >+<%= moreBet %><i class="icon-chevron-right-sml"></i>
    </a>
</div>
<% } %>
`
}

export const oddPageTemplateNewAPI = (config) => {
	return `
<%
const notShowHandicap = ['6'];
const notShowOverUnder = [];

var leagues = data.matchData.leagues;
const {selectedOddIds =[], periodName, baseRoute, selectedSportName, isHlE, timeZoneId = '', locale, timeStampDifference = 0, subfix = '', width = 1440, selectedMarket = null, isHomePage = false, hockeyNaContainers = [], quickBet} = data;

let isShowHandicap = !notShowHandicap.includes(data.selectedSport) && width >= 1080;
let isShowOverUnder = !notShowOverUnder.includes(data.selectedSport) && width >= 1280;
const isMobile = width <= 768;
let isShowML = true;
  if(data.orderConfig === 'HD/ML/OU') {
    isShowML = width >= 1080
    isShowHandicap = true
  }
${checkSelectedMarket}
const selectedOddsWithoutAlt = selectedOddIds.map((odd) => {
  const oddWithoutAlt = odd.split('|')
  oddWithoutAlt.splice(4, 1)
  return oddWithoutAlt.join('|')
})
leagues.forEach(function(league,indexLeague){
  const { id:leagueId } = league;
  let { periodToDisplay = 0 } = data;
  let isNormalPeriod = true;
  let renderThreeLine = false;

  // Check if sport is hockey and is not North Ameria League to render 3 columns with period 6
  if(data.selectedSport ==='19' && !hockeyNaContainers.includes(league.container) && isHomePage) {
    periodToDisplay = 6;
    isNormalPeriod = false;
    renderThreeLine = true;
  }
   const navigateData = JSON.stringify({ sportId:data.selectedSport,
    sportName:data.selectedSportName, leagueId:league.id, leagueCode:league.leagueCode, leagueName:league.name, gameCode:league.gameCode
    }) ;
%>
 <% if (isNormalPeriod) {  %>
 ${renderMetaWithOrder(config.order)}
 <%  } else { %>
  ${renderMetaWithOrder('1X2ML')}
  <% }  %>
<% var events=league.events;
events.forEach(function(event,indexEvent){
  const { time, moreBet, id:eventId, homeTeamType, awayTeamType, periods, parentId = 0} = event;
  let { runningState = '', hasLiveStream, hasScoreboard } = event;
  const availableLiveStreamIcon = (hasLiveStream || hasScoreboard) && window?.env?.enableEsportsMatchupPage; 
 	if (generateTranslationId?.(runningState) === data?.translate?.liveNow?.id) {
  	runningState = data?.translate?.liveNow?.translation;
  };
  const {handicap=[], moneyLine = {} , overUnder=[]} = periods[periodToDisplay] || {};
  const participants = [];
  const homeTeam = event.participants.find(p=>p.type === 'HOME');
  const awayTeam = event.participants.find(p=>p.type === 'AWAY');
  const isShowHomeFirst = homeTeamType === 0;
  if(isShowHomeFirst) {
      participants[0] = homeTeam;
      participants[1] = awayTeam;
  } else {
      participants[0] = awayTeam;
      participants[1] = homeTeam;
  }
    const navigateEventData = JSON.stringify({ sportId:data.selectedSport, gameCode:league.gameCode, parentId, subfix,
    sportName:data.selectedSportName, leagueId:league.id,leagueName:league.name.replaceAll("'",""), leagueCode:league.leagueCode, eventId, eventName: participants[0].englishName.replace(' ','-').replaceAll("'","") + "-vs-" + participants[1].englishName.replace(' ', '-').replaceAll("'","")
    }) ;

    let navigateEventLink = baseRoute + '/'+selectedSportName.replace(' ','')+ '/' +league.leagueCode+ '/' + participants[0].englishName.replaceAll(' ','-') + '-vs-' + participants[1].englishName.replaceAll(' ','-') + '/' + eventId +'/';
    if(data.selectedSport === '12') {
      navigateEventLink = baseRoute + '/'+selectedSportName.replace(' ','')+ '/games/'+ league.gameCode + '/' +league.leagueCode+ '/' + participants[0].englishName.replaceAll(' ','-') + '-vs-' + participants[1].englishName.replaceAll(' ','-') + '/' + eventId +'/';
    }
%>
<%  if(periods[periodToDisplay]) {
  // Render three line for Esport with Draw Price on
  let renderThreeLineEsport = false;
  if(moneyLine.drawPrice && data.selectedSport === '12') {
    renderThreeLineEsport = true;
  }
	let timeLocale =isHlE ? new Intl.DateTimeFormat(locale, {
  hourCycle: 'h23',
  month:'numeric',day:'numeric', year:'numeric',
  hour:'numeric',
  minute:'numeric',
  timeZone: timeZoneId,
  }).format(time + timeStampDifference).replaceAll(',','') : new Intl.DateTimeFormat(locale, {
    hourCycle: 'h23',
    timeStyle: 'short',
    timeZone: timeZoneId,
  }).format(time + timeStampDifference);
  const { participants_0_score, participants_1_score, participants_0_redCards, participants_1_redCards } = getScoreLiveEvent({periodToDisplay, sportId: data.selectedSport, participants});
  const todayInt = new Intl.DateTimeFormat(locale,{month:'numeric',day:'numeric', year:'numeric',}).format(new Date().getTime() + timeStampDifference);
  const currentInt = new Intl.DateTimeFormat(locale,{month:'numeric',day:'numeric', year:'numeric',}).format(time + timeStampDifference);
    const isToday = todayInt === currentInt
  if(isToday && isHlE) {
    timeLocale = data.translate.today + ' '  + new Intl.DateTimeFormat(locale, {
      hourCycle: 'h23',
      timeStyle: 'short',
      timeZone: timeZoneId,
    }).format(time + timeStampDifference);
  }

  let notShowPeriod = listSportsNotShowPeriod.includes(data.selectedSport);
  
  if((data.selectedSport === '4' || data.selectedSport === '15') && periodToDisplay === 2) {
    notShowPeriod = false;
  }


  %>
<div class="styleRowMeta styleRowHighlight" id="eventElement<%= eventId %>">
  <div class="styleMetadata">
    <div>
      <a href="<%= navigateEventLink  %>" onclick="onNavigateEventClick(event,'<%= navigateEventData  %>')">
        <div class="flex-row styleGameInfo">
          <div class="styleMatchupMetadata">
            <div class="ellipsis styleGameInfoLabel">
              <span class="ellipsis event-row-participant styleParticipant" 
                ><%= participants[0].name + ' ' +(notShowPeriod ? '' : periodName) %> 
              </span>
              <%  if(data.isLive && data.selectedSport === '29'){ %>
                <span class="styleRedcard <%= participants_0_redCards > 0 ? '' : 'styleBlank' %>" id="eventRedcardHome<%= eventId %>"><%= participants_0_redCards > 0 ? participants_0_redCards : '' %></span>
                <span class="staticScore styleScore" id="eventScoreHome<%= eventId %>"><%= participants_0_score %></span>
                <% } %>
              
            </div>
            <div class="ellipsis styleGameInfoLabel">
              <span class="ellipsis event-row-participant styleParticipant" 
                ><%= participants[1].name + ' ' +(notShowPeriod ? '' : periodName) %>
              </span>
              <%  if(data.isLive && data.selectedSport === '29'){ %> 
                <span class="styleRedcard <%= participants_1_redCards > 0 ? '' : 'styleBlank' %> " id="eventRedcardAway<%= eventId %>"><%= participants_1_redCards > 0 ? participants_1_redCards : '' %></span>
                <span class="staticScore styleScore" id="eventScoreAway<%= eventId %>"><%= participants_1_score %></span>
                <% } %>
            
            </div>
            <div class="styleMatchupDate <%= data.isLive ? 'styleLive':''  %> ">
              <span><%= data.isLive? runningState : timeLocale %></span>
              <%- data.isLive && availableLiveStreamIcon ? '<i class="live-stream-icon"></i>' : '' %>
              <% if(isMobile) {  %>
                <div class="styleRowEnd">
                  <span class="styleBtnRowEnd"
                  >+<%= moreBet %><i class="icon-chevron-right-sml"></i>
                  </span>
                </div>
              <% } %>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  
  <% if (isNormalPeriod) {  %>
    ${renderContentWithOrder(config.order)}
  <%  } else { %>
    ${renderContentWithOrder('1X2')}
  <% }  %>

     
<% if(!isMobile) {  %>
  <div class="styleRowEnd">
    <a class="styleBtnRowEnd" href="<%= navigateEventLink  %>" onclick="onNavigateEventClick(event,'<%= navigateEventData  %>')"
      >+<%= moreBet %><i class="icon-chevron-right-sml"></i>
      </a>
  </div>
<% } %>
</div>

<%  }    %>
<% }) %>

<% }) %>

`
}
